/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-properties */
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import area from '@turf/area';

const scheduleLink = 'https://web.farmx.co/schedule';
const controlLink = (ranchId) => `https://web.farmx.co/control?ranchId=${ranchId}`;

const soilStateMap = {
  very_dry: 1,
  dry: 2,
  drying: 3,
  over_irrigated: 4,
  well_irrigated: 5,
};

const soilStateMapString = {
  1: 'very_dry',
  2: 'dry',
  3: 'drying',
  4: 'over_irrigated',
  5: 'well_irrigated',
};

/**
 * This function aggregates up different states
 * e.g. if one sensor is not provision in the group,
 * then the group gets state set to "not provisioned"
 * @param {Array} sensorCollection
 * @param {Bool} isMarker
 * @returns {Object}
 */
export const calculateGroupStateForPresentationModes = (sensorCollection, isMarker) => {
  let soilState = 0;
  let soilStateString = '';
  let connectivityState = 0;
  let waterPressureState = 0;
  let controlState = 0;
  let provisionState = 5;
  let minutes = 0;
  let stateKnown = false;
  let stateAggregated = 10;

  try {
    const sensorFeatures = sensorCollection
      .map((featureOrMarker) => {
        if (isMarker) {
          return featureOrMarker.feature;
        }
        return featureOrMarker;
      });

    const wp = sensorFeatures
      .filter((s) => s.properties.type === 'water_pressure')
      .reduce((acc, curr) => {
        if (typeof curr.properties.waterPressure !== 'undefined'
          && typeof curr.properties.pressureCutoff !== 'undefined') {
          return {
            count: acc.count + 1,
            p: acc.p + curr.properties.waterPressure,
            c: acc.c + curr.properties.pressureCutoff,
          };
        }
        return acc;
      }, {
        count: 0,
        p: 0,
        c: 0,
      });

    if (wp.count > 0) {
      const avgPressure = wp.p / wp.count;
      const avgCutoff = wp.c / wp.count;
      if (avgPressure >= avgCutoff) {
        waterPressureState = 4;
      }
    }

    for (let i = 0; i < sensorFeatures.length; i += 1) {
      const sensorFeature = sensorFeatures[i];

      const { type: sensorType } = sensorFeature.properties;

      // soilstate
      if (['aquacheck_soil', 'pixl_soil'].includes(sensorType)) {
        const { soilState: sensorSoilState } = sensorFeature.properties;
        if (sensorSoilState) {
          stateKnown = true;
          if (soilStateMap[sensorSoilState] < stateAggregated) {
            stateAggregated = soilStateMap[sensorSoilState];
          }
        }
      }

      // connectivity
      if (sensorType !== 'cavalier') {
        const lastPosted = sensorFeature.properties.lastPostedInMinutes;
        if (lastPosted === undefined) {
          minutes = undefined;
        }
        if (lastPosted !== undefined && lastPosted === -1) {
          minutes = -1;
        }
        if (lastPosted !== undefined && lastPosted !== -1 && lastPosted > minutes) {
          minutes = lastPosted;
        }
      }

      if (minutes === undefined) {
        connectivityState = 0;
      } else if (minutes === -1) {
        connectivityState = 1;
      } else if (minutes > 120) {
        connectivityState = 2;
      } else if (minutes > 30 && minutes <= 120) {
        connectivityState = 3;
      } else if (minutes <= 30) {
        connectivityState = 5;
      }

      // control
      if (
        controlState === 0
        && [
          'valve',
          'vfd',
          'water_flow',
          'water_flow_analog',
          'water_pressure',
        ].includes(sensorType)
        && sensorFeature.properties.controlSateON
      ) {
        controlState = 4;
      }

      // provision has only two states 2 and 5
      if (provisionState === 5 && !sensorFeature.properties.provisioned) {
        provisionState = 2;
      }
    }

    // soilstate continues
    if (stateKnown) {
      switch (stateAggregated) {
        case 1:
          soilState = 1;
          soilStateString = soilStateMapString[1];
          break;
        case 2:
          soilState = 2;
          soilStateString = soilStateMapString[2];
          break;
        case 3:
          soilState = 3;
          soilStateString = soilStateMapString[3];
          break;
        case 4:
          soilState = 4;
          soilStateString = soilStateMapString[4];
          break;
        case 5:
          soilState = 5;
          soilStateString = soilStateMapString[5];
          break;
        default:
          soilState = 0;
          soilStateString = soilStateMapString[0];
      }
    }
  } catch (error) {
    console.log('ERROR calculating state of marker groups', error);
  }

  return {
    connectivityState,
    waterPressureState,
    provisionState,
    soilState,
    soilStateString,
    controlState,
  };
};

/**
 * Prepares GeoJSON data for sensors, ranches, blocks.
 * Calculates states using calculateGroupStateForPresentationModes
 * Parameters sensorStates & soilState are optional
 * @param {Object} param0
 * @returns {Object}
 */
export const prepareData = ({
  sensors,
  blocks,
  ranches,
  sensorStates,
  soilState,
  recommendationBlocks,
}) => {
  const blockInfoObj = blocks.reduce((acc, curr) => {
    acc.blockIds.push(curr.id);
    if (curr.vfd) {
      acc.blockNames.push(curr.name);
    }
    acc.blocksObj[curr.id] = curr;

    return acc;
  }, {
    blockIds: [],
    blockNames: [],
    blocksObj: {},
  });

  const blockIds = blockInfoObj.blockIds.join(',');
  const blockNames = blockInfoObj.blockNames.join(', ');
  const { blocksObj } = blockInfoObj;

  const sensorsInfo = cloneDeep(sensors);
  const sensorsInfoObj = sensorsInfo.reduce((acc, curr) => {
    if (curr.type === 'vfd') {
      acc.vfdObj[`vfd/${curr.identifier}`] = curr;
    }
    return acc;
  }, {
    vfdObj: {},
  });

  const { vfdObj } = sensorsInfoObj;

  const sensorsFeatures = {};
  sensors
    .filter((i) => {
      if (i === Object(i) && i.location === Object(i.location)) {
        return isNumber(i.location.lat) && isNumber(i.location.lng);
      }
      return false;
    })
    .forEach((sensor) => {
      if ((sensor.visible || ['valve', 'vfd'].includes(sensor.type))) {
        const id = `${sensor.type}/${sensor.identifier}`;
        const sensorFeature = {
          id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [sensor.location.lng, sensor.location.lat],
          },
          properties: {
            ...cloneDeep(sensor),
            id,
            noStatusAvailable: true,
            idDb: sensor.id,
            ranchId: sensor.ranch === Object(sensor.ranch)
              ? sensor.ranch.id
              : sensor.ranch,
          },
        };

        sensorFeature.properties.blockId = sensor.block === Object(sensor.block)
          ? sensor.block.id
          : sensor.block;

        if (sensorFeature.properties.type === 'valve') {
          sensorFeature.properties.scheduleLink = scheduleLink;
          if (sensorFeature.properties.irrigation_block) {
            if (blockInfoObj.blocksObj[sensorFeature.properties.irrigation_block]) {
              sensorFeature.properties.downstreamBlock = blockInfoObj
                .blocksObj[sensorFeature.properties.irrigation_block].name;
            } else {
              sensorFeature.properties.downstreamBlock = '';
            }

            sensorFeature.properties.scheduleLink = `https://web.farmx.co/schedule?irrigationTypes=scheduled&blockId=${sensorFeature.properties.irrigation_block}`;
            const irrigationBlock = blocksObj[sensorFeature.properties.irrigation_block];
            if (irrigationBlock === Object(irrigationBlock)
              && irrigationBlock.properties
              && irrigationBlock.properties.vfd
            ) {
              sensorFeature.properties.pumpControl = vfdObj[`vfd/${irrigationBlock.properties.vfd.identifier}`]
                ? vfdObj[`vfd/${irrigationBlock.properties.vfd.identifier}`].properties.name
                : '';
            }
          }

          sensorFeature.properties.controlLink = controlLink(sensorFeature.properties.ranchId);
        }

        if (sensorFeature.properties.type === 'vfd') {
          sensorFeature.properties.supplies = blockNames;
          sensorFeature.properties.scheduleLink = `https://web.farmx.co/schedule?irrigationTypes=scheduled&blockId=${blockIds}`;
          sensorFeature.properties.controlLink = controlLink;
        }

        sensorsFeatures[id] = sensorFeature;
      }
    });


  const blocksFeatures = blocks
    .filter((i) => {
      if (i === Object(i) && i.bounds === Object(i.bounds)) {
        return (i.bounds.coordinates instanceof Array)
          && i.bounds.coordinates.length
          && i.bounds.type;
      }
      return false;
    })
    .reduce((acc, block) => {
      const properties = cloneDeep(block);
      if (recommendationBlocks
        && recommendationBlocks[block.id] instanceof Array
        && recommendationBlocks[block.id].length) {
        const [recommendation] = recommendationBlocks[block.id];
        properties.recommendationInfo = recommendation;
      } else {
        properties.recommendationInfo = {};
      }

      const blockFeature = {
        id: block.id,
        type: 'Feature',
        geometry: cloneDeep(block.bounds),
        properties,
      };

      blockFeature.properties.ranchId = block.ranch === Object(block.ranch)
        ? block.ranch.id
        : block.ranch;
      blockFeature.properties.type = 'block';
      try {
        blockFeature.properties.squareMeters = area(blockFeature);
      } catch (error) {
        console.log('ERROR with block data in utils.js', error);
      }

      blockFeature.properties.acres = Number(
        (blockFeature.properties.squareMeters / 4046.86).toFixed(1),
      );
      blockFeature.properties.noStatusAvailable = true;
      acc[block.id] = blockFeature;

      return acc;
    }, {});

  const ranchesFeatures = ranches
    .filter((i) => {
      if (i === Object(i) && i.bounds === Object(i.bounds)) {
        return (i.bounds.coordinates instanceof Array)
          && i.bounds.coordinates.length
          && i.bounds.type;
      }
      return false;
    })
    .reduce((acc, ranch) => {
      const ranchFeature = {
        id: ranch.id,
        type: 'Feature',
        geometry: cloneDeep(ranch.bounds),
        properties: cloneDeep(ranch),
      };

      ranchFeature.properties.entityId = ranch.entity === Object(ranch.entity)
        ? ranch.entity.id
        : ranch.entity;

      ranchFeature.properties.type = 'ranch';
      try {
        ranchFeature.properties.squareMeters = area(ranchFeature);
      } catch (error) {
        console.log('ERROR with ranch data in utils.js', error);
      }

      ranchFeature.properties.acres = Number(
        (ranchFeature.properties.squareMeters / 4046.86).toFixed(1),
      );
      ranchFeature.properties.noStatusAvailable = true;
      acc[ranch.id] = ranchFeature;

      return acc;
    }, {});

  if (!sensorStates) {
    return {
      sensorFeatures: sensorsFeatures,
      blockFeatures: blocksFeatures,
      ranchFeatures: ranchesFeatures,
    };
  }

  let sensorsWithSoilStateFeatures;
  let blocksWithSoilStateFeatures;
  let ranchesWithSoilStateFeatures;

  if (soilState) {
    const soilStatusSensors = {};
    if (soilState === Object(soilState)) {
      soilState.blocks.forEach((block) => {
        if (block === Object(block)
          && block.state
          && block.state.details
          && block.state.details.all_data
          && block.state.details.all_data.length
        ) {
          block.state.details.all_data.forEach((s) => {
            soilStatusSensors[s.sensor] = cloneDeep(s);
          });
        }
      });

      if (soilState.state === Object(soilState.state)
        && soilState.state.details
        && soilState.state.details.all_data
        && soilState.state.details.all_data.length) {
        soilState.state.details.all_data.forEach((s) => {
          soilStatusSensors[s.sensor] = cloneDeep(s);
        });
      }
    }

    sensorsWithSoilStateFeatures = Object
      .values(sensorsFeatures)
      .reduce((acc, sensorBase) => {
        const sensor = cloneDeep(sensorBase);
        sensor.properties.noStatusAvailable = false;
        sensor.properties.soilStatusAvailable = true;

        if (soilStatusSensors[sensorBase.properties.identifier]) {
          sensor.properties = {
            ...cloneDeep(sensor.properties),
            ...cloneDeep(soilStatusSensors[sensorBase.properties.identifier]),
          };
        }

        acc[sensorBase.id] = cloneDeep(sensor);
        return acc;
      }, {});

    let blocksSoilSate = {};

    if (soilState === Object(soilState) && soilState.blocks instanceof Array) {
      blocksSoilSate = soilState.blocks.reduce((acc, block) => {
        acc[block.id] = cloneDeep(block);
        return acc;
      }, {});
    }

    blocksWithSoilStateFeatures = Object
      .values(blocksFeatures)
      .reduce((acc, blockFeature) => {
        if (blocksSoilSate[blockFeature.id]) {
          blockFeature.properties = {
            ...cloneDeep(blockFeature.properties),
            ...cloneDeep(blocksSoilSate[blockFeature.id]),
            noStatusAvailable: false,
            soilStatusAvailable: true,
          };
        }

        acc[blockFeature.id] = blockFeature;
        return acc;
      }, {});

    ranchesWithSoilStateFeatures = Object
      .values(ranchesFeatures)
      .reduce((acc, ranchFeature) => {
        if (soilState === Object(soilState) && soilState.id === ranchFeature.id) {
          ranchFeature.properties = {
            ...cloneDeep(ranchFeature.properties),
            ...cloneDeep(soilState),
            noStatusAvailable: false,
            soilStatusAvailable: true,
          };
        }
        acc[ranchFeature.id] = cloneDeep(ranchFeature);
        return acc;
      }, {});
  }

  if (!sensorStates) {
    return {
      sensorFeatures: sensorsWithSoilStateFeatures,
      blockFeatures: blocksWithSoilStateFeatures,
      ranchFeatures: ranchesWithSoilStateFeatures,
    };
  }

  const now = (new Date()).getTime();

  const maxWaterPressure = sensorStates.reduce((acc, curr) => {
    if (curr.type !== 'water_pressure') {
      return acc;
    }
    if (curr.pressureCutoff && curr.waterPressure) {
      return Math.max(acc, curr.pressureCutoff, curr.waterPressure);
    }
    if (curr.pressureCutoff) {
      return Math.max(acc, curr.pressureCutoff);
    }
    if (curr.waterPressure) {
      return Math.max(acc, curr.waterPressure);
    }
    return acc;
  }, 0);

  const okStateResults = sensorStates.reduce((acc, r) => {
    const id = `${r.type}/${r.identifier}`;

    let lastPostedInMinutes;
    if (r.earliestDate
      && r.latestDate) {
      const earliestDate = (new Date(r.earliestDate))
        .getTime();
      const lastPosted = (new Date(r.latestDate))
        .getTime();
      if (lastPosted >= earliestDate) {
        lastPostedInMinutes = Math.round((now - lastPosted) / 1000 / 60);
      }
    }

    if (r.type === 'water_pressure') {
      if (r.waterPressure || r.waterPressure === 0) {
        if (r.waterPressure < 0) {
          r.waterPressure = 0;
          r.psi = 0;
        } else if (r.waterPressure < 10) {
          r.waterPressure = Number(r.waterPressure.toFixed(1));
          r.psi = r.waterPressure;
        } else {
          r.waterPressure = Number(r.waterPressure.toFixed());
          r.psi = r.waterPressure;
        }
        r.controlSateON = true;
      } else {
        r.missingWaterPressure = true;
      }
    } else if (r.type === 'valve') {
      // Can be open, closed, unknown
      // based on design req 'open' means 'On'
      if (r.controlStatus === Object(r.controlStatus)
        && r.controlStatus.state === 'open') {
        r.controlSateON = true;
      }
    } else if (r.type === 'vfd') {
      if (r.controlStatus === Object(r.controlStatus)
        && r.controlStatus.state === 'on') {
        r.controlSateON = true;
      }
    } else if (r.type === 'water_flow' || r.type === 'water_flow_analog') {
      if (isNumber(r.flowRate) && r.flowRate > 0) {
        r.controlSateON = true;
      }
    }

    acc[id] = {
      ...r,
      idDbStatus: r.id,
      lastPostedInMinutes,
      lastUpdated: now,
      id,
    };

    return acc;
  }, {});

  const sensorsWithStatusAndByBlocks = Object
    .values(sensorsWithSoilStateFeatures || sensorsFeatures)
    .reduce((acc, sensorBase) => {
      const sensor = cloneDeep(sensorBase);
      sensor.properties.noStatusAvailable = false;
      if (okStateResults[sensorBase.id]) {
        sensor.properties = {
          ...cloneDeep(sensor.properties),
          ...cloneDeep(okStateResults[sensorBase.id]),
          otherStatesAvailable: true,
        };
      }

      if (sensor.properties.type === 'water_pressure' && sensor.properties.missingWaterPressure) {
        sensor.properties.waterPressureColor = 'gray';
      } else if (sensor.properties.type === 'water_pressure') {
        const waterPressure = sensor.properties.waterPressure || 0;
        if (waterPressure === 0) {
          sensor.properties.waterPressureColor = 'white';
          sensor.properties.waterPressureRatio = 0.01;
        } else if (Math.round(waterPressure) === Math.round(maxWaterPressure)) {
          sensor.properties.waterPressureColor = 'blue';
          sensor.properties.waterPressureRatio = 1;
        } else {
          const ratioWP = waterPressure / maxWaterPressure;
          let hex = Number(Math.round(255 - (ratioWP * 255))).toString(16);
          if (hex.length < 2) {
            hex = `0${hex}`;
          }
          sensor.properties.waterPressureColor = `#${hex}${hex}ff`;
          sensor.properties.waterPressureRatio = ratioWP;
        }
      }

      acc.sensorsFeaturesWithStatus[sensorBase.id] = cloneDeep(sensor);
      if (!acc.sensorsGroupedByBlock[sensorBase.properties.blockId]) {
        acc.sensorsGroupedByBlock[sensorBase.properties.blockId] = [];
      }
      acc.sensorsGroupedByBlock[sensorBase.properties.blockId].push(cloneDeep(sensor));
      return acc;
    }, {
      sensorsFeaturesWithStatus: {},
      sensorsGroupedByBlock: {},
    });

  const { sensorsGroupedByBlock } = sensorsWithStatusAndByBlocks;

  const blocksWithStatusAndByRanch = Object
    .values(blocksWithSoilStateFeatures || blocksFeatures)
    .reduce((acc, blockFeature) => {
      let connectivityState = 0;
      let waterPressureState = 0;
      let controlState = 0;
      let provisionState = 5;
      let soilStateOfBlock = 0;
      let soilStateStringOfBlock;

      if (sensorsGroupedByBlock[blockFeature.id]) {
        const states = calculateGroupStateForPresentationModes(
          sensorsGroupedByBlock[blockFeature.id],
        );
        connectivityState = states.connectivityState;
        waterPressureState = states.waterPressureState;
        controlState = states.controlState;
        provisionState = states.provisionState;
        soilStateOfBlock = states.soilState;
        soilStateStringOfBlock = states.soilStateString;
      }

      if (!sensorsGroupedByBlock[blockFeature.id]) {
        provisionState = 0;
      }

      blockFeature.properties.connectivityState = connectivityState;
      blockFeature.properties.provisionState = provisionState;
      blockFeature.properties.waterPressureState = waterPressureState;
      blockFeature.properties.controlState = controlState;
      blockFeature.properties.otherStatesAvailable = true;
      blockFeature.properties.soilStateOfBlock = soilStateOfBlock;
      blockFeature.properties.soilStateStringOfBlock = soilStateStringOfBlock;
      acc.blocksFeaturesWithStatus[blockFeature.id] = blockFeature;

      if (!acc.blocksGroupedByRanch[blockFeature.properties.ranchId]) {
        acc.blocksGroupedByRanch[blockFeature.properties.ranchId] = [];
      }
      acc.blocksGroupedByRanch[blockFeature.properties.ranchId].push(cloneDeep(blockFeature));
      return acc;
    }, {
      blocksFeaturesWithStatus: {},
      blocksGroupedByRanch: {},
    });

  const { blocksGroupedByRanch } = blocksWithStatusAndByRanch;

  const ranchesFeaturesWithStatus = Object
    .values(ranchesWithSoilStateFeatures || ranchesFeatures)
    .reduce((acc, ranchFeature) => {
      if (soilState === Object(soilState) && soilState.id === ranchFeature.id) {
        ranchFeature.properties = {
          ...cloneDeep(ranchFeature.properties),
          ...cloneDeep(soilState),
        };
      }

      let connectivityState = 5;
      let waterPressureState = 0;
      let controlState = 0;
      let provisionState = 5;

      if (blocksGroupedByRanch[ranchFeature.id]) {
        const blockFeaturesByRanchId = blocksGroupedByRanch[ranchFeature.id];
        for (let i = 0; i < blockFeaturesByRanchId.length; i += 1) {
          const {
            connectivityState: blockConnectivityState,
            waterPressureState: blockWaterPressureState,
            controlState: blockControlState,
            provisionState: blockProvisionState,
          } = blockFeaturesByRanchId[i].properties;

          if (blockConnectivityState < connectivityState) {
            connectivityState = blockConnectivityState;
          }

          if (blockWaterPressureState > waterPressureState) {
            waterPressureState = blockWaterPressureState;
          }

          if (blockControlState > controlState) {
            controlState = blockControlState;
          }

          if (blockProvisionState < provisionState) {
            provisionState = blockProvisionState;
          }
        }
      }

      ranchFeature.properties.connectivityState = connectivityState;
      ranchFeature.properties.provisionState = provisionState;
      ranchFeature.properties.waterPressureState = waterPressureState;
      ranchFeature.properties.controlState = controlState;
      ranchFeature.properties.otherStatesAvailable = true;
      acc[ranchFeature.id] = cloneDeep(ranchFeature);
      return acc;
    }, {});

  return {
    sensorFeatures: cloneDeep(sensorsWithStatusAndByBlocks.sensorsFeaturesWithStatus),
    blockFeatures: cloneDeep(blocksWithStatusAndByRanch.blocksFeaturesWithStatus),
    ranchFeatures: cloneDeep(ranchesFeaturesWithStatus),
  };
};

export const prepareRanchesData = ({
  ranches,
  soilState,
}) => {
  const ranchesFeatures = ranches.reduce((acc, ranch) => {
    if (!ranch.bounds) {
      return acc;
    }
    try {
      const ranchFeature = {
        id: ranch.id,
        type: 'Feature',
        geometry: cloneDeep(ranch.bounds),
        properties: cloneDeep(ranch),
      };

      ranchFeature.properties.entityId = ranch.entity === Object(ranch.entity)
        ? ranch.entity.id
        : ranch.entity;
      ranchFeature.properties.type = 'ranch';
      try {
        ranchFeature.properties.squareMeters = area(ranchFeature);
      } catch (error) {
        console.log('ERROR ranchFeature in utils.js', ranchFeature, error);
      }

      ranchFeature.properties.acres = Number(
        (ranchFeature.properties.squareMeters / 4046.86).toFixed(1),
      );
      acc[ranch.id] = ranchFeature;
    } catch (e) {
      console.log('ERROR broken data for the ranch in utils.js', ranch, e);
    }
    return acc;
  }, {});

  if (!soilState) {
    return {
      ranchesFeatures,
      noState: true,
    };
  }

  const ranchesFeaturesWithStatus = Object
    .values(ranchesFeatures)
    .reduce((acc, ranchFeature) => {
      if (soilState[ranchFeature.id]) {
        ranchFeature.properties = {
          ...cloneDeep(ranchFeature.properties),
          ...cloneDeep(soilState[ranchFeature.id]),
        };
      }

      acc[ranchFeature.id] = cloneDeep(ranchFeature);
      return acc;
    }, {});

  return {
    ranchesFeaturesWithStatus,
  };
};
