/**
 * @param {Number} value
 * @param {String} fromUnits
 * @param {String} toUnits
 * @returns {Number}
 */
export function getConversion(value, fromUnits, toUnits, decimalPlaces = 1) {
  try {
    if (value === null || value === undefined || value === '') return 0;

    const key = `${fromUnits}_to_${toUnits}`;

    const convertMap = {
      // Flow units conversion
      gallons_per_minute_to_liters_per_minute: (value * 3.785412).toFixed(decimalPlaces),
      gallons_per_minute_to_gallons_per_hour: (value * 60).toFixed(decimalPlaces),
      gallons_per_minute_to_liters_per_hour: (value * 227.1247070).toFixed(decimalPlaces),

      // Power units conversion
      horsepower_to_kilowatts: (value * 0.7457).toFixed(decimalPlaces),

      // Temperature conversion
      degrees_fahrenheit_to_degrees_celsius: ((value - 32) * (5 / 9)).toFixed(decimalPlaces),
      degrees_celsius_to_degrees_fahrenheit: ((value * (9 / 5)) + 32).toFixed(decimalPlaces),

      // Length conversion
      feet_to_meters: (value * 0.3048).toFixed(decimalPlaces),
      inches_to_centimeters: (value * 2.54).toFixed(decimalPlaces),
      millimeters_to_inches: (value / 25.4).toFixed(decimalPlaces),
      millimeters_to_centimeters: (value / 10).toFixed(decimalPlaces),

      // Volume conversion
      gallons_to_liters: (value * 3.785412).toFixed(decimalPlaces),
      gallons_to_acre_inches: (value / 27154.285714).toFixed(decimalPlaces),
      gallons_to_acre_feet: (value / 325851.4318891248).toFixed(decimalPlaces),
      gallons_to_cubic_meters: (value / 264.172052).toFixed(decimalPlaces),
      cubic_meters_to_gallons: (value * 264.172052).toFixed(decimalPlaces),

      // Area conversion
      acres_to_hectares: (value * 0.404686).toFixed(decimalPlaces),
      acres_to_square_miles: (value * 0.0015625).toFixed(decimalPlaces),
      acres_to_square_kilometers: (value * 0.00404686).toFixed(decimalPlaces),
      square_meters_to_square_kilometers: (value / 1000000).toFixed(decimalPlaces),
      square_meters_to_acres: (value / 4046.86).toFixed(decimalPlaces),
    };

    return convertMap[key] !== undefined
      ? parseFloat(convertMap[key])
      : parseFloat(Number(value).toFixed(decimalPlaces));
  } catch {
    return value;
  }
}

/**
 * @param {String} toUnits
 * @returns {String}
 */
const getShortLabel = (toUnits) => ({
  // Flow Units
  gallons_per_minute: 'GPM',
  gallons_per_hour: 'GPH',
  liters_per_minute: 'LPM',
  liters_per_hour: 'LPH',

  // Area Units
  square_miles: 'Sq Miles',
  square_kilometers: 'Sq Kms',

  // Volume Units
  acre_inches: 'Acre In',
  acre_feet: 'Acre Ft',
  cubic_meters: 'm³',

  // Length Units
  inches: 'in',
  centimeters: 'cm',
  millimeters: 'mm',

  // Power Units
  horsepower: 'HP',
  kilowatts: 'KW',

  // Temperature Units
  degrees_fahrenheit: 'F',
  degrees_celsius: 'C',

  // Frequency Units
  hertz: 'Hz',

  // Speed Units
  revolutions_per_minute: 'RPM',
}[toUnits] || toUnits);

/**
 * @param {String} toUnits
 * @returns {String}
 */
const getLongLabel = (toUnits) => ({
  // Flow Units
  gallons_per_minute: 'gallons per minute',
  gallons_per_hour: 'gallons per hour',
  liters_per_minute: 'liters per minute',
  liters_per_hour: 'liters per hour',

  // Area Units
  square_miles: 'square miles',
  square_kilometers: 'square kilometers',

  // Volume Units
  acre_inches: 'acre inches',
  acre_feet: 'acre feet',
  cubic_meters: 'cubic meters',

  // Length Units
  inches: 'inches',
  centimeters: 'centimeters',
  millimeters: 'millimeters',

  // Power Units
  horsepower: 'horsepower',
  kilowatts: 'kilowatts',

  // Temperature Units
  degrees_fahrenheit: 'degrees fahrenheit',
  degrees_celsius: 'degrees celsius',

  // Frequency Units
  hertz: 'hertz',

  // Speed Units
  revolutions_per_minute: 'revolutions per minute',
}[toUnits] || toUnits);

/**
 * @param {String} toUnits
 * @param {String} labelType
 * @returns {String}
 */
export function getLabel(toUnits, labelType = 'short') {
  if (labelType === 'short') {
    return getShortLabel(toUnits);
  }
  return getLongLabel(toUnits);
}
