import { useSelector } from 'react-redux';
import { getConversion, getLabel } from '../../helper/units';
import { useLoadUserSettings } from './useLoadUserSettings';
import * as settingsSelectors from '../../settings/selectors';

/**
 * Example usage
 * const getUserUnits = useUnits();;
 * const convertedFlowRate = getUserUnits(flowRate, units.flowRate, 'flow_rate', options);
 * const toDisplay = `${convertedFlowRate.value} ${t(convertedFlowRate.label)}`;
 *
 * options may contain
 * {
 *   labelType: 'long',
 *   decimalPlaces: 2,
 * }
 */
export function useUnits() {
  useLoadUserSettings();

  const toUnits = {
    length: useSelector(settingsSelectors.selectUserLengthFormat),
    depth: useSelector(settingsSelectors.selectUserDepthFormat),
    volume: useSelector(settingsSelectors.selectUserVolumeFormat),
    area: useSelector(settingsSelectors.selectUserAreaFormat),
    flow_rate: useSelector(settingsSelectors.selectUserFlowRateFormat),
    power: useSelector(settingsSelectors.selectUserPowerFormat),
    temperature: useSelector(settingsSelectors.selectUserTemperatureFormat),
  };

  function getUserUnits(value, fromUnit, unitType, options = {}, inputUnit) {
    const toUnit = toUnits[unitType] || fromUnit;
    const { labelType, decimalPlaces } = options;

    const convertedValue = getConversion(value, fromUnit, inputUnit || toUnit, decimalPlaces);
    const unitsLabel = getLabel(inputUnit || toUnit, labelType);

    return {
      value: convertedValue,
      label: unitsLabel,
    };
  }

  return getUserUnits;
}
