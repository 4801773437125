import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styles from './SensorSelect.less';
import Searchbox from './SearchBox';
import calculatedHeightForMobile from '../helper';

const DropdownModal = ({
  title,
  displayOptions,
  mobileScreen,
  onClick,
  onChange,
  displaySearch,
  buttonText,
  size,
}) => {
  const { t } = useTranslation();

  const listHeight = calculatedHeightForMobile();

  return (
    <div>
      <div
        className={styles['flex-container']}
        style={{ height: mobileScreen ? `${listHeight}px` : 'auto' }}
      >
        {mobileScreen
          ? (
            <div>
              <div className={styles['modal-select-header']}>
                <div className={styles['modal-select-header-text']}>
                  {t(title)}
                </div>
                <div />
              </div>
              {displaySearch ? <Searchbox onChange={onChange} /> : null}
            </div>
          ) : null}
        <div
          className={styles['sensor-option-div']}
          onClick={(d) => (buttonText === 'cancel' ? onClick(d) : null)}
          onKeyPress={(d) => (buttonText === 'cancel' ? onClick(d) : null)}
          role="button"
          tabIndex="0"
        >
          {displayOptions}
        </div>
      </div>
      {mobileScreen
        ? (
          <div className={`${styles['button-centered']} custom-button`}>
            <Button
              id="goBack"
              type="primary"
              className={styles['cancel-button-color']}
              size={size}
              onClick={onClick}
              block
            >
              {t(buttonText)}
            </Button>
          </div>
        ) : null }
    </div>
  );
};

DropdownModal.propTypes = {
  title: PropTypes.string,
  displayOptions: PropTypes.shape({}),
  mobileScreen: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  displaySearch: PropTypes.bool,
  buttonText: PropTypes.string,
  size: PropTypes.string,
};

DropdownModal.defaultProps = {
  title: null,
  displayOptions: null,
  mobileScreen: false,
  onClick: null,
  onChange: null,
  displaySearch: false,
  buttonText: 'done',
  size: 'middle',
};

export default DropdownModal;
