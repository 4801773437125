import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'antd';
import { FaBell } from 'react-icons/fa';
import styles from './CommonStyles.less';

function getAlarmListUrl({ type, identifier }) {
  const params = {
    sensorType: type,
    sensorIdentifier: identifier,
    logLevel: 'error',
    active: true,
  };
  const paramStr = new URLSearchParams(params).toString();
  return `/events?${paramStr}`;
}

export default function SensorAlarmButton(props) {
  const {
    alarmCount,
    identifier,
    isMobileView,
    type,
  } = props;

  return (
    <Link to={getAlarmListUrl({ type, identifier })}>
      <Badge
        className={isMobileView ? styles['mobile-sensor-alarm-button'] : ''}
        count={alarmCount}
      >
        <Button
          className={styles['bell-icon-button']}
          icon={(
            <FaBell
              className={`${styles['bell-icon-size']},
              ${styles['bell-icon']},
              ${alarmCount === 0
                ? styles['bell-icon-disabled'] : styles['bell-icon-enabled']}`}
            />
          )}
        />
      </Badge>
    </Link>
  );
}

SensorAlarmButton.propTypes = {
  type: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  alarmCount: PropTypes.number,
  isMobileView: PropTypes.bool,
};

SensorAlarmButton.defaultProps = {
  alarmCount: null,
  isMobileView: false,
};
