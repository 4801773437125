import { sensorApi } from 'farmx-api';
import createFlatSlice from '../flatSlice';

const { fetchSensorImages } = sensorApi;

const sensorImages = createFlatSlice(
  'images',
  fetchSensorImages,
  () => {}, // currently not implemented
  (state) => state.sensorsData.images,
);

const {
  loadFlatList: loadSensorImagesList,
  loadSingle: loadSensorImage,
  entityAdapter: sensorImagesAdapter,
} = sensorImages;

export {
  loadSensorImagesList,
  loadSensorImage,
  sensorImagesAdapter,
};

export default sensorImages.slice.reducer;
