import React from 'react';
import PropTypes from 'prop-types';
import InputNumberUnits from './InputNumberUnits';

const volumeUnits = [
  {
    key: 'acrein',
    name: 'acre inches',
  },
  {
    key: 'cuft',
    name: 'cubic feet',
  },
];

function InputVolume(props) {
  const { disabled, initialValue, onChange } = props;
  return (
    <InputNumberUnits
      unitOptions={volumeUnits}
      onChange={onChange}
      disabled={disabled}
      initialValue={initialValue}
    />
  );
}

InputVolume.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.number,
  onChange: PropTypes.func,
};

InputVolume.defaultProps = {
  disabled: false,
  initialValue: 0,
  onChange: null,
};

export default InputVolume;
