import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Collapse, List, Typography, Tooltip,
} from 'antd';
import styles from './CommonStyle.less';
import {
  almonds, grapes, none, pistachios, walnuts,
} from '../assets/icons/croptypes';

const { Panel } = Collapse;
const { Text } = Typography;

const EntityList = ({
  onChange,
  selected,
  data,
  activeSelections,
}) => {
  // sort data in alphabetical order
  if (data.length) {
    data.map((d) => {
      const obj = d;
      obj.ranches.sort((a, b) => (a && a.name.toLowerCase()
        .localeCompare(b && b.name.toLowerCase())));
      obj.ranches.map((r) => {
        const robj = r;
        robj.blocks.sort((a, b) => (a && a.name.toLowerCase()
          .localeCompare(b && b.name.toLowerCase())));
        return robj;
      });
      return obj;
    });
    data.sort((a, b) => (a && a.name.toLowerCase().localeCompare(b && b.name.toLowerCase())));
  }

  function getImage(type) {
    switch (type.toLowerCase()) {
      case 'almonds': case 'almond': return almonds;
      case 'grapes': case 'grape': return grapes;
      case 'pistachios': case 'pistachio': return pistachios;
      case 'walnuts': case 'walnut': return walnuts;
      default: return none;
    }
  }

  useEffect(() => {
    if (selected.ranch.length && !selected.block.length) {
      const elmnt = document.getElementById(String(selected.ranch[0]));
      if (elmnt) elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (selected.block.length) {
      const elmnt = document.getElementById(String(selected.block[0]));
      if (elmnt) elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });

  return (
    data.map((en, i) => {
      if (en && en.id) {
        return (
          <Collapse
            expandIconPosition="right"
            key={`${String(en.id)}_${String(i)}`}
            collapsible="header"
            defaultActiveKey={en.id}
          >
            <Panel header={en.name} key={String(en.id)} className={styles['collapse-content']}>
              {en.ranches.length ? en.ranches.map((ra, j) => (
                <Collapse
                  className={((selected.ranch && selected.ranch.includes(ra.id))
                    && (selected.block && !selected.block.length))
                    ? styles['collapsible-list-selected']
                    : styles['collapsible-list']}
                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  key={`${String(ra.id)}_${String(j)}`}
                  activeKey={activeSelections}
                  onChange={onChange}
                >
                  <Panel
                    header={((selected.ranch && selected.ranch.includes(ra.id))
                      && (selected.block && !selected.block.length))
                      ? (
                        <Tooltip title={ra.name}>
                          <Text
                            id={String(ra.id)}
                            onClick={(d) => onChange({ ranch: d.target.id })}
                            className={styles['selected-ranch-header']}
                          >
                            {ra.name}
                          </Text>
                        </Tooltip>
                      )
                      : (
                        <Tooltip title={ra.name}>
                          <Text
                            id={String(ra.id)}
                            onClick={(d) => onChange({ ranch: d.target.id })}
                          >
                            {ra.name}
                          </Text>
                        </Tooltip>
                      )}
                    key={String(ra.id)}
                  >
                    {ra.blocks.length ? ra.blocks.map((bl, k) => (
                      <div key={`${bl.name}_${String(k)}`} className={styles['list-content']}>
                        <List.Item
                          key={bl.id}
                          onClick={(d) => onChange({ block: d.target.id })}
                          id={bl.id}
                          className={((selected.ranch && selected.ranch.length)
                            && (selected.block && selected.block.includes(bl.id)))
                            ? styles['selected-block']
                            : styles['extra-list']}
                          extra={[
                            <div
                              id={bl.id}
                              key={`${bl.id}_${bl.name}`}
                              className={styles['extra-list-items']}
                            >
                              <img id={bl.id} alt={bl.crop} src={getImage(bl.crop)} />
                            </div>]}
                        >
                          <Tooltip title={bl.name}>
                            <Text
                              id={bl.id}
                              className={((selected.ranch && selected.ranch.length)
                                && (selected.block && selected.block.includes(bl.id)))
                                ? styles['selected-block-text']
                                : styles['list-name']}
                            >
                              {bl.name}
                            </Text>
                          </Tooltip>
                        </List.Item>
                        <div className={styles['status-box']} />
                      </div>
                    )) : (
                      <div>
                        {!ra.isLoading
                          ? <Text className={styles['loading-icon']}>No Block</Text>
                          : <LoadingOutlined className={styles['loading-icon']} />}
                      </div>
                    )}
                  </Panel>
                  <div className={styles['panel-statusbox']} />
                </Collapse>

              )) : (
                <div>
                  {!en.isLoading
                    ? <Text className={styles['loading-icon']}>No Ranch</Text>
                    : <LoadingOutlined className={styles['loading-icon']} />}
                </div>
              )}
            </Panel>
          </Collapse>
        );
      }
      return null;
    })
  );
};

EntityList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  selected: PropTypes.shape({
    ranch: PropTypes.arrayOf(PropTypes.any),
    block: PropTypes.arrayOf(PropTypes.any),
  }),
  activeSelections: PropTypes.arrayOf(PropTypes.any),
};

EntityList.defaultProps = {
  data: [],
  onChange: null,
  selected: null,
  activeSelections: [],
};

export default EntityList;
