import moment from 'moment';
import { scheduledEventsAdapter } from './slices/scheduledEvents';
import { renderedEventsAdapter } from './slices/renderedEvents';

// scheduled events
const selectScheduledEventsState = (state) => state.scheduleData.scheduledEvents;
const scheduledEventsSelectors = scheduledEventsAdapter.getSelectors(
  selectScheduledEventsState,
);

const selectBlockScheduledEvents = (state, blockId) => {
  const blockScheduledEvents = scheduledEventsSelectors.selectById(state, blockId);
  if (!blockScheduledEvents || blockScheduledEvents.events === undefined) {
    return [];
  }
  return blockScheduledEvents.events;
};
const selectLoadingBlockScheduledEvents = (state, blockId) => {
  const blockScheduledEvents = scheduledEventsSelectors.selectById(state, blockId);
  return blockScheduledEvents && blockScheduledEvents.loading;
};

const selectCurrentBlockScheduledEvents = (state, blockId) => {
  const currentDate = moment();
  return selectBlockScheduledEvents(state, blockId).filter((event) => {
    if (moment(event.stop_date) >= currentDate) {
      return true;
    }
    if (!event.is_recurring) {
      return false;
    }
    if (event.repeat_end_type === 'never') {
      return true;
    }
    if (event.repeat_end_type === 'date') {
      return currentDate < moment(event.repeat_end_date);
    }
    // TODO: handle repeat_end_type === 'count'
    return true;
  });
};

const selectScheduledEventById = (state, { id, blockId }) => (
  selectBlockScheduledEvents(state, blockId).find((event) => event.id === id)
);

// rendered events

const selectRenderedEventsState = (state) => state.scheduleData.renderedEvents;
const renderedEventsSelectors = renderedEventsAdapter.getSelectors(
  selectRenderedEventsState,
);

const selectBlockRenderedSchedule = (state, { blockId, dateStart, dateEnd }) => {
  const blockRenderedSchedule = renderedEventsSelectors.selectById(state, blockId);
  if (!blockRenderedSchedule) {
    return {
      blockId,
      events: null,
      loading: false,
    };
  }
  const events = blockRenderedSchedule.events
    && blockRenderedSchedule.events.filter((event) => (
      moment(event.start_date) < dateEnd && moment(event.stop_date) > dateStart
    ));
  return {
    blockId,
    loading: blockRenderedSchedule.loading,
    events,
  };
};

const selectLatestBlockRenderedSchedule = (state, { blockId, dateStart, dateEnd }) => {
  const { events } = selectBlockRenderedSchedule(state, { blockId, dateStart, dateEnd });
  if (events && events.length) {
    return events.sort((a, b) => {
      if (a.start_date > b.start_date) return 1;
      if (a.start_date < b.start_date) return -1;
      return 0;
    })[0];
  }
  return null;
};

const selectLoadingBlockRenderedSchedule = (state, blockId) => {
  const blockRenderedSchedule = renderedEventsSelectors.selectById(state, blockId);
  return blockRenderedSchedule && blockRenderedSchedule.loading;
};

const selectBlockRenderedSchedules = (state, { blockIds, dateStart, dateEnd }) => {
  if (!blockIds) return [];
  return blockIds.map(
    (blockId) => selectBlockRenderedSchedule(state, { blockId, dateStart, dateEnd }),
  );
};

const selectLoadingBlockRenderedSchedules = (state, blockIds) => {
  const loadingBlocks = blockIds.map((blockId) => (
    selectLoadingBlockRenderedSchedule(state, blockId)
  ));
  return loadingBlocks.filter((loading) => loading === true).length > 0;
};

const selectBlockRenderedScheduleEvent = (state, { blockId, eventId, eventIndex }) => {
  const schedule = renderedEventsSelectors.selectById(state, blockId);
  if (!schedule || !schedule.events) return null;
  return schedule.events.find((event) => (
    event.id === eventId && event.event_index === eventIndex
  ));
};

const selectSchedulerTimelineDataRendered = (
  state,
  {
    blockIds,
    dateStart,
    dateEnd,
    blockColors,
  },
) => selectBlockRenderedSchedules(
  state,
  { blockIds, dateStart, dateEnd },
).reduce((events, schedule) => {
  if (!schedule.events) {
    return events;
  }
  const blockEvents = schedule.events.map((event) => ({
    ...event,
    id: event.uid,
    rootId: event.id,
    start_date: moment(event.start_date),
    end_date: moment(event.stop_date),
    irrId: 3, // scheduled
    name: null, // block name
    text: null, // text to display on event
    section_id: event.block,
    type: 'scheduled_events',
    color: blockColors[event.block],
  }));
  return events.concat(blockEvents);
}, []);

export {
  selectBlockScheduledEvents,
  selectCurrentBlockScheduledEvents,
  selectLoadingBlockScheduledEvents,
  selectScheduledEventById,
  // rendered
  selectBlockRenderedSchedule,
  selectBlockRenderedSchedules,
  selectLoadingBlockRenderedSchedules,
  selectSchedulerTimelineDataRendered,
  selectBlockRenderedScheduleEvent,
  selectLatestBlockRenderedSchedule,
};
