import React from 'react';
import PropTypes from 'prop-types';
import styles from './CommonStyle.less';

const RanchOption = ({ ranch }) => (
  <div>
    <span className={styles['left-align']}>{ranch.name}</span>
    <span className={styles['right-align']}>{ranch.entity && ranch.entity.name}</span>
  </div>
);

RanchOption.propTypes = {
  ranch: PropTypes.shape({
    name: PropTypes.string,
    entity: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default RanchOption;
