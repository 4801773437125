import { selectors } from 'farmx-redux-core';
import { stateColorCode } from './map-leaflet/constants';

const {
  selectPressureDetails,
} = selectors;

export default function calculatedHeightForMobile() {
  const mainDivElement = document.querySelector('.main-content');
  const footerDivElement = document.querySelector('.bottom-tabbar-container');
  const mainDivHeight = mainDivElement ? mainDivElement.offsetHeight : 0;
  const footerHeight = footerDivElement ? footerDivElement.offsetHeight : 0;

  return (mainDivHeight || footerHeight) ? (mainDivHeight + footerHeight) : window.innerHeight;
}

/**
 * @param {*} state - redux state
 * @param {*} blockIds - array of block ids to get pressure details
 * @returns the pressure detail object with waterPressureState
 */
export function getPressureDetailsForBlockIds(state, blockIds) {
  const pressureSensors = [];
  if (blockIds && blockIds.length) {
    blockIds.forEach((blockId) => {
      const pressureDetail = selectPressureDetails(state, blockId);
      const averagePressureCutOff = pressureDetail.pressureCutoff.reduce((p, c) => p + c, 0)
      / pressureDetail.pressureCutoff.length;
      const averagePressure = Number(pressureDetail.pressure);
      let waterPressureState = 0;
      if (averagePressure >= averagePressureCutOff) {
        waterPressureState = 4;
      } else if (averagePressure < averagePressureCutOff) {
        waterPressureState = 1;
      }
      const pressureDetailWithState = pressureDetail;
      pressureDetail.waterPressureState = waterPressureState;
      pressureSensors.push({ blockId, pressureDetail: pressureDetailWithState });
    });
  }
  const pressureSensorsObj = pressureSensors.reduce((acc, obj) => {
    acc[obj.blockId] = obj.pressureDetail;
    return acc;
  }, {});
  return pressureSensorsObj;
}

/**
 * @param {*} waterPressureState - number 0 to 4
 * @returns the irrigation state and color
 */
export function getBlockIrrigationStateByWaterPressureState(waterPressureState) {
  let irrigationState = 'unknown';
  const color = stateColorCode[waterPressureState].markerColor;
  if (waterPressureState === 4) irrigationState = 'irrigating';
  if (waterPressureState === 1) irrigationState = 'not irrigating';
  return { irrigationState, color };
}
