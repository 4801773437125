import L from 'leaflet';
import { stateColorCode } from './constants';

export function prepareRanchMarker(feature, latlng) {
  const opts = {
    markerColor: '#fff',
    iconColor: '#000',
  };

  if (feature.properties.state && feature.properties.state.state) {
    const { state } = feature.properties.state; // convert from soil state to overall state
    const { markerColor } = stateColorCode[state];
    opts.markerColor = markerColor;
    opts.iconColor = 'white';
  }

  const markerIcon = L.VectorMarkers.icon({
    icon: 'icon-ranch',
    prefix: 'icon',
    markerColor: opts.markerColor,
    iconColor: opts.iconColor,
    dataTestId: feature.properties.id,
    selected: false,
    selectedClass: '',
  });

  // eslint-disable-next-line new-cap
  const marker = new L.marker(latlng, { icon: markerIcon });

  return marker;
}
