/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { userApi } from 'farmx-api';

const { changePassword } = userApi;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 16 },
};

export default function ChangePasswordPage() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [alertData, setAlertData] = useState(null);

  async function onFinish(values) {
    const { currentPassword, newPassword } = values;
    try {
      const response = await changePassword(currentPassword, newPassword);
      if (response.status === 200) {
        setAlertData({
          message: t('Password changed'),
          type: 'success',
        });
        form.resetFields();
      }
    } catch (error) {
      const { response } = error;
      const { status } = response;
      if (status === 400) {
        setAlertData({
          message: t('Incorrect password'),
          type: 'error',
        });
      } else {
        setAlertData({
          message: t('Failed to change password'),
          type: 'error',
        });
      }
    }
  }

  function renderAlert() {
    if (!alertData) return null;
    const { message, type } = alertData;
    return (
      <Form.Item
        wrapperCol={{ span: 28 }}
      >
        <Alert
          message={message}
          type={type}
          showIcon
        />
      </Form.Item>
    );
  }

  return (
    <Form
      className="change-password-form"
      onFinish={onFinish}
      form={form}
      {...layout}
    >
      {renderAlert()}
      <Form.Item
        name="currentPassword"
        label={t('Current password')}
        rules={[{
          required: true,
          message: t('Please input your current password'),
        }]}
      >
        <Input.Password
          placeholder={t('Current Password')}
          autoComplete="current-password"
        />
      </Form.Item>
      <Form.Item
        name="newPassword"
        label={t('New password')}
        rules={[{
          required: true,
          message: t('Please input your new password'),
        }]}
      >
        <Input.Password
          placeholder={t('New Password')}
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        name="confirmNewPassword"
        dependencies={['newPassword']}
        label={t('Confirm new password')}
        rules={[{
          required: true,
          message: t('Please enter your new password again'),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve();
            }
            return Promise.reject(Error('The two passwords that you entered do not match!'));
          },
        })]}
      >
        <Input.Password
          placeholder={t('Confirm New Password')}
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" className="change-password-button" data-test-id="change-password-submit">
          {t('Change Password')}
        </Button>
      </Form.Item>
    </Form>
  );
}
