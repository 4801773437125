import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectRanchById } from '../selectors';
import { loadRanchList } from '../actions';

export default function useRanch(id) {
  const dispatch = useDispatch();
  const ranch = useSelector((state) => selectRanchById(state, id));
  useEffect(() => {
    dispatch(loadRanchList());
  }, [dispatch]);
  return ranch;
}
