import { createTransform } from 'redux-persist';

const transform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => inboundState,
  // transform state being rehydrated
  (outboundState) => {
    outboundState.stats.sensor.loading = false;
    outboundState.stats.gateway.loading = false;
    outboundState.stats.cavalier.loading = false;
    return ({ ...outboundState });
  },
  // define which reducers this transform gets called for.
  { whitelist: ['sensorsData'] },
);

const farmDataTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => inboundState,
  // transform state being rehydrated
  (outboundState) => {
    outboundState.blocks.loading = false;
    outboundState.ranches.loading = false;
    outboundState.entities.loading = false;
    return ({ ...outboundState });
  },
  // define which reducers this transform gets called for.
  { whitelist: ['farmData'] },
);

export { transform, farmDataTransform };
