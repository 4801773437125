import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { authService } from 'farmx-api';

export default function LogoutRedirect() {
  const [processing, setProcessing] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    authService.logout(dispatch).then(() => {
      setProcessing(false);
    });
  }, [setProcessing, dispatch]);

  if (processing) return null;
  return <Redirect to="/" />;
}
