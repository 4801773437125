import cloneDeep from 'lodash/cloneDeep';

// Accept geoJSONState sensors all, so it would fix the zero
export function prepareZeroIslandSensors(sensorsGeoJSON) {
  const sensors = cloneDeep(sensorsGeoJSON);

  const zeroIslands = [];
  const gw = {};
  sensors.features.forEach((s, i) => {
    const { coordinates } = s.geometry;
    const { type, id } = s.properties;

    if (type === 'gateway') {
      gw[id] = coordinates;
    }

    if (!coordinates[0] && !coordinates[1]) {
      zeroIslands.push({ gw: s.properties.gateway, idx: i });
    }
  });

  if (!zeroIslands.length) {
    return sensorsGeoJSON;
  }
  zeroIslands.forEach((z) => {
    if (gw[z.gw]) {
      sensors.features[z.idx].geometry.coordinates = gw[z.gw];
    }
  });

  return sensors;
}
