import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { plansApi } from 'farmx-api';

const name = 'plans';

const initialState = {
  loading: false,
  currentRequestId: undefined,
  error: null,
};

export const plansAdapter = createEntityAdapter();

export const loadPlansByRanchId = createAsyncThunk(
  `${name}/fetchByRanchId`,
  async (ranchId) => {
    const response = await plansApi.fetchPlansByRanchId(ranchId);
    return response.data;
  },
);

export const loadPlanById = createAsyncThunk(
  `${name}/fetchById`,
  async (id) => {
    const response = await plansApi.fetchPlanById(id);
    return response.data;
  },
);

export const plansSlice = createSlice({
  name,
  initialState: plansAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [loadPlansByRanchId.pending]: (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.error = undefined;
        state.currentRequestId = action.meta.requestId;
      }
    },

    [loadPlansByRanchId.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === true && state.currentRequestId === requestId) {
        const lastUpdated = Date.now();
        state.lastUpdated = lastUpdated;
        state.loading = false;
        state.currentRequestId = undefined;
        state.error = undefined;
        plansAdapter.setAll(state, action.payload);
      }
    },

    [loadPlansByRanchId.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === true && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [loadPlanById.pending]: (state, action) => {
      const id = action.meta.arg;
      plansAdapter.upsertOne(state, {
        id,
        loading: true,
        error: undefined,
        currentRequestId: action.meta.requestId,
      });
    },

    [loadPlanById.fulfilled]: (state, action) => {
      if (!action.payload) {
        return;
      }
      const { payload: item } = action;
      item.lastUpdated = Date.now();
      item.loading = false;
      item.currentRequestId = undefined;
      item.error = undefined;
      plansAdapter.upsertOne(state, { ...item, error: undefined });
    },

    [loadPlanById.rejected]: (state, action) => {
      const id = action.meta.arg;
      plansAdapter.upsertOne(state, {
        id,
        loading: false,
        error: action.error,
        currentRequestId: undefined,
      });
    },
  },
});

export default plansSlice.reducer;

export const patchPlan = createAsyncThunk(
  `${name}/patch`,
  async (planData, { rejectWithValue }) => {
    try {
      const { id } = planData;
      const response = await plansApi.patchPlanById(id, planData);
      return response.data;
    } catch (err) {
      const error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const updatePlan = createAsyncThunk(
  `${name}/update`,
  async (planData, { rejectWithValue }) => {
    try {
      const { id } = planData;
      const response = await plansApi.updatePlanById(id, planData);
      return response.data;
    } catch (err) {
      const error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPlan = createAsyncThunk(
  `${name}/create`,
  async (planData, { rejectWithValue }) => {
    try {
      const response = await plansApi.createPlan(planData);
      return response.data;
    } catch (err) {
      const error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
