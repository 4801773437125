import React from 'react';
import PropTypes from 'prop-types';
import styles from './CommonStyle.less';

const EntityOption = ({ entity }) => (
  <div>
    <span className={styles['left-align']}>{entity.name}</span>
  </div>
);

EntityOption.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default EntityOption;
