import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

function InstallStateTag({ state }) {
  function getColorForState(installState) {
    switch (installState) {
      case 'removed':
        return 'red';
      case 'installed':
        return 'green';
      case 'planned':
        return 'cyan';
      case 'withdrawn':
        return 'purple';
      case 'inventory':
        return 'blue';
      case 'unknown':
        return 'geekblue';
      case 'dead':
        return 'black';
      case 'maintenance':
        return 'magenta';
      default:
        return null;
    }
  }

  return <Tag color={getColorForState(state)}>{state}</Tag>;
}

InstallStateTag.propTypes = {
  state: PropTypes.string,
};

InstallStateTag.defaultProps = {
  state: null,
};
export default InstallStateTag;
