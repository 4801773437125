import moment from 'moment';

function getTimeDiff(startDate, endDate) {
  const min = endDate.diff(startDate, 'minutes');
  const sec = endDate.diff(startDate, 'seconds');
  if (min < 0 || sec < 0) {
    return null;
  }
  if (min > 59) {
    const hr = Math.floor(min / 60);
    const m = (min % 60);
    return `${hr} hrs and ${m} mins`;
  }
  if (sec > 59) {
    return `${min} mins`;
  } return `${sec} secs`;
}

function getTimingAttr(openTime, closeTime) {
  let timeOpen;
  let timeClose;
  let opensFirst;
  if (openTime) {
    timeOpen = getTimeDiff(
      moment(),
      moment(openTime),
    );
  }
  if (closeTime) {
    timeClose = getTimeDiff(
      moment(),
      moment(closeTime),
    );
  }
  if (openTime && closeTime) {
    opensFirst = !!getTimeDiff(
      moment(openTime),
      moment(closeTime),
    );
  }
  return [timeOpen, timeClose, opensFirst];
}

// Get the schedule message for valve
export const getValveScheduleMessage = (t, online, state, openTime, closeTime, overrideState) => {
  const [timeOpen, timeClose, opensFirst] = getTimingAttr(openTime, closeTime);
  if (state) {
    if (timeOpen && opensFirst) {
      if (state !== 'closed' && overrideState !== state) {
        return `${t('Closing, scheduled to open in')} ${timeOpen}`;
      }
      if (overrideState !== undefined) {
        return `${t('Scheduled to open in')} ${timeOpen}.`;
      }
      return `${t('Opens in')} ${timeOpen}`;
    }
    if (timeClose && !opensFirst) {
      if (state !== 'open' && overrideState !== state) {
        return `${t('Opening, scheduled to close in')} ${timeClose}`;
      }
      if (overrideState !== undefined) {
        return `${t('Scheduled to close in')} ${timeClose}.`;
      }
      return `${t('Closes in')} ${timeClose}`;
    }
    if (state === 'unknown') {
      return t('Disconnected');
    }
    if (!online && state === 'closed') {
      return t('Disconnected (Closed)');
    }
    if (state !== 'closed' && !overrideState) {
      return t('Closing');
    }
    return t('Nothing scheduled');
  }
  return '';
};

// Get the schedule message for pump
export const getPumpScheduleMessageBasic = (t, startDate, stopDate) => {
  const [timeOn, timeOff, onFirst] = getTimingAttr(startDate, stopDate);
  if (timeOn && onFirst) {
    return `${t('On in')} ${timeOn}.`;
  }
  if (timeOff && !onFirst) {
    return `${t('Off in')} ${timeOff}.`;
  }
  return null;
};

// Get the schedule message for pump
export const getPumpScheduleMessage = (t, online, state, startDate, stopDate, overrideState) => {
  const [timeOn, timeOff, onFirst] = getTimingAttr(startDate, stopDate);
  if (state) {
    if (timeOn && onFirst) {
      if (state !== 'off' && overrideState !== state) {
        return `${t('Turning off, scheduled to turn on in')} ${timeOn}`;
      }
      if (overrideState !== undefined) {
        return `${t('Scheduled to turn on in')} ${timeOn}.`;
      }
      return `${t('On in')} ${timeOn}.`;
    }
    if (timeOff && !onFirst) {
      if (state !== 'on' && overrideState !== state) {
        return `${t('Turning on, scheduled to turn off in')} ${timeOff}`;
      }
      if (overrideState !== undefined) {
        return `${t('Scheduled to turn off in')} ${timeOff}.`;
      }
      return `${t('Off in')} ${timeOff}.`;
    }
    if (state === 'unknown') {
      return t('Disconnected');
    }
    if (!online && state === 'off') {
      return t('Disconnected (off)');
    }
    if (state !== 'off' && !overrideState) {
      return t('Turning off');
    }
    return t('Nothing scheduled');
  }
  return '';
};
