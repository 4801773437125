import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const getColorForValue = (value) => {
  if (value === 'ok') return 'green';
  if (value === 'warning') return 'yellow';
  if (value === 'error') return 'red';
  return undefined;
};

const values = ['ok', 'warning', 'error'];

function renderLabelForValue(value, labels) {
  const [labelOk, labelWarning, labelError] = labels;
  if (value === 'ok') return labelOk;
  if (value === 'warning') return labelWarning;
  if (value === 'error') return labelError;
  return 'unknown';
}

function StoplightTag({ value, labels }) {
  if (value === undefined || value === null) return null;
  return (
    <Tag color={getColorForValue(value)}>
      { renderLabelForValue(value, labels) }
    </Tag>
  );
}

StoplightTag.propTypes = {
  value: PropTypes.oneOf([...values, undefined]),
  labels: PropTypes.arrayOf(PropTypes.string),
};

StoplightTag.defaultProps = {
  value: undefined,
  labels: values,
};

export default StoplightTag;
