import {
  createSlice,
} from '@reduxjs/toolkit';

const name = 'selected';

const selectedSlice = createSlice({
  name,
  initialState: {
    blocks: [],
    ranches: [],
    block: [],
    ranch: [],
    entitySidebarState: true,
    urlLoad: { payload: true },
    ranchBlock: { payload: { key: undefined, value: undefined } },
    userInfo: { payload: undefined },
  },
  reducers: {
    setSelectedBlock: (state, data) => { state.block = data; },
    setSelectedRanch: (state, data) => { state.ranch = data; },
    setSelectedBlocks: (state, data) => { state.blocks = data; },
    setSelectedRanches: (state, data) => { state.ranches = data; },
    setEntitySidebarCollapseState: (state, data) => { state.entitySidebarState = data; },
    setUrlLoad: (state, data) => { state.urlLoad = data; },
    setRanchBlock: (state, data) => { state.ranchBlock = data; },
    setLoginUserInfo: (state, data) => { state.userInfo = data; },
  },
});

const setActions = selectedSlice.actions;

export { setActions };

export default selectedSlice.reducer;
