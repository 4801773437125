import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { sensorApi } from 'farmx-api';

const { getNameForSensorType } = sensorApi;

export default function SensorTypeTag({ sensorType }) {
  function getColorForType(type) {
    switch (type) {
      case 'weather_station':
        return 'geekblue';
      case 'cavalier':
        return 'purple';
      case 'aquacheck_soil':
        return 'volcano';
      case 'water_pressure':
        return 'blue';
      case 'farmx_dendrometer':
        return 'green';
      case 'pixl_soil':
        return 'orange';
      case 'farmx_infrared':
        return 'lime';
      case 'farmx_frost':
        return 'cyan';
      default:
        return null;
    }
  }
  const color = getColorForType(sensorType);
  return <Tag color={color}>{getNameForSensorType(sensorType)}</Tag>;
}

SensorTypeTag.propTypes = {
  sensorType: PropTypes.string.isRequired,
};
