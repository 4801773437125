import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Tag } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import Moment from 'react-moment';
import moment from 'moment';
import BooleanTag from '../BooleanTag';
import SensorAlarmButton from './SensorAlarmButton';
import PumpStateTag from './PumpStateTag';
import ControlSummaryRefreshContext from './RefreshContext';
import styles from './CommonStyles.less';
import { getPressureDetailsForBlockIds, getBlockIrrigationStateByWaterPressureState } from '../helper';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few secs',
    ss: '%d secs',
    m: 'a min',
    mm: '%d mins',
    h: 'an hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

const {
  selectBlockPressureSensors,
  selectBlockPumpController,
  selectBlockFlowMeter,
  selectValveControllersForBlocks,
} = selectors;

const {
  loadSensorStatus,
} = actions;

const { useBlockNames } = hooks;

export default function BlockControlSensorList(props) {
  const { controlStatus, valves, showBlockList } = props;
  const { blockId } = controlStatus;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refreshId = useContext(ControlSummaryRefreshContext);

  const pressureSensors = useSelector(
    (state) => selectBlockPressureSensors(state, blockId),
  );
  const pumpController = useSelector(
    (state) => selectBlockPumpController(state, blockId),
  );
  const flowMeter = useSelector(
    (state) => selectBlockFlowMeter(state, blockId),
  );

  const valvesIds = controlStatus.valves ? controlStatus.valves.valveIds || [] : [];
  const filteredValveObjArr = valves.filter((d) => d && d.irrigation_blocks.includes(blockId)
   && valvesIds.includes(d.id));
  const valveBlockIds = [];
  const vIdentifiers = [];
  filteredValveObjArr.forEach((d) => {
    if (d) {
      vIdentifiers.push({ type: d.type, identifier: d.identifier });
    }
    if (d && d.irrigation_blocks && d.irrigation_blocks.length
      && !vIdentifiers.includes({ type: d.type, identifier: d.identifier })) {
      valveBlockIds.push(...d.irrigation_blocks);
    }
  });
  const uniqueValveBlockIds = valveBlockIds.filter((v, i, a) => a.indexOf(v) === i);

  const valveControllerObj = useSelector(
    (state) => selectValveControllersForBlocks(state, uniqueValveBlockIds.length
      ? uniqueValveBlockIds : [blockId]),
  );
  const valveControllersFromObj = Object.values(valveControllerObj).length
    ? Object.values(valveControllerObj) : [];
  const valveController = valveControllerObj[blockId];

  const valveControllers = [];
  valveControllersFromObj.forEach((items) => {
    items.forEach((item) => {
      if (item.irrigation_blocks && item.irrigation_blocks.includes(blockId)) {
        const i = valveControllers.findIndex((x) => x.identifier === item.identifier);
        if (i <= -1) {
          valveControllers.push(item);
        }
      }
    });
  });

  // To show associated block list for valve under valves section
  const blockIds = controlStatus && controlStatus.irrigation_blocks;
  const blockNames = useBlockNames(blockIds);
  const pressureSensorsObj = useSelector((state) => getPressureDetailsForBlockIds(state, blockIds));

  const blockArr = [];
  if (showBlockList) {
    blockIds.forEach((d, i) => {
      const blockObj = {
        blockName: blockNames[d],
        blockId: d,
        type: controlStatus.irrigation_blocks[i] === d && controlStatus.type,
        identifier: controlStatus.irrigation_blocks[i] === d && controlStatus.identifier,
        status: { alarmCount: controlStatus.blockAlarmCount },
      };
      blockArr.push(blockObj);
    });
  }

  const {
    identifier: pumpControllerIdentifier,
    type: pumpControllerType,
  } = pumpController;
  const {
    identifier: flowMeterIdentifier,
    type: flowMeterType,
  } = flowMeter || {};

  const {
    identifier: valveControllerIdentifier,
    type: valveControllerType,
  } = valveController;

  let valveIdentifiers = '';
  if (!vIdentifiers.includes({
    identifier: valveControllerIdentifier,
    type: valveControllerType,
  })) {
    valveIdentifiers = JSON.stringify([...vIdentifiers, {
      identifier: valveControllerIdentifier,
      type: valveControllerType,
    }]);
  }
  const pressureSensorIdentifiers = JSON.stringify(
    pressureSensors.map((sensor) => sensor.identifier),
  );

  const refreshPressureSensorStatus = useCallback(() => {
    if (refreshId) {
      JSON.parse(pressureSensorIdentifiers).forEach((identifier) => {
        const sensorParams = { type: 'water_pressure', identifier };
        dispatch(loadSensorStatus(sensorParams));
      });
    }
  }, [dispatch, refreshId, pressureSensorIdentifiers]);

  useEffect(() => {
    refreshPressureSensorStatus();
  }, [refreshPressureSensorStatus]);

  useEffect(() => {
    const sensorParams = {
      type: pumpControllerType,
      identifier: pumpControllerIdentifier,
    };
    if (pumpControllerIdentifier) {
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, pumpControllerType, pumpControllerIdentifier]);

  useEffect(() => {
    if (refreshId && flowMeterIdentifier && flowMeterType) {
      const sensorParams = { type: flowMeterType, identifier: flowMeterIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, refreshId, flowMeterType, flowMeterIdentifier]);

  useEffect(() => {
    if (JSON.parse(valveIdentifiers).length) {
      JSON.parse(valveIdentifiers).forEach((sensorParams) => {
        dispatch(loadSensorStatus(sensorParams));
      });
    }
  }, [dispatch, valveIdentifiers]);

  const getCurrentState = (valveState, valveOnline) => {
    const color = valveState === 'open' ? '#1890FF' : '#D40202';
    if (valveState) {
      if (valveState !== 'unknown') {
        if (valveOnline && valveState === 'closed') {
          return (
            <Tag color="" key={valveState} className={styles['tag-icon']}>
              {t('Closed').toUpperCase()}
            </Tag>
          );
        }
        const state = valveState === 'open'
          ? t('Open_status').toUpperCase()
          : valveState;
        return (
          <Tag
            color={color}
            key={valveState}
            className={[styles['tag-icon'], styles['tag-icon-irrigating']]}
          >
            {state.toUpperCase()}
          </Tag>
        );
      }
      return <WarningFilled className={styles['warning-icon']} />;
    }
    if (!valveState) {
      return <WarningFilled className={styles['warning-icon']} />;
    }
    return '';
  };

  function renderSensorName(sensor) {
    if (!sensor.name) {
      return <span>{t('No Name')}</span>;
    }
    return (
      <Tooltip title={sensor.name}>
        <span>{sensor.name}</span>
      </Tooltip>
    );
  }

  function renderPressure(sensor) {
    const { status } = sensor;
    if (!status || status.psi === undefined || status.psi === null) return null;
    return (
      <div>{`${status.psi.toFixed(1)} psi`}</div>
    );
  }

  function renderSensorConnectivity(sensor) {
    if (!sensor.status) return null;
    return (
      <BooleanTag value={sensor.status.online} labels={[t('online'), t('offline')]} />
    );
  }

  function renderLatestDate(sensor) {
    if (!sensor.status || !sensor.status.latestDate) return null;
    const { latestDate: date } = sensor.status;
    return (
      <Tooltip
        title={<Moment format="YYYY-MM-DD h:mm:ss A" local>{date}</Moment>}
        placement="bottom"
      >
        <span>
          <Moment fromNow>{date}</Moment>
        </span>
      </Tooltip>
    );
  }

  function renderSensorAlarms(sensor) {
    return (
      <SensorAlarmButton
        type={sensor.type}
        identifier={sensor.identifier}
        alarmCount={sensor.status && sensor.status.alarmCount}
      />
    );
  }

  function renderFlow(sensor) {
    const { status } = sensor;
    if (!status || status.flowRate === undefined || status.flowRate.value === undefined) {
      return null;
    }
    return (
      <div>{`${status.flowRate.value.toFixed(1)} ${status.flowRate.units}`}</div>
    );
  }

  function widthCalc(columns) {
    const maxWidth = 100;
    const result = columns().map((column) => {
      if (column.key !== 'alarms') {
        return {
          ...column,
          width: `${(maxWidth - 10) / (columns().length - 1)}%`,
        };
      }
      return column;
    });
    return result;
  }

  const pressureColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles['schedule-item-header'],
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('Pressure'),
      key: 'pressure',
      className: styles['state-header'],
      render: (sensor) => renderPressure(sensor),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles['last-data'],
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles['alarms-header'],
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const flowMeterColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles['schedule-item-header'],
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('Flow'),
      key: 'flow',
      className: styles['state-header'],
      render: (sensor) => renderFlow(sensor),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles['last-data'],
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles['alarm-header'],
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const pumpControllerColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles['schedule-item-header'],
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('State'),
      className: styles['state-header'],
      key: 'state',
      render: (sensor) => (
        <PumpStateTag
          state={sensor.status && sensor.status.controlStatus && sensor.status.controlStatus.status}
          loading={sensor.status && sensor.status.loading}
          online={sensor.status && sensor.status.online}
        />
      ),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles['last-data'],
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles['alarms-header'],
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const valveControllerColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles['schedule-item-header'],
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('State'),
      className: styles['state-header'],
      key: 'state',
      render: (sensor) => (
        getCurrentState(sensor.status && sensor.status.controlStatus
          && sensor.status.controlStatus.state, sensor.status && sensor.status.loading)
      ),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles['last-data'],
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles['alarms-header'],
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const blockList = () => [
    {
      title: t('Name'),
      key: 'blockName',
      className: styles['schedule-item-header'],
      render: (d) => d.blockName,
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles['hidden-column'],
      render: null,
    },
    {
      title: t('Irrigation State'),
      className: styles['state-header'],
      render: (d) => {
        const waterPressureState = pressureSensorsObj[d.blockId]
          && pressureSensorsObj[d.blockId].waterPressureState;
        const statusDetails = getBlockIrrigationStateByWaterPressureState(waterPressureState);
        return (
          <Tag
            color={statusDetails.color}
            className={waterPressureState > 1
              ? [styles['tag-icon-irrigation-state'], styles['tag-icon-irrigating']]
              : styles['tag-icon-irrigation-state']}
          >
            {statusDetails.irrigationState && statusDetails.irrigationState.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: t('Last Data'),
      className: styles['hidden-column'],
      render: null,
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles['alarm-header'],
      render: (d) => renderSensorAlarms(d),
    },
  ];

  const pumpControllers = pumpControllerIdentifier ? [pumpController] : null;

  return (
    <div>
      {pumpControllers && (
        [
          <span className={styles['sub-header']} key="header">{t('Pump Control')}</span>,
          <Table
            key="table"
            dataSource={pumpControllers}
            columns={widthCalc(pumpControllerColumns)}
            rowKey={(record) => record.identifier}
            size="small"
            className={styles['inner-table']}
            pagination={false}
          />,
        ]
      )}
      {valveController && (
        [
          <span className={styles['sub-header']} key="header">
            {showBlockList ? t('Blocks') : t('Valve Control')}
          </span>,
          <Table
            key="table"
            dataSource={showBlockList ? blockArr : valveControllers}
            columns={widthCalc(showBlockList ? blockList : valveControllerColumns)}
            rowKey={(record) => record.identifier}
            size="small"
            className={styles['inner-table']}
            pagination={false}
          />,
        ]
      )}
      {flowMeter && (
        [
          <span className={styles['sub-header']} key="header">{t('Flow Meter')}</span>,
          <Table
            key="table"
            dataSource={[flowMeter]}
            columns={widthCalc(flowMeterColumns)}
            rowKey={(record) => record.identifier}
            size="small"
            className={styles['inner-table']}
            pagination={false}
          />,
        ]
      )}
      <span className={styles['sub-header']}>{t('Pressure Sensors')}</span>
      <Table
        dataSource={pressureSensors}
        columns={widthCalc(pressureColumns)}
        rowKey={(record) => record.identifier}
        size="small"
        className={styles['inner-table']}
        pagination={false}
      />
    </div>
  );
}

BlockControlSensorList.propTypes = {
  controlStatus: PropTypes.shape().isRequired,
  valves: PropTypes.arrayOf(PropTypes.any),
  showBlockList: PropTypes.bool,
};

BlockControlSensorList.defaultProps = {
  valves: [],
  showBlockList: false,
};
