import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import { Tag, Select } from 'antd';
import ItemSelect from './ItemSelect';
import EntityOption from './EntityOption';
import DropdownModal from './sensor/DropdownModal';
import SensorSelectContext from './sensor/SensorSelectContext';
import styles from './CommonStyle.less';

const mobileScreen = window.innerWidth < 599;

const { Option } = Select;

const { loadFarmData } = actions;

const {
  selectAllEntities,
} = selectors;

const entityTagRender = (args) => {
  const { label, closable, onClose } = args;
  return (
    <Tag
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label.props ? label.props.entity.name : label}
    </Tag>
  );
};

const defaultItemRenderer = (d) => (
  <Option
    className={styles['add-color-to-even-rows']}
    key={d.id}
    value={d.id}
    label={d.name}
    data-testid={`${d.name}`}
  >
    <EntityOption entity={d} />
  </Option>
);

const EntitySelect = ({
  loadItems,
  items,
  mode,
  renderItem,
  onSelect,
  onChange,
  tagRender,
  placeholder,
  defaultOption,
  applyFilter,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(selectAllEntities);
  const ranches = items || data;
  const [modal, setModalState] = useState(false);
  const [searchText, setSearchText] = useState('');
  let dropdownStyle = null;
  if (mobileScreen) dropdownStyle = { minWidth: '60%' };
  const dropdownStyleValues = useContext(SensorSelectContext);

  useEffect(() => {
    if (loadItems) loadItems();
    else dispatch(loadFarmData(applyFilter));
  }, [applyFilter, dispatch, loadItems]);

  function customFilterMobile(searchStr) {
    const filterResult = ranches.filter((d) => ((d && d.name
       && d.name.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0)
    ));
    return filterResult;
  }

  return (
    <ItemSelect
      mode={mode}
      renderItem={renderItem}
      items={!mobileScreen ? ranches : customFilterMobile(searchText)}
      onSelect={onSelect}
      onChange={onChange}
      tagRender={tagRender || entityTagRender}
      placeholder={placeholder}
      defaultOption={defaultOption}
      filterOption={(input, option) => option.children.props.entity.name.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
        || (option.children.props.entity
          && option.children.props.entity.name
            .toLowerCase().indexOf(input.toLowerCase()) >= 0)}
      dropdownStyle={dropdownStyle}
      menuIsOpen={mobileScreen ? modal : undefined}
      dropdownRender={mobileScreen ? (menu) => (
        <DropdownModal
          title="Entity Select"
          displayOptions={menu}
          mobileScreen={mobileScreen}
          onClick={() => setModalState(false)}
          onChange={(d) => setSearchText(d.target.value)}
          displaySearch
          size={mobileScreen ? 'large' : 'middle'}
        />
      ) : undefined}
      dropdownStyleValues={dropdownStyleValues}
      onDropdownVisibleChange={mobileScreen ? () => setModalState(true) : undefined}
      showSearch={!mobileScreen}
    />
  );
};

EntitySelect.propTypes = {
  mode: PropTypes.string,
  loadItems: PropTypes.func,
  renderItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  tagRender: PropTypes.func,
  placeholder: PropTypes.string,
  defaultOption: PropTypes.arrayOf(PropTypes.any),
  applyFilter: PropTypes.bool,
};

EntitySelect.defaultProps = {
  mode: null,
  loadItems: null,
  items: null,
  renderItem: defaultItemRenderer,
  onSelect: null,
  onChange: null,
  tagRender: null,
  placeholder: null,
  defaultOption: [],
  applyFilter: true,
};

export default EntitySelect;
