import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadUserSettings } from '../../settings/userSettingsSlice';

export function useLoadUserSettings() {
  const dispatch = useDispatch();

  useEffect(() => {
    const userSettingsLoaded = JSON.parse(sessionStorage.getItem('userSettingsLoaded'));

    if (!userSettingsLoaded) {
      sessionStorage.setItem('userSettingsLoaded', true);
      dispatch(loadUserSettings());
    }
  }, [dispatch]);
}
