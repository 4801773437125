// Irrigation Recommendations
// Currently stored by block id

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { recommendationApi } from 'farmx-api';
import {
  initialState,
  receiveItem,
  receiveItemData,
  loadDetail,
  loadList,
  requestList,
  receiveList,
  requestListFailed,
} from '../helpers';

const {
  retrieveIrrigationRecommendationsForUser,
  retrieveIrrigationRecommendationForBlock,
  patchIrrigationRecommendation,
} = recommendationApi;

export const recommendationAdapter = createEntityAdapter({
  selectId: (entity) => entity.block,
});

const name = 'recommendation';

export const loadRecommendationForBlock = createAsyncThunk(
  `${name}/loadRecommendationForBlock`,
  loadDetail({
    getData: (id) => retrieveIrrigationRecommendationForBlock({ blockId: id }),
    getRequestState: (id, state) => state.recommendationData.recommendation.entities[id],
  }),
);

export const updateRecommendation = createAsyncThunk(
  `${name}/updateRecommendation`,
  async ({ id, data }) => patchIrrigationRecommendation({
    id,
    data,
  }),
);

export const loadRecommendations = createAsyncThunk(
  `${name}/loadRecommendationsForUser`,
  loadList({
    getData: retrieveIrrigationRecommendationsForUser,
    getRequestState: (state) => state.recommendationData.recommendation,
  }),
);

const recommendationSlice = createSlice({
  name,
  initialState: recommendationAdapter.getInitialState(initialState),
  reducers: {
    cleanListRequestState(state) {
      state.loading = false;
      state.currentRequestId = undefined;
      state.error = null;
    },
  },
  extraReducers: {
    [loadRecommendationForBlock.pending]: (state, action) => {
      const blockId = action.meta.arg;
      recommendationAdapter.upsertOne(state, {
        block: blockId,
        loading: true,
        currentRequestId: action.meta.requestId,
        error: undefined,
      });
    },
    [loadRecommendationForBlock.fulfilled]: receiveItem(recommendationAdapter),
    [loadRecommendationForBlock.rejected]: (state, action) => {
      const blockId = action.meta.arg;
      recommendationAdapter.upsertOne(state, {
        block: blockId,
        loading: false,
        error: action.error,
        currentRequestId: undefined,
      });
    },
    [loadRecommendations.pending]: requestList,
    [loadRecommendations.fulfilled]: receiveList(recommendationAdapter),
    [loadRecommendations.rejected]: requestListFailed,
    [updateRecommendation.fulfilled]: receiveItemData(recommendationAdapter),
  },
});

export { recommendationSlice };
export default recommendationSlice.reducer;
