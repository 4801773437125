import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Switch, Select } from 'antd';
import './MapSettingsMenu.less';

export function MapSettingsMenu({
  renderItemShowLabels,
  renderItemPresentationMode,
  onChangePresentationMode,
  showLabels,
  toggleShowLabels,
}) {
  return (
    <Menu className="settings-menu" selectable={false}>
      {renderItemPresentationMode && (
        <Menu.Item key="1">
          <Select
            defaultValue="connectivity"
            style={{
              width: 140,
              // position: 'absolute',
              // zIndex: 1,
              // top: 10,
              // left: 10,
            }}
            onChange={onChangePresentationMode}
            data-test-id="presentationmode-select"
          >
            <Select.Option value="connectivity" className="presentationmode-option-connectivity">Connectivity</Select.Option>
            <Select.Option value="waterpressure" className="presentationmode-option-waterpressure">Water Pressure</Select.Option>
            <Select.Option value="provision" className="presentationmode-option-provision">Provision</Select.Option>
          </Select>
        </Menu.Item>
      )}

      {renderItemShowLabels && (
        <Menu.Item key="2">
          <span>Show Labels:</span>
          <Switch checked={showLabels} onChange={toggleShowLabels} className="switch-toggle-labels" />
        </Menu.Item>
      )}

    </Menu>
  );
}

MapSettingsMenu.propTypes = {
  renderItemShowLabels: PropTypes.bool.isRequired,
  renderItemPresentationMode: PropTypes.bool.isRequired,
  onChangePresentationMode: PropTypes.func.isRequired,
  showLabels: PropTypes.bool.isRequired,
  toggleShowLabels: PropTypes.func.isRequired,
};
