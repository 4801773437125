import { useSelector } from 'react-redux';
import {
  selectAllEntities, selectAllBlocks, selectAllRanches, selectAllBlockIds, selectAllRanchIds,
  selectEntityIdForRanchBlock, selectBlocksForRanchBlockSelection,
} from '../selectors';
import { selectSelectedBlock, selectSelectedRanch, selectLoginUserInfo } from '../../client/selectors';

function useEntityRanchBlock(data) {
  // Get all entities, blocks and ranches
  const entity = useSelector(selectAllEntities);
  const ranch = useSelector(selectAllRanches);
  const block = useSelector(selectAllBlocks);

  // Function to process data and return array of data objects
  function processData(entityData, blockData, ranchData) {
    const resultData = [];
    // Get ranchIds, BlockIds and entityIds from blockData
    const entityIdFromBlock = blockData.map((d) => (d.ranch
          && d.ranch.entity && d.ranch.entity.id));
    const ranchIdFromBlock = blockData.map((d) => (d.ranch && d.ranch.id));
    const blockIdFromBlock = blockData.map((d) => (d && d.id));

    // Get ranchIds, BlockIds and entityIds from ranchData
    const entityIdFromRanch = ranchData.map((d) => (d.entity && d.entity.id));
    const ranchIdFromRanch = ranchData.map((d) => (d && d.id));
    const BlockIdFromRanch = [];
    ranchData.forEach((d) => {
      const bid = block.filter((b) => b.ranch && b.ranch.id === d.id);
      if (bid.length) {
        bid.forEach(((bi) => BlockIdFromRanch.push(bi.id)));
      }
    });

    // Get ranchIds, BlockIds and entityIds from entityData
    const entityIdFromEntity = entityData.map((d) => (d && d.id));
    const ranchIdFromEntity = [];
    entityData.forEach((d) => {
      const rid = ranch.filter((r) => r.entity && r.entity.id === d.id);
      if (rid.length) {
        rid.forEach(((ri) => ranchIdFromEntity.push(ri.id)));
      }
    });
    const BlockIdFromEntity = [];
    entityData.forEach((d) => {
      const bid = block.filter((b) => b.ranch && b.ranch.entity && b.ranch.entity.id === d.id);
      if (bid.length) {
        bid.forEach(((bi) => BlockIdFromEntity.push(bi.id)));
      }
    });

    // Merge all entityIds and get unique list from that
    // Similarly it does the same process for blockIds and ranchIds also
    const allEnitity = entityIdFromBlock.concat(entityIdFromRanch
      .concat(entityIdFromEntity)).filter((v, i, a) => a.indexOf(v) === i);
    const allRanch = ranchIdFromBlock.concat(ranchIdFromRanch
      .concat(ranchIdFromEntity)).filter((v, i, a) => a.indexOf(v) === i);
    const allBlock = blockIdFromBlock.concat(BlockIdFromRanch
      .concat(BlockIdFromEntity)).filter((v, i, a) => a.indexOf(v) === i);

    // Filter the data objects based on merged ids
    const filteredEntity = entity.filter((d) => allEnitity.indexOf(d.id) >= 0);
    const filteredRanch = ranch.filter((d) => (allRanch.indexOf(d.id) >= 0)
          && (allEnitity.indexOf(d.entity && d.entity.id) >= 0));
    const filteredBlock = block.filter((d) => (allBlock.indexOf(d.id) >= 0)
          && (allRanch.indexOf(d.ranch && d.ranch.id) >= 0));

    // Get data objects for each entity and push to resultData
    filteredEntity.forEach((eEntity) => {
      const obj = {
        id: eEntity.id,
        name: eEntity.name,
        ranches: [],
        isLoading: true,
      };
      const ranchFromFilteredRanch = filteredRanch.filter((e) => e.entity
        && e.entity.id === eEntity.id);
      if (ranchFromFilteredRanch.length) {
        ranchFromFilteredRanch.forEach((eRanch) => {
          const rObj = {
            id: eRanch.id,
            name: eRanch.name,
            blocks: [],
            isLoading: true,
          };
          const blockFromFilteredBlock = filteredBlock.filter((e) => e.ranch
                && e.ranch.id === eRanch.id);
          if (blockFromFilteredBlock.length) {
            blockFromFilteredBlock.forEach((eBlock, eBlockIndex) => {
              const bObj = {
                id: eBlock.id,
                name: eBlock.name,
                crop: eBlock.crop,
              };
              rObj.isLoading = false;
              rObj.blocks.push(bObj);
              if (blockFromFilteredBlock.length === (eBlockIndex + 1)) {
                obj.isLoading = false;
                obj.ranches.push(rObj);
              }
            });
          } else {
            obj.isLoading = false;
            obj.ranches.push(rObj);
            if (block.length) rObj.isLoading = null;
          }
        });
      } else if (ranch.length) obj.isLoading = null;
      resultData.push(obj);
    });
    return resultData;
  }

  function definedStructure(obj, attrs) {
    let tmp = obj;
    for (let i = 0; i < attrs.length; i += 1) {
      if (tmp[attrs[i]] && !tmp[attrs[i]].length) { return false; }
      if (tmp[attrs[i]] && tmp[attrs[i]].length) {
        const innerObj = tmp[attrs[i]][0];
        tmp = innerObj;
      }
    }

    return true;
  }

  // Check for valid structure of options
  let isValidOption = false;
  if (data && data.length) {
    for (let i = 0; i < data.length; i += 1) {
      isValidOption = definedStructure(data[i], ['ranches', 'blocks']); // option must have these keys
      if (isValidOption) break;
    }
  }

  // To generate option only if invalid
  if (!isValidOption) {
    const finalData = processData(entity || [], block || [], ranch || []);

    // sort data in alphabetical order
    if (finalData && finalData.length) {
      finalData.map((d) => {
        const obj = d;
        obj.ranches.sort((a, b) => (a && a.name.toLowerCase()
          .localeCompare(b && b.name.toLowerCase())));
        obj.ranches.map((r) => {
          const robj = r;
          robj.blocks.sort((a, b) => (a && a.name.toLowerCase()
            .localeCompare(b && b.name.toLowerCase())));
          return robj;
        });
        return obj;
      });
      finalData.sort((a, b) => (a && a.name.toLowerCase()
        .localeCompare(b && b.name.toLowerCase())));
    }

    return finalData;
  }

  return data;
}

function useKeyGeneration(entityId, ranchIds, blockIds) {
  let value;
  let key;
  let type;
  const blockIdsFromState = useSelector(selectAllBlockIds);
  const ranchIdsFromState = useSelector(selectAllRanchIds);
  const userDetail = useSelector(selectLoginUserInfo).payload;

  let isValidRanch;
  if (ranchIds && ranchIds.length) {
    isValidRanch = ranchIdsFromState.indexOf(ranchIds[0]) >= 0;
  }
  let isValidBlock;
  if (blockIds && blockIds.length) {
    isValidBlock = blockIdsFromState.indexOf(blockIds[0]) >= 0;
  }

  if (isValidBlock && blockIds && blockIds.length) {
    value = `${blockIds[0]}`;
    key = `${blockIds[0]}`;
    type = 'block';
  } else if (isValidRanch && entityId && entityId.length && ranchIds && ranchIds.length) {
    value = `${ranchIds[0]}`;
    key = `${entityId[0]}_${ranchIds[0]}`;
    type = 'ranch';
  } else if (!isValidBlock && !isValidRanch && userDetail && !userDetail.admin) {
    key = 'All Ranches';
    type = 'All Ranches';
    value = null;
  }

  return { key, value, type };
}

function useRanchBlockSelection() {
  const block = useSelector(selectSelectedBlock).payload || [];
  const ranch = useSelector(selectSelectedRanch).payload || [];
  const userDetail = useSelector(selectLoginUserInfo).payload;
  const entity = useSelector((state) => (
    selectEntityIdForRanchBlock(state, Number(ranch && ranch[0]), Number(block && block[0]))));
  let selectedObjFromState = useKeyGeneration(entity, ranch, block);

  // To show first ranch as default ranch for admin user
  const ranches = useSelector(selectAllRanches);
  const entityForDefaultRanch = useSelector((state) => (
    selectEntityIdForRanchBlock(state, Number(ranches[0] && ranches[0].id), undefined)));
  if (!selectedObjFromState.type && userDetail && userDetail.admin
      && entityForDefaultRanch.length) {
    selectedObjFromState = {
      type: 'ranch',
      value: `${ranches[0] && ranches[0].id}`,
      key: `${entityForDefaultRanch[0] && entityForDefaultRanch[0]}_${ranches[0] && ranches[0].id}`,
    };
  }

  const blocksFromState = useSelector(
    (state) => selectBlocksForRanchBlockSelection(state, selectedObjFromState),
  );
  const blockArray = blocksFromState.map((b) => ({
    id: b.id,
    name: b.name,
    vfd: b.vfd,
    valve: b.valve,
    ranchId: b.ranch,
    valves: b.valves,
    vfds: b.vfds,
  }));
  const blockIds = blocksFromState.map((b) => b.id);

  return {
    block, ranch, entity, selectedObjFromState, blockArray, blockIds,
  };
}

export { useEntityRanchBlock, useKeyGeneration, useRanchBlockSelection };
