import L from 'leaflet';
import { calculateGroupStateForPresentationModes } from 'farmx-redux-core';

const createDivIcon = (markersLength, c) => new L.DivIcon({
  html: `<div><span>${markersLength}</span></div>`,
  className: `marker-cluster ${c}`,
  iconSize: new L.Point(40, 40),
});

export const prepareMarkerClusterIcon = (presentationMode) => (cluster) => {
  const childCount = cluster.getChildCount();

  let c = 'marker-cluster-';
  if (childCount < 10) {
    c += 'small';
  } else if (childCount < 100) {
    c += 'medium';
  } else {
    c += 'large';
  }

  const markers = cluster.getAllChildMarkers();
  const markersLength = markers.length;
  const {
    connectivityState,
    waterPressureState,
    provisionState,
    soilState,
    controlState,
  } = calculateGroupStateForPresentationModes(markers, true);

  if (presentationMode === 'initial') {
    c = 'marker-cluster-unknown';

    return createDivIcon(markersLength, c);
  }

  if (presentationMode === 'soilstatus') {
    switch (soilState) {
      case 1:
        c = 'marker-cluster-critical';
        break;
      case 2:
        c = 'marker-cluster-bad';
        break;
      case 3:
        c = 'marker-cluster-warning';
        break;
      case 4:
        c = 'marker-cluster-over';
        break;
      case 5:
        c = 'marker-cluster-good';
        break;
      default:
        c = 'marker-cluster-unknown';
    }

    return createDivIcon(markersLength, c);
  }

  if (presentationMode === 'connectivity') {
    switch (connectivityState) {
      case 0:
        c = 'marker-cluster-unknown';
        break;
      case -1:
        c = 'marker-cluster-offline';
        break;
      case 2:
        c = 'marker-cluster-critical';
        break;
      case 3:
        c = 'marker-cluster-warning';
        break;
      case 5:
        c = 'marker-cluster-good';
        break;
      default:
        c = 'marker-cluster-unknown';
    }

    return createDivIcon(markersLength, c);
  }

  if (presentationMode === 'waterpressure') {
    switch (waterPressureState) {
      case 0:
        c = 'marker-cluster-unknown';
        break;
      case 4:
        c = 'marker-cluster-over';
        break;
      default:
        c = 'marker-cluster-unknown';
    }

    return createDivIcon(markersLength, c);
  }

  if (presentationMode === 'control') {
    switch (controlState) {
      case 0:
        c = 'marker-cluster-unknown';
        break;
      case 4:
        c = 'marker-cluster-over';
        break;
      default:
        c = 'marker-cluster-unknown';
    }

    return createDivIcon(markersLength, c);
  }

  if (presentationMode === 'provision') {
    switch (provisionState) {
      case 2:
        c = 'marker-cluster-critical';
        break;
      case 5:
        c = 'marker-cluster-good';
        break;
      default:
        c = 'marker-cluster-unknown';
    }

    return createDivIcon(markersLength, c);
  }

  return createDivIcon(markersLength, c);
};
