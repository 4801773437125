import React, { Component } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import moment from 'moment';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_limit';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_multiselection';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_timeline';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_tooltip';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_multiselect';
import 'dhtmlx-scheduler/codebase/sources/skins/Timeline.css';
import PropTypes from 'prop-types';

// Class component created as per the link
// https://dhtmlx.com/blog/use-dhtmlx-scheduler-component-react-js-library-demo/
const { scheduler, dhtmlXTooltip } = window;
export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      spanID: null,
      timeoutId: null,
    };
  }

  componentDidMount() {
    const { intervalId, timeoutId, spanID } = this.state;

    this.createConfig();

    if (intervalId) {
      clearInterval(intervalId);
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (spanID) {
      this.deleteCurrentDateIndicator();
    }
    this.customEventTextStyles();
    this.updateCurrentDateIndicator();
  }

  componentDidUpdate(prevProps) {
    const { blocks, scheduleData, config } = this.props;
    const hasBlocksChanged = JSON.stringify(prevProps.blocks) !== JSON.stringify(blocks);

    // set the 1st day of the week
    if (config.x_length > 14) {
      scheduler.config.start_on_monday = true;
    } else {
      scheduler.config.start_on_monday = false;
    }

    scheduler.clearAll();
    if ((hasBlocksChanged || (scheduleData && scheduleData.length))) {
      this.redrawChart();
    }

    this.customEventTextStyles();
    if (hasBlocksChanged) {
      this.deleteCurrentDateIndicator();
      this.updateCurrentDateIndicator();
    }
  }

  componentWillUnmount() {
    const { intervalId, timeoutId } = this.state;

    if (intervalId) {
      clearInterval(intervalId);
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
      this.setState({ intervalId: null });
    }
    this.deleteCurrentDateIndicator();
  }

  currentDateIndicator() {
    const { scheduleData, initDate, blocks } = this.props;

    // current date indicator
    const spanID = scheduler.addMarkedTimespan({
      start_date: new Date(),
      end_date: new Date(),
      html: `<span id="currTime" class="currTime">${moment().format('YYYY-MM-DD HH:mm')}</span>`,
      css: 'column_selection',
      type: 'indicator',
      sections: {
        timeline: scheduleData[0] ? [scheduleData[0].section_id] : [blocks[0]],
      },
    });
    this.setState({ spanID });
    scheduler.setCurrentView(initDate);
  }

  deleteCurrentDateIndicator() {
    const { spanID } = this.state;

    if (spanID) {
      scheduler.deleteMarkedTimespan(spanID);
    }
  }

  updateCurrentDateIndicator() {
    this.currentDateIndicator();

    const d = new Date();
    d.setTime(d.getTime() + 60000);
    const dms = d.getMilliseconds();
    const dh = d.getHours();
    const dm = d.getMinutes();
    const intervalTime = new Date().setHours(dh, dm, 0, dms) - new Date();
    const temp = this;
    const timeoutId = setTimeout(() => {
      temp.deleteCurrentDateIndicator();
      temp.currentDateIndicator();
      scheduler.updateView();
      const intervalId = setInterval(() => {
        temp.deleteCurrentDateIndicator();
        temp.currentDateIndicator();
        scheduler.updateView();
      }, 60000);
      temp.setState({ intervalId });
    }, intervalTime);
    temp.setState({ timeoutId });
  }

  /*
  * Display text when event length is > 4 hours
  * for any screen above 14 inch (i.e. chart container width
  * > 1026 with sidebar ON).
  *
  * OR event length is > 7 hours for 14 inch screen(chart
  * container width = 1026)
  *
  * OR event length is > 9 hours for all tablet
  * screens (anything below 14 inch screen)
  *
  * These condition will make sure to show text with
  * atleast 3 digits in decimal number clearly
  *
  * If number of hours is increased,
  * more event bars will be empty and if reduced,
  * more event bars will display text
  *
  */

  customEventTextStyles() {
    const { initDate } = this.props;
    scheduler.templates.event_bar_text = (startTime, endTime, ev) => {
      let duration = null;
      const actualDuration = moment.duration(moment(endTime).diff(moment(startTime)));
      if (moment(startTime).isBefore(initDate)) {
        // special case when long event ending at
        // beginning of current date range
        duration = moment.duration(moment(endTime).diff(moment(initDate)));
      } else { duration = actualDuration; }
      const eventHours = duration.asHours();
      const divElem = document.getElementById('scheduler-container');
      let containerWidth;
      if (divElem) containerWidth = divElem.clientWidth;
      const displayText = (Number(eventHours) > 4 && containerWidth > 1026)
        || (Number(eventHours) > 7 && containerWidth === 1026)
        || Number(eventHours) > 9;
      return `<span class=${Number(actualDuration) >= 10 ? 'event_text' : ''} id=${ev.bid}>
        ${displayText ? ev.text : ''}</span>`;
    };
    scheduler.setCurrentView(initDate);
  }

  redrawChart() {
    const { config, initDate, scheduleData } = this.props;
    const timeline = scheduler.getView();
    timeline.x_date = config.x_date;
    timeline.x_unit = config.x_unit;
    timeline.x_length = config.x_length;
    timeline.x_start = config.x_start;
    timeline.x_size = config.x_size;
    timeline.second_scale = config.second_scale;
    timeline.y_unit = config.y_unit;
    scheduler.createTimelineView(timeline);
    scheduler.setCurrentView(initDate);
    scheduler.parse(scheduleData, 'json');
  }

  createConfig() {
    const {
      initDate, scheduleData, config, onClickChart,
    } = this.props;

    scheduler.skin = 'material';
    scheduler.config.header = [];

    // x-axis scale height
    scheduler.xy.scale_height = 20;

    // Initial Config
    scheduler.createTimelineView(config);

    // Event handling
    scheduler.attachEvent('onEmptyClick', (date, e) => {
      if (!e.target.innerHTML) {
        onClickChart({
          blockId: scheduler.getActionData(e).section,
          startDate: moment(date).format('YYYY-MM-DD HH:mm'),
        });
      }
    });

    // onclick event on created schedule
    scheduler.attachEvent('onClick', (id, e) => {
      const eventObj = scheduler.getEvent(id);
      onClickChart({
        blockId: scheduler.getActionData(e).section,
        scheduleId: parseInt(id, 10),
        eventIndex: eventObj && eventObj.event_index,
      });
      dhtmlXTooltip.hide();
      return true;
    });

    // disable drag feature
    scheduler.attachEvent('onBeforeDrag', () => false);

    // Timeline custom config
    scheduler.config.xml_date = '%Y-%m-%d %h:%i';
    // drag event
    scheduler.config.drag_event_body = false;

    // resize the created event
    scheduler.config.drag_resize = false;

    // current date indicator line
    scheduler.config.mark_now = true;

    scheduler.config.full_day = true;
    scheduler.config.drag_move = false;
    scheduler.config.drag_resize = false;
    scheduler.locale.labels.timeline_scale_header = 'Blocks';

    // configure the timeline after it's created
    scheduler.config.dblclick_create = false;
    scheduler.locale.labels.icon_myicon = 'Text';
    scheduler.config.multisection = true;

    // Tooltip
    dhtmlXTooltip.config.className = 'dhtmlXTooltip tooltip';
    dhtmlXTooltip.config.timeout_to_display = 0;
    dhtmlXTooltip.config.timeout_to_hide = 10;
    dhtmlXTooltip.config.delta_x = 15;
    dhtmlXTooltip.config.delta_y = -20;
    const format = scheduler.date.date_to_str('%Y-%m-%d %H:%i');
    scheduler.templates.tooltip_text = (start, end, event) => `${event.name}<br/>Event:
     ${event.text}<br/>From: ${format(start)}<br/>To: ${format(end)}<br/>Total runtime (in view):`;

    // To set position for tooltip based on event id
    scheduler.onMouseMoveEventId = 0;
    scheduler.attachEvent('onMouseMove', (id, e) => {
      if (scheduler.onMouseMoveEventId !== id) {
        scheduler.onMouseMoveEventId = id;
        scheduler.clientX = e.clientX;
        scheduler.clientY = e.clientY;
      }
    });

    // Inbuilt function to set position
    dhtmlXTooltip.position = (e) => ({
      x: scheduler.clientX || e.clientX,
      y: scheduler.clientY || e.clientY,
    });

    // Initialization schedule container
    scheduler.init(this.schedulerContainer, initDate, 'timeline');

    // Loading Data
    scheduler.parse(scheduleData, 'json');
  }

  render() {
    return (
      <div
        ref={(input) => {
          this.schedulerContainer = input;
        }}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
}

Timeline.propTypes = {
  config: PropTypes.shape({
    x_date: PropTypes.string,
    x_length: PropTypes.number,
    x_unit: PropTypes.string,
    y_unit: PropTypes.arrayOf(PropTypes.any),
    x_start: PropTypes.number,
    x_size: PropTypes.number,
    second_scale: PropTypes.shape({}),
  }),
  scheduleData: PropTypes.arrayOf(PropTypes.any),
  initDate: PropTypes.shape({}),
  onClickChart: PropTypes.func,
  blocks: PropTypes.arrayOf(PropTypes.any),
};

Timeline.defaultProps = {
  config: {},
  scheduleData: [],
  initDate: null,
  onClickChart: () => null,
  blocks: [],
};
