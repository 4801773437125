import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import calculatedHeightForMobile from './helper';

function ItemSelect(props) {
  const {
    onChange,
    onSelect,
    onBlur,
    mode,
    renderItem,
    items,
    tagRender,
    placeholder,
    defaultOption,
    filterOption,
    value,
    dropdownRender,
    dropdownStyle,
    menuIsOpen,
    dropdownStyleValues,
    loading,
    showSearch,
    listHeightOffset,
    onDropdownVisibleChange,
    bordered,
    size,
  } = props;
  const { t } = useTranslation();
  const mobileScreen = window.innerWidth < 599;
  const children = [];
  if (items && items.length > 0) {
    items.forEach((d) => {
      children.push(renderItem(d));
    });
  }
  const getDropDownClass = (dropDownStyle) => {
    const sideBar = document.querySelector('.ant-layout-sider-trigger');
    let styleClassName;
    if (window.innerWidth > 950) {
      if (dropDownStyle && dropDownStyle.leftValue) {
        const createdStyles = StyleSheet.create({
          leftposition: {
            left: `${dropDownStyle.leftValue.current} !important`,
            width: `${dropDownStyle.minWidthValue.current} !important`,
          },
        });
        styleClassName = `${css(createdStyles.leftposition)}`;
      }
    } if (window.innerWidth > 599 && window.innerWidth <= 950) {
      // Style for narrow screens
      if (sideBar && sideBar.offsetWidth > 50) {
        const createdStyles = StyleSheet.create({
          leftposition: {
            left: `${sideBar.offsetWidth + 20}px !important`,
          },
        });
        styleClassName = `${css(createdStyles.leftposition)}`;
      }
    } if (window.innerWidth < 599) { // Style for only mobile screens
      const createdStyles = StyleSheet.create({
        leftposition: {
          left: '0px !important',
          width: '100% !important',
          top: '0px !important',
        },
      });
      styleClassName = `${css(createdStyles.leftposition)}`;
    }
    return styleClassName;
  };

  const divHeight = calculatedHeightForMobile();

  return (
    <div>
      {value
        ? (
          <Select
            defaultActiveFirstOption={window.innerWidth > 599}
            showSearch={showSearch}
            filterOption={filterOption}
            mode={mode}
            style={{ width: '100%' }}
            placeholder={placeholder || t('Please select')}
            defaultValue={defaultOption || []}
            value={value || undefined}
            onSelect={onSelect}
            onChange={onChange}
            onBlur={onBlur}
            allowClear
            tagRender={tagRender}
            dropdownRender={dropdownRender}
            // The below change was done to avoid closing of sensor selecot dropdown
            // when any items in filter dropdown was selected
            // it has to be tested for impact on other functionalities
            onMouseDown={(e) => { e.stopPropagation(); }}
            dropdownStyle={dropdownStyle}
            optionLabelProp="label"
            dropdownClassName={getDropDownClass(dropdownStyleValues)}
            open={menuIsOpen != null ? menuIsOpen : undefined}
            listHeight={mobileScreen ? divHeight - (listHeightOffset || 70) : 330}
            loading={loading}
            onDropdownVisibleChange={onDropdownVisibleChange}
            getPopupContainer={mobileScreen ? null : (triggerNode) => triggerNode.parentNode}
            bordered={bordered}
            size={size}
          >
            {children}
          </Select>
        ) : (
          <Select
            defaultActiveFirstOption={window.innerWidth > 599}
            showSearch={showSearch}
            filterOption={filterOption}
            mode={mode}
            style={{ width: '100%' }}
            placeholder={t(placeholder || 'Please select')}
            defaultValue={defaultOption || []}
            onSelect={onSelect}
            onChange={onChange}
            onBlur={onBlur}
            allowClear
            tagRender={tagRender}
            dropdownRender={dropdownRender}
            // The below change was done to avoid closing of sensor selecot dropdown
            // when any items in filter dropdown was selected
            // it has to be tested for impact on other functionalities
            onMouseDown={(e) => { e.stopPropagation(); }}
            dropdownStyle={dropdownStyle}
            optionLabelProp="label"
            dropdownClassName={getDropDownClass(dropdownStyleValues)}
            open={menuIsOpen != null ? menuIsOpen : undefined}
            listHeight={mobileScreen ? divHeight - (listHeightOffset || 70) : 330}
            loading={loading}
            onDropdownVisibleChange={onDropdownVisibleChange}
            getPopupContainer={mobileScreen ? null : (triggerNode) => triggerNode.parentNode}
            bordered={bordered}
            size={size}
          >
            {children}
          </Select>
        )}
    </div>
  );
}

ItemSelect.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  mode: PropTypes.string,
  renderItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.any),
  tagRender: PropTypes.func,
  placeholder: PropTypes.string,
  defaultOption: PropTypes.arrayOf(PropTypes.any),
  filterOption: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.any),
  dropdownRender: PropTypes.func,
  dropdownStyle: PropTypes.shape({}),
  menuIsOpen: PropTypes.bool,
  dropdownStyleValues: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  listHeightOffset: PropTypes.number,
  onDropdownVisibleChange: PropTypes.func,
  bordered: PropTypes.bool,
  size: PropTypes.string,
};

ItemSelect.defaultProps = {
  onChange: null,
  onSelect: null,
  onBlur: null,
  mode: null,
  renderItem: null,
  items: [],
  tagRender: null,
  placeholder: null,
  defaultOption: null,
  filterOption: null,
  value: null,
  dropdownRender: null,
  dropdownStyle: {},
  menuIsOpen: null,
  dropdownStyleValues: null,
  loading: false,
  showSearch: true,
  listHeightOffset: null,
  onDropdownVisibleChange: null,
  bordered: true,
  size: 'middle',
};

export default ItemSelect;
