import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

const name = 'blocksgeojson';

const blocksGeoJSON = createSlice({
  name,
  initialState: {},
  reducers: {
    setBlocksGeoJSON(
      state,
      {
        payload: {
          blocksFeatures,
        },
      },
    ) {
      if (!Object.keys(blocksFeatures).length) {
        return state;
      }

      const ranchIdsObj = Object.values(blocksFeatures)
        .reduce((acc, blockFeature) => {
          acc[blockFeature.properties.ranchId] = blockFeature.properties.ranchId;
          return acc;
        }, {});

      const blocksFeaturesRemaining = Object.values(state)
        .filter((blockFeature) => !ranchIdsObj[blockFeature.properties.ranchId])
        .reduce((acc, blockFeature) => {
          acc[blockFeature.properties.id] = cloneDeep(blockFeature);

          return acc;
        }, {});


      return {
        ...cloneDeep(blocksFeaturesRemaining),
        ...cloneDeep(blocksFeatures),
      };
    },
  },
});

export const { setBlocksGeoJSON } = blocksGeoJSON.actions;
export default blocksGeoJSON.reducer;
