import React from 'react';
import Highcharts from 'highcharts';
import HighchartsGantt from 'highcharts/highcharts-gantt.src';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

NoDataToDisplay(Highcharts);

const defaultOptions = {
  title: null,
  xAxis: {
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 1,
  },
  series: [],
  credits: {
    enabled: false,
  },
  lang: {
    noData: 'No data',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030',
    },
  },
  time: {
    useUTC: false,
  },
};

export default class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.highchartsComponent = React.createRef();
  }

  componentDidUpdate = () => {
    // NOTE: this is overkill and should be cleaned up
    // Force reflow to fix issue where graph does match parent size on load
    // need to call this.highchartsComponent.current.chart.reflow() on parent resize
    this.forceReflow();
  }

  forceReflow = () => {
    if (this.highchartsComponent && this.highchartsComponent.current) {
      this.highchartsComponent.current.chart.reflow();
    }
  }

  render() {
    const { options, constructorType, chartRef } = this.props;
    if (chartRef && this.highchartsComponent) {
      chartRef.current = this.highchartsComponent.current;
    }
    return (
      <HighchartsReact
        constructorType={constructorType || 'chart'}
        containerProps={{ style: { height: '100%' } }}
        highcharts={constructorType === 'ganttChart' ? HighchartsGantt : Highcharts}
        options={{
          ...defaultOptions,
          ...options,
        }}
        ref={this.highchartsComponent}
      />
    );
  }
}

ChartComponent.propTypes = {
  options: PropTypes.shape({}),
  constructorType: PropTypes.string,
  chartRef: PropTypes.shape({
    current: PropTypes.any,
  }),
};

ChartComponent.defaultProps = {
  options: null,
  constructorType: null,
  chartRef: null,
};
