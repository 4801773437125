import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadSensorDetail,
  loadSensorDetailById,
} from '../actions';
import {
  selectSensorByParams,
} from '../selectors';

export default function useSensor(sensor = {}) {
  const dispatch = useDispatch();
  const sensorFound = useSelector((state) => selectSensorByParams(state, sensor));
  // if sensor params change, load sensor from database
  // uses original params (sensor) not sensorFound
  const { identifier, type, id } = sensor || {};

  useEffect(() => {
    if (type && identifier) {
      dispatch(loadSensorDetail({ type, identifier }));
    } else if (type && id) {
      dispatch(loadSensorDetailById({ type, id }));
    }
  }, [identifier, type, id, dispatch]);

  return sensorFound;
}
