import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';

const ConfirmValveOverrideDialog = ({
  title,
  displayText,
  buttonText,
  onClick,
}) => {
  const { t } = useTranslation();
  const [modal, setModalState] = useState(true);

  function callOnClick(d) {
    if (d) setModalState(false);
    onClick(d.target.id);
  }

  return (
    <div>
      {modal
        ? (
          <Modal
            title={t(title)}
            centered
            visible={modal}
            onCancel={callOnClick}
            footer={[
              <Button key="cancel" onClick={callOnClick}>{t('Cancel')}</Button>,
              <Button
                id={buttonText[0]}
                key={buttonText[0]}
                value={buttonText[0]}
                onClick={callOnClick}
                type="primary"
                style={{ background: 'red', borderColor: 'red' }}
              >
                <span id={buttonText[0]}>{t(buttonText[0])}</span>
              </Button>,
              <Button
                id={buttonText[1]}
                key={buttonText[1]}
                value={buttonText[1]}
                onClick={callOnClick}
                type="primary"
              >
                <span id={buttonText[1]}>{t(buttonText[1])}</span>
              </Button>,
            ]}
            closable
            maskClosable={false}
            destroyOnClose
          >
            {displayText.map((d, i) => <p key={String(i)}>{t(d)}</p>)}
          </Modal>
        )
        : null}
    </div>
  );
};

ConfirmValveOverrideDialog.propTypes = {
  title: PropTypes.string,
  displayText: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

ConfirmValveOverrideDialog.defaultProps = {
  title: null,
  displayText: null,
  buttonText: null,
  onClick: () => null,
};

export default ConfirmValveOverrideDialog;
