import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Marker } from 'react-leaflet';

export const LabelMarker = ({ position, name, className }) => {
  const icon = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer
      .renderToString(<div className={`${className} label-marker-text`}>{name}</div>),
  });

  return (
    <Marker
      position={position}
      icon={icon}
    />
  );
};

LabelMarker.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
