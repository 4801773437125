import React, { Component } from 'react';
import 'dhtmlx-scheduler';
import moment from 'moment';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_minical';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_all_timed';
import PropTypes from 'prop-types';

// Class component created as per the link
// https://dhtmlx.com/blog/use-dhtmlx-scheduler-component-react-js-library-demo/
const { scheduler, dhtmlXTooltip } = window;
const SWIPE_SIZE = 40;
export default class EventCalendar extends Component {
  componentDidMount() {
    const {
      scheduleData, schedulerObject, onModechange, hourFormat, onClickChart,
    } = this.props;
    this.getHeader();
    const { schedulerMode, schedulerStartDate } = schedulerObject;

    scheduler.skin = 'material';
    scheduler.config.hour_date = hourFormat;
    scheduler.xy.min_event_height = 1;
    scheduler.xy.scale_width = 20;
    const height = document.querySelector('.scheduler-container-mobile');
    // 500 is min-height of chart container
    const screenHeight = window.screen.availHeight;
    if (height && height.clientHeight >= 500) {
      scheduler.chartDivHeight = height.clientHeight;
    } else if (!scheduler.chartDivHeight && (screenHeight !== window.innerHeight)) {
      scheduler.chartDivHeight = (screenHeight - Math.round(screenHeight * 0.33));
    } else {
      scheduler.chartDivHeight = (window.innerHeight - (Math.round(window.innerHeight * 0.25)));
    }

    // 83 is height of chart x-scale
    scheduler.config.hour_size_px = ((scheduler.chartDivHeight - 83) / 24);
    scheduler.config.all_timed = true;
    scheduler.config.icons_select = ['icon_details', 'icon_edit'];
    scheduler.attachEvent('onBeforeLightbox', () => false);
    scheduler.attachEvent('onBeforeTooltip', () => false);
    scheduler.templates.event_class = () => 'my_event';
    scheduler.xy.scale_height = 40;
    scheduler.xy.nav_height = 40;
    scheduler.config.start_on_monday = false;
    scheduler.config.touch_swipe_dates = true;
    const headerDate = scheduler.date.date_to_str('%F');
    const formatMonthScale = scheduler.date.date_to_str('%D');
    const formatDayScale = scheduler.date.date_to_str('%l, %F %j, %Y');

    scheduler.xy.menu_width = 0;
    // drag event
    scheduler.config.drag_event_body = false;
    // resize the created event
    scheduler.config.drag_resize = false;
    scheduler.config.drag_move = false;
    scheduler.config.drag_create = false;
    scheduler.config.drag_lightbox = false;
    scheduler.config.touch_tip = false;
    scheduler.config.touch_tooltip = false;
    scheduler.config.dblclick_create = false;
    scheduler.config.detail_on_dblclick = false;

    scheduler.onEmptyClick = scheduler.attachEvent('onEmptyClick', (date) => {
      scheduler.detachEvent(scheduler.onClick);
      const { mode } = scheduler.getState();
      if (mode === 'day' || mode === 'week') {
        onClickChart({
          blockId: [],
          startDate: moment(date).format('YYYY-MM-DD HH:mm'),
        });
        onModechange({ mode, date });
      } else {
        onModechange({ mode: 'day', date });
        scheduler.setCurrentView(date, 'day');
      }
    });

    scheduler.attachEvent('onClick', (id) => {
      const eventObj = scheduler.getEvent(id);
      onClickChart(eventObj);
      return true;
    });

    // remove text for week scheduleData
    scheduler.templates.event_header = (start, end, event) => {
      const { mode } = scheduler.getState();
      if (mode === 'day') {
        const diff = moment.duration(moment(end).diff(moment(start))).asHours();
        if (diff >= 1) {
          return event.text;
        }
      }

      return '';
    };

    scheduler.templates.day_scale_date = (date) => formatDayScale(date);

    // Week View
    scheduler.templates.week_date = (date) => {
      if (scheduler.getState().mode === 'week') {
        scheduler.detachEvent(scheduler.onClick);
      }
      return headerDate(date);
    };

    // Day View
    scheduler.templates.day_date = (date) => {
      if (scheduler.getState().mode === 'day') {
        scheduler.detachEvent(scheduler.onClick);
      }
      return headerDate(date);
    };

    // Month View
    scheduler.templates.month_date = (date) => {
      document.querySelector('.dhx_minical_icon').style.display = 'none';
      if (scheduler.getState().mode === 'month') {
        scheduler.detachEvent(scheduler.onClick);
        scheduler.onClick = scheduler.attachEvent('onClick', (id) => {
          if (scheduler.getState().mode === 'month') {
            const eventObj = scheduler.getEvent(id);
            const sDate = eventObj.start_date;
            onModechange({ mode: 'day', sDate });
            scheduler.setCurrentView(new Date(eventObj.start_date), 'day');
          }
        });
      }
      return headerDate(date);
    };


    scheduler.templates.month_scale_date = function monthScaleDate(date) {
      return formatMonthScale(date)[0];
    };

    scheduler.attachEvent('onBeforeViewChange', () => {
      scheduler.deleteMarkedTimespan();
      return true;
    });

    function addEvCount() {
      let startDate = scheduler.getState().min_date;
      const endMonthDate = scheduler.getState().max_date;

      while (startDate.getTime() < endMonthDate.getTime()) {
        const endDayDate = scheduler.date.add(startDate, 1, 'day');
        const evs = scheduler.getEvents(startDate, endDayDate);
        if (evs.length) {
          scheduler.addMarkedTimespan({
            start_date: startDate,
            end_date: endDayDate,
            html: `<div style="text-align:center;"><b>${evs.length}</b><svg height="30px" width="30px"  fill="#1890ff" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><path d="M82.66,62.34C82.66,80.378,68.037,95,50,95c-18.038,0-32.66-14.622-32.66-32.66C17.34,34.309,50,5,50,5  S82.66,34.309,82.66,62.34z"></path></svg></div>`,
          });
        }
        startDate = endDayDate;
      }

      scheduler.updateView();
    }

    scheduler.attachEvent('onViewChange', (newMode) => {
      if (newMode === 'month') {
        addEvCount();
      }
    });

    scheduler.filter_month = () => false;
    scheduler.templates.event_bar_date = () => '';
    const dateFormat = scheduler.date.date_to_str('%j');
    const formatweekScale = scheduler.date.date_to_str('%D');
    scheduler.templates.week_scale_date = (date) => {
      const today = scheduler.date.date_part(new Date());
      if (date.valueOf() === today.valueOf()) {
        return ` <div><span>${formatweekScale(date)[0]}<span>
        <br>
        <span
        style='border-radius: 50%;
        justify-content: center;
        background: #1890ff;
        border: 1px solid #1890ff;
        color: white;
        display:flex;
        align-items:center;
        height: 22px;
        width: 22px;
        margin-left: 24%;'
        >${dateFormat(date)}</span></div>`;
      }
      return ` <div><span>${formatweekScale(date)[0]}<span><br>
      <span>${dateFormat(date)}</span></div>`;
    };
    scheduler.init(this.schedulerContainer, new Date(moment(schedulerStartDate)), schedulerMode);
    scheduler.clearAll();
    scheduler.parse(scheduleData);
    scheduler.config.max_month_events = 1;

    // Below code for add swipe events to chart area and set values to state
    let xStart = null;
    let xEnd = null;
    let yStart = null;
    let yEnd = null;

    scheduler.touchStart = function handleTouchStart(evt) {
      xStart = evt.targetTouches[0].clientX;
      yStart = evt.targetTouches[0].clientY;
    };

    scheduler.touchMove = function handleTouchMove(evt) {
      xEnd = evt.targetTouches[0].clientX;
      yEnd = evt.targetTouches[0].clientY;
    };

    scheduler.touchEnd = function handleTouchEnd() {
      const { mode } = scheduler.getState();
      const xScale = xStart - xEnd;
      const yScale = yStart - yEnd;
      // SWIPE_SIZE is the optimized swipe length
      if (xStart && xEnd && xScale > SWIPE_SIZE && xScale > yScale) {
        // Left swipe
        const newDate = moment(scheduler.currDate).add(1, mode);
        onModechange({ date: newDate, mode });
      }
      if (xStart && xEnd && xScale < -SWIPE_SIZE && xScale < -yScale) {
        // Right swipe
        const newDate = moment(scheduler.currDate).subtract(1, mode);
        onModechange({ date: newDate, mode });
      }

      // Reset values
      xStart = null;
      xEnd = null;
      yStart = null;
      yEnd = null;
    };

    // Adding listeners
    this.addTouchEvent();
  }

  componentDidUpdate() {
    // Remove listeners
    this.removeTouchEvent();

    // 83 is height of chart x-scale
    if (scheduler.chartDivHeight) {
      scheduler.config.hour_size_px = ((scheduler.chartDivHeight - 83) / 24);
    }

    scheduler.detachEvent(scheduler.onClick);
    scheduler.clearAll();
    const allEvents = scheduler.getEvents();
    const allSectionIds = allEvents.map((item) => item.section_id)
      .filter((v, i, a) => a.indexOf(v) === i);
    const { scheduleData, schedulerObject } = this.props;
    const { schedulerMode, schedulerStartDate } = schedulerObject;
    const newSectionIds = scheduleData.map((item) => item.section_id)
      .filter((v, i, a) => a.indexOf(v) === i);

    if (newSectionIds.length > 0) {
      const idsToDelete = allSectionIds.filter((element) => !(newSectionIds.includes(element)));
      const eventsToDelete = allEvents.filter((d) => d.section_id === idsToDelete[0]);
      if (idsToDelete.length === 1) {
        for (let i = 0; i < eventsToDelete.length; i += 1) {
          scheduler.deleteEvent(eventsToDelete[i].id, true);
        }
      }
    }

    scheduler.init(this.schedulerContainer, new Date(moment(schedulerStartDate)), schedulerMode);
    this.getHeader();
    scheduler.parse(scheduleData);
    scheduler.render();
    scheduler.attachEvent('onBeforeViewChange', () => {
      scheduler.deleteMarkedTimespan();
      return true;
    });

    // Adding listeners
    this.addTouchEvent();
  }

  componentWillUnmount() {
    dhtmlXTooltip.hide();
    scheduler.detachEvent(scheduler.onEmptyClick);
    scheduler.detachEvent(scheduler.onClick);
    scheduler.chartDivHeight = 0;
  }

  setTimeFormat() {
    const { hourFormat } = this.props;
    scheduler.config.hour_date = hourFormat;
    scheduler.templates.hour_scale = scheduler.date.date_to_str(
      scheduler.config.hour_date,
    );
  }

  getHeader() {
    const { schedulerObject, onModechange } = this.props;
    const { schedulerMode } = schedulerObject;

    function customModeChange(mode) {
      onModechange({
        mode,
        date: scheduler.getState().date,
      });
    }
    scheduler.config.header = [
      {
        html: `<text class=${schedulerMode === 'day' ? 'selected' : ''}>day</text>`,
        click() {
          customModeChange('day');
        },
      },
      {
        html: `<text class=${schedulerMode === 'week' ? 'selected' : ''}>week</text>`,
        click() {
          customModeChange('week');
        },
      }, {
        html: `<text class=${schedulerMode === 'month' ? 'selected' : ''}>month</text>`,
        click() {
          customModeChange('month');
        },
      },
      {
        view: 'minicalendar',
        click() {
          if (scheduler.isCalendarVisible()) {
            scheduler.destroyCalendar();
          } else {
            scheduler.renderCalendar({
              position: this,
              date: scheduler.getState().date,
              navigation: true,
              handler(date) {
                scheduler.setCurrentView(date);
                scheduler.destroyCalendar();
              },
            });
          }
        },
      },
      'date',
    ];
  }

  addTouchEvent() {
    if (this.schedulerContainer) {
      const divElem = this.schedulerContainer.lastChild;
      divElem.addEventListener('touchstart', scheduler.touchStart, false);
      divElem.addEventListener('touchmove', scheduler.touchMove, false);
      divElem.addEventListener('touchend', scheduler.touchEnd, false);
    }
  }

  removeTouchEvent() {
    if (this.schedulerContainer) {
      const divElem = this.schedulerContainer.lastChild;
      divElem.removeEventListener('touchstart', scheduler.touchStart, false);
      divElem.removeEventListener('touchmove', scheduler.touchMove, false);
      divElem.removeEventListener('touchend', scheduler.touchEnd, false);
    }
  }

  render() {
    const { schedulerObject } = this.props;
    const { schedulerStartDate } = schedulerObject;
    scheduler.currDate = schedulerStartDate;
    this.setTimeFormat();
    return (
      <div
        ref={(input) => {
          this.schedulerContainer = input;
        }}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
}

EventCalendar.propTypes = {
  scheduleData: PropTypes.arrayOf(PropTypes.any),
  hourFormat: PropTypes.string,
  onClickChart: PropTypes.func,
  schedulerObject: PropTypes.shape({
    schedulerMode: PropTypes.string,
    schedulerStartDate: PropTypes.any,
  }),
  onModechange: PropTypes.func,
};

EventCalendar.defaultProps = {
  scheduleData: null,
  hourFormat: '',
  onClickChart: () => null,
  schedulerObject: {},
  onModechange: () => null,
};
