import { recommendationAdapter } from './slices';
import { selectBlockById } from '../farm/selectors';
import { selectLoadingState } from '../helpers';

const selectRecommendationState = (state) => state.recommendationData.recommendation;
const recommendationSelectors = recommendationAdapter.getSelectors(selectRecommendationState);

const selectRecommendationForBlock = (state, blockId) => {
  const recommendation = recommendationSelectors.selectById(state, blockId);
  if (!recommendation) return undefined;
  const block = selectBlockById(state, recommendation.block);
  return {
    ...recommendation,
    blockName: block && block.name,
  };
};

const selectRecommendationsForBlocks = (state, blockIds) => (
  blockIds.reduce((result, blockId) => {
    const recommendation = selectRecommendationForBlock(state, blockId);
    if (recommendation) {
      result.push(recommendation);
    }
    return result;
  }, [])
);

const selectRecommendations = recommendationSelectors.selectAll;

const selectLoadingRecommendations = (state) => (
  selectLoadingState(selectRecommendationState(state)));

export {
  selectRecommendationForBlock,
  selectRecommendationsForBlocks,
  selectRecommendations,
  selectLoadingRecommendations,
};
