import {
  loadBlockList, loadBlockDetail, cleanBlocksListRequestState, updateBlockItem,
} from './blocks';
import { loadRanchList, loadRanchDetail, cleanRanchesListRequestState } from './ranches';
import { loadEntityList, loadEntityDetail, cleanEntitiesListRequestState } from './entities';
import {
  loadPlansByRanchId,
  loadPlanById,
  patchPlan,
  createPlan,
  updatePlan,
} from './plans';
import { loadSensorStatus } from '../sensor/actions';
import { selectBlockById } from './selectors';
import { setBlocksGeoJSON } from './blocks-geojson';
import { setRanchesGeoJSON } from './ranches-geojson';
import { loadSoilState } from './soilstate';

const loadFarmData = (filter) => (dispatch) => {
  dispatch(loadBlockList(filter));
  dispatch(loadRanchList(filter));
  dispatch(loadEntityList(filter));
};

const loadBlockControlStatus = (blockId) => (dispatch, getState) => {
  const block = selectBlockById(getState(), blockId);
  if (block && block.valve) {
    dispatch(loadSensorStatus(block.valve));
  }
  if (block && block.vfd) {
    dispatch(loadSensorStatus(block.vfd));
  }
};

export {
  loadBlockList,
  loadBlockDetail,
  cleanBlocksListRequestState,
  loadRanchList,
  loadEntityList,
  loadEntityDetail,
  cleanEntitiesListRequestState,
  loadRanchDetail,
  cleanRanchesListRequestState,
  loadFarmData,
  loadPlansByRanchId,
  loadPlanById,
  patchPlan,
  createPlan,
  updatePlan,
  loadBlockControlStatus,
  setBlocksGeoJSON,
  setRanchesGeoJSON,
  loadSoilState,
  updateBlockItem,
};
