import React, { useState, useRef, useEffect } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'antd';
import styles from './CircularSlider.css';

function round(x, step) {
  return Math.ceil(x / step) * step;
}

const Slider = (props) => {
  const {
    strokeDashArray,
    sliderWidth,
    trackSize,
    hourMax,
    hourStep,
    minMax,
    minStep,
    value,
    defaultValue,
    knobSize,
    colors,
    onChange,
    cutoffValues,
    waterValue,
    waterUnit,
  } = props;
  const { t } = useTranslation();

  // const defaultMinutes = (defaultValue !== null) ? defaultValue : value || 0;
  const timeoutId = useRef(0);

  useEffect(() => () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
  });

  function parseHours(minutes) {
    return Math.floor(minutes / 60);
  }

  function parseMinutes(minutes) {
    return minutes % 60;
  }

  const [hrs, setHrs] = useState(parseHours(value));
  const [mins, setMins] = useState(parseMinutes(value));

  useEffect(() => {
    if (mins !== parseMinutes(value)) setMins(parseMinutes(value));
  }, [value, mins]);

  const totalMinutes = value; // 60 * hrs + mins;

  const maxTotalMinutes = 60 * hourMax;

  function getSliderValues() {
    const allValues = [];
    for (let i = minStep; i <= maxTotalMinutes; i += minStep) {
      allValues.push(i);
    }
    return allValues;
  }

  const timeArr = getSliderValues();
  const currIndex = timeArr.indexOf(round(totalMinutes, minStep));
  const sliderIndex = currIndex >= 0 ? currIndex : 0;

  const onSliderChange = (newValue) => {
    // handle values over slider max
    if (newValue === maxTotalMinutes && totalMinutes > maxTotalMinutes) {
      return;
    }
    setHrs(parseHours(newValue));
    setMins(parseMinutes(newValue));
    onChange(newValue);
  };

  function onHoursInputChange(newHours) {
    setHrs(newHours);
    onChange(Number(newHours * 60 + mins));
  }

  function onMinutesInputChange(newMinutes) {
    const extraHours = Math.floor(newMinutes / 60);
    const minutes = newMinutes % 60;
    setMins(minutes);
    setHrs(hrs + extraHours);
    onChange(Number(newMinutes + 60 * hrs));
  }

  function appendAttributesToElement() {
    const circles = document.getElementsByTagName('circle');
    const svgElem = document.getElementsByTagName('svg');
    const divElem = document.querySelectorAll('.innerDiv div');

    if (svgElem.length && svgElem.length >= 3) {
      svgElem[0].setAttribute('id', 'sliderCircle');
      svgElem[1].setAttribute('id', 'knob');
      svgElem[0].setAttribute('shape-rendering', 'geometricPrecision');
      svgElem[1].setAttribute('shape-rendering', 'geometricPrecision');
      svgElem[2].childNodes.forEach((d) => d.setAttribute('fill', 'none'));
    }

    if (divElem.length && divElem.length >= 1) {
      divElem[1].setAttribute('id', 'knobDiv');
    }

    return circles;
  }

  const filteredCutOffPoints = [];
  const filteredColor = [];
  let arrIndex;
  for (arrIndex = 0; arrIndex < cutoffValues.length; arrIndex += 1) {
    const cutoffValue = cutoffValues[arrIndex];
    if (cutoffValue && cutoffValue > 0 && cutoffValue <= hourMax) {
      filteredColor.push(colors[arrIndex]);
      filteredCutOffPoints.push(cutoffValues[arrIndex]);
    }
  }
  filteredColor.push(colors[arrIndex]);

  const mobileScreen = window.innerWidth < 600;
  const outerCircleWidth = sliderWidth;
  const cutOffValArray = filteredCutOffPoints;
  const x = sliderWidth / 2;
  const y = sliderWidth / 2;
  const r = x - 8;
  const maxValue = hourMax;
  const { sin } = Math;
  const { cos } = Math;
  let svg = null;

  // Change below values to adjust gap between circle and size of circle
  const radius = 8;
  const gap = 12;

  function getProportionateAngle(num) {
    return ((2 * Math.PI * num) / maxValue);
  }

  function getPointForAngle(ra, angle) {
    return [x + ra * sin(angle - Math.PI), y + ra * cos(angle - Math.PI)];
  }

  function plotCirclesOnArc(start, end, color) {
    const circle = document.getElementById('innerCircle');
    const angleUnit = Math.PI / 180;
    const angleBetweenCircles = gap * angleUnit;
    let currentAngle = start + angleBetweenCircles;
    const cutOffPointsArr = [...cutOffValArray, end];
    for (let i = 0; i < cutOffPointsArr.length; i += 1) {
      const cutOffAngle = getProportionateAngle(cutOffPointsArr[i]);
      while (currentAngle <= cutOffAngle) {
        const point = getPointForAngle(r, currentAngle);
        const circleClone = circle.cloneNode(true);
        circleClone.setAttribute('id', 'arc-cir');
        circleClone.setAttribute('r', radius);
        circleClone.setAttribute('cx', point[0]);
        circleClone.setAttribute('cy', point[1]);
        circleClone.setAttribute('fill', color || filteredColor[i]);
        circleClone.setAttribute('shape-rendering', 'geometricPrecision');
        circleClone.setAttribute('stroke-linejoin', 'round');
        svg.appendChild(circleClone);
        currentAngle += angleBetweenCircles;
      }
    }
  }

  timeoutId.current = setTimeout(() => {
    const circle = appendAttributesToElement();
    if (circle.length && circle.length >= 2) {
      circle[0].setAttribute('id', 'circle');
      circle[0].setAttribute('r', `${mobileScreen ? x - 20 : x - 30}`);
      circle[0].setAttribute('fill', '#e3e3e3');
      circle[1].setAttribute('fill', 'white');
      circle[1].setAttribute('fill-opacity', '0.8');
    }

    if (!document.getElementById('svgStrokeCircle')) {
      const newHtml = (`<svg id="svgStrokeCircle" class="outercircle"
        viewBox="0 0 ${outerCircleWidth} ${outerCircleWidth}"
        width="${outerCircleWidth}" height="${outerCircleWidth}">
        <circle id='innerCircle' cx="${x}" cy="${y}" r="${r}" fill="none"
        shape-rendering="geometricPrecision" />
        </svg>`);

      const newSvg = document.getElementById('strokeCircle');
      if (newSvg) {
        newSvg.innerHTML += newHtml;
      }
    }

    try {
      svg = document.getElementById('svgStrokeCircle');
      if (cutOffValArray && cutOffValArray.length) {
        plotCirclesOnArc(0, maxValue);
      } else {
        plotCirclesOnArc(0, maxValue, filteredColor[0] ? filteredColor[0] : 'orange');
      }
    } catch {
      // navigated away from page
    }
  });

  function renderInputs() {
    return (
      <div>
        <div className={`${styles['custom-label-div']} 
          ${!waterValue || !waterUnit ? styles['custom-label-div-default'] : ''}`}
        >
          <div className={styles['time-div-style']}>
            <InputNumber
              className={styles['custom-input']}
              min={0}
              step={hourStep}
              max={hourMax}
              defaultValue={3}
              value={hrs}
              onChange={onHoursInputChange}
              controls={false} // Whether to show +- controls: 4.17.0
              size="large"
              data-testid="input-hrs"
            />
            <span className={styles['span-text']}>{t('hrs')}</span>
          </div>
          <div className={styles['time-div-style']}>
            <InputNumber
              className={styles['custom-input']}
              min={0}
              step={minStep}
              max={minMax}
              defaultValue={0}
              value={mins}
              onChange={onMinutesInputChange}
              controls={false}
              size="large"
              data-testid="input-mins"
            />
            <span className={styles['span-text']}>{t('mins')}</span>
          </div>
        </div>
        {waterValue && waterUnit ? (
          <div className={`${styles['custom-label-div']} ${styles['water-value']}`}>
            <span className={styles['span-text-value']}>{waterValue}</span>
            <span className={styles['span-text-unit']}>{waterUnit}</span>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className={styles['outer-div']}>
      <div className={styles['inner-div']} id="innerdiv">
        <CircularSlider
          width={sliderWidth}
          renderLabelValue={renderInputs()}
          knobSize={knobSize || 40}
          labelFontSize="1rem"
          valueFontSize="2rem"
          knobPosition="top"
          direction={-1}
          progressLineCap="round"
          knobColor="#1890ff"
          progressColorFrom="#1890ff"
          progressColorTo="#1890ff"
          progressSize={trackSize}
          trackColor="transparent"
          trackSize={trackSize}
          data={timeArr}
          dataIndex={sliderIndex}
          onChange={onSliderChange}
        />
      </div>
      <div id="strokeCircle" className={styles['inner-div']} />
    </div>
  );
};

Slider.propTypes = {
  strokeDashArray: PropTypes.string,
  sliderWidth: PropTypes.number,
  trackSize: PropTypes.number,
  hourMax: PropTypes.number,
  hourStep: PropTypes.number,
  minMax: PropTypes.number,
  minStep: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.number,
  knobSize: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  cutoffValues: PropTypes.arrayOf(PropTypes.any),
  waterValue: PropTypes.number,
  waterUnit: PropTypes.string,
};

Slider.defaultProps = {
  strokeDashArray: '0,18.5',
  sliderWidth: 150,
  trackSize: 14,
  hourMax: 72,
  hourStep: 1,
  minMax: 60,
  minStep: 5,
  defaultValue: null,
  onChange: () => { },
  knobSize: 30,
  colors: ['green', 'orange', 'blue'],
  cutoffValues: [],
  waterValue: null,
  waterUnit: null,
};

export default Slider;
