import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import './CollapsableSidebar.less';

const { Sider } = Layout;

class CollapsableSidebar extends React.Component {
  collapse = () => {
    const { onCollapse } = this.props;
    onCollapse(true);
  };

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
  };

  toggleIfNotMobile = () => {
    const { isMobile } = this.props;
    if (!isMobile) {
      this.toggle();
    }
  }

  render() {
    const {
      collapsed, onCollapse, children, theme,
    } = this.props;
    return (
      <Sider
        className="nav"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        collapsedWidth={50}
        theme={theme}
      >
        {children}
        <div
          className="sider-filler"
          role="button"
          tabIndex={0}
          onKeyDown={this.toggleIfNotMobile}
          onClick={this.toggleIfNotMobile}
        />
      </Sider>
    );
  }
}

CollapsableSidebar.propTypes = {
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  children: PropTypes.element,
  theme: PropTypes.oneOf(['light', 'dark']),
  isMobile: PropTypes.bool,
};

CollapsableSidebar.defaultProps = {
  onCollapse: () => {},
  collapsed: false,
  children: null,
  theme: 'dark',
  isMobile: false,
};

export default CollapsableSidebar;
