// eslint-disable-next-line import/no-extraneous-dependencies
import isNumber from 'lodash/isNumber';

// This color map is used for both map and status tag
// in mobile-ui irrigation recommendation
export const stateTagColorCodes = {
  critical: '#f5222d',
  bad: '#ff4c00',
  poor: '#faad14',
  good: 'rgb(61, 200, 115)',
  unknown: '#989898',
};

export const stateTagTextColorCodes = {
  critical: 'white',
  bad: 'white',
  poor: 'black',
  good: 'black',
};

// This will be refactored using legend styles
export function getAnomalyIconColor(type) {
  if (type === 'disease') { return '#000000'; }
  if (type === 'missing') { return '#3dc873'; }
  if (type === 'irrigation') { return '#429aff'; }
  return '#999999';
}

export function getAnomalyIcon(type) {
  if (type === 'disease') { return 'icon-air'; }
  if (type === 'missing') { return 'icon-tree'; }
  if (type === 'irrigation') { return 'icon-waterdrop'; }
  return 'icon-sound';
}

export const getColorCodeForIrrigationState = (irrigationState) => {
  if (irrigationState === 'over_irrigated') {
    return stateTagColorCodes.bad;
  }
  if (irrigationState === 'well_irrigated') {
    return stateTagColorCodes.good;
  }
  if (irrigationState === 'drying') {
    return stateTagColorCodes.poor;
  }
  if (irrigationState === 'dry') {
    return stateTagColorCodes.bad;
  }
  if (irrigationState === 'very_dry') {
    return stateTagColorCodes.critical;
  }
  return stateTagColorCodes.unknown;
};

// soil state color codes
export const soilStateColorCodes = {
  critical: '#f5222d',
  bad: '#ff4c00',
  poor: '#faad14',
  good: 'rgb(61, 200, 115)',
  unknown: '#989898',
  over: '#1890FF',
};

export const getColorCodeForSoilState = (soilState) => {
  if (soilState === 'over_irrigated') {
    return soilStateColorCodes.over;
  }
  if (soilState === 'well_irrigated') {
    return soilStateColorCodes.good;
  }
  if (soilState === 'drying') {
    return soilStateColorCodes.poor;
  }
  if (soilState === 'dry') {
    return soilStateColorCodes.bad;
  }
  if (soilState === 'very_dry') {
    return soilStateColorCodes.critical;
  }
  return soilStateColorCodes.unknown;
};

export const stateColorCode = [
  { // Blank 0 gray
    iconColor: '#ffffff',
    markerColor: '#cccccc',
    fillColor: '#cccccc',
    textColor: '#000000',
  },
  { // Offline 1 gray
    iconColor: '#ffffff',
    markerColor: '#cccccc',
    fillColor: '#cccccc',
    textColor: '#000000',
  },
  { // Bad 2 red
    iconColor: '#ff98a4',
    markerColor: '#e23549',
    fillColor: '#e23549',
    textColor: '#35e2ce',
  },
  { // Warning 3 yellow
    iconColor: '#fff4cc',
    markerColor: '#FFCC00',
    fillColor: '#FFCC00',
    textColor: '#0033ff',
  },
  { // Over - 4 blue
    iconColor: '#a0ccff',
    markerColor: '#429aff',
    fillColor: '#429aff',
    textColor: '#fa742',
  },
  { // Good - 5 green
    iconColor: '#4DFA90',
    markerColor: '#3dc873',
    fillColor: '#3dc873',
    textColor: '#c83d92',
  },
];

export const icons = {
  dendrometer: 'icon-leaf',
  farmx_dendrometer: 'icon-leaf',
  ir_plant: 'icon-camera',
  farmx_infrared: 'icon-camera',
  weather_station: 'icon-ios-rainy',
  water_pressure: 'icon-gauge',
  water_flow: 'icon-water-meter',
  water_flow_analog: 'icon-water-meter',
  gateway: 'icon-radio-tower',
  boxer_station: 'icon-radio-tower',
  aquacheck_soil: 'icon-eyedropper',
  pixl_soil: 'icon-eyedropper',
  valve: 'icon-pipe-valve',
  vfd: 'icon-water-pump',
  cavalier: 'icon-radio-waves',
};

export const sensorTitles = {
  dendrometer: {
    singular: 'Dendrometer',
    plural: 'Dendrometers',
  },
  farmx_dendrometer: {
    singular: 'Dendrometer',
    plural: 'Dendrometers',
  },
  ir_plant: {
    singular: 'IR Sensor',
    plural: 'IR Sensors',
  },
  weather_station: {
    singular: 'Weather Station',
    plural: 'Weather Stations',
  },
  water_pressure: {
    singular: 'Pressure Sensor',
    plural: 'Pressure Sensors',
  },
  water_flow: {
    singular: 'Flow Meter',
    plural: 'Flow Meters',
  },
  water_flow_analog: {
    singular: 'Flow Meter',
    plural: 'Flow Meters',
  },
  gateway: {
    singular: 'Gateway',
    plural: 'Gateways',
  },
  boxer_station: {
    singular: 'Boxer Station',
    plural: 'Boxer Stations',
  },
  aquacheck_soil: {
    singular: 'Soil Sensor',
    plural: 'Soil Sensors',
  },
  pixl_soil: {
    singular: 'Pixl Soil Sensor',
    plural: 'Pixl Soil Sensors',
  },
  cavalier: {
    singular: 'Cavalier',
    plural: 'Cavaliers',
  },
  valve: {
    singular: 'Valve',
    plural: 'Valves',
  },
  vfd: {
    singular: 'VFD',
    plural: 'VFDs',
  },
};

/**
  aquacheck_soil,
  cavalier,
  farmx_dendrometer,
  farmx_frost,
  farmx_infrared,
  gateway,
  pixl_soil,
  valve,
  vfd,
  water_flow,
  water_flow_analog,
  water_pressure,
  weather_station,
*/
export const mapPresentationModes = {
  connectivity: {
    title: 'Connectivity',
    excludedSensors: ['cavalier'],
  },
  waterpressure: {
    title: 'Water pressure',
    includedSensors: ['water_pressure'],
    // TODO redundant psiLevel defined
    psiLevel: 5,
  },
  provision: {
    title: 'Provision',
    excludedSensors: [],
  },
  soilstatus: {
    title: 'Soil Status',
    excludedSensors: [],
  },
  control: {
    title: 'Control',
    includedSensors: [
      'valve',
      'vfd',
      'water_flow',
      'water_flow_analog',
      'water_pressure',
    ],
  },
  irrigationRecommendation: {
    // Irrigation Recommendation has no sensor displayed
    // status is displayed via block colors
    title: 'Irrigation Recommendation',
    includedSensors: [],
  },
  anomaly: {
    title: 'Anomalies',
    excludedSensors: [],
  },
};

export function sensorTypeAllowedForMode(
  presentationMode,
  sensorProperties,
  isSensorByTypeOnly,
) {
  const { type: sensorType, block } = sensorProperties;

  if (presentationMode === 'soilstatus' && isSensorByTypeOnly) {
    return ['aquacheck_soil', 'pixl_soil'].includes(sensorType) && block;
  }

  if (presentationMode === 'waterpressure' && isSensorByTypeOnly) {
    return (sensorType === 'water_pressure');
  }

  if (mapPresentationModes[presentationMode].excludedSensors
    && !mapPresentationModes[presentationMode].includedSensors) {
    return !mapPresentationModes[presentationMode].excludedSensors.includes(sensorType);
  }

  if (mapPresentationModes[presentationMode].includedSensors
    && !mapPresentationModes[presentationMode].excludedSensors) {
    return mapPresentationModes[presentationMode].includedSensors.includes(sensorType);
  }

  if (!mapPresentationModes[presentationMode]) {
    throw new Error(`${presentationMode} is not defined in mapPresentationModes`);
  }

  throw new Error('mapPresentationModes can not have both includedSensors and excludedSensors');
}

export function prepareNumberForDisplay(n) {
  if (!isNumber(n)) {
    return n;
  }

  if (Math.abs(n) < 1) {
    return n.toFixed(3);
  }

  return n.toFixed(1);
}

export function getMarkerTooltipByPresentationMode(feature, presentationMode) {
  if (presentationMode === 'waterpressure') {
    return prepareNumberForDisplay(feature.properties.psi);
  }
  return feature.properties.identifier.slice(-5);
}
