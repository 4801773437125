import React from 'react';
import 'antd/dist/antd.css';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './SensorSelect.less';

const Searchbox = ({
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Input
        className={styles['search-box']}
        placeholder={t('Type to search')}
        allowClear
        onChange={onChange}
        onKeyDown={(e) => { e.stopPropagation(); }}
        ref={(input) => input && input.focus()}
      />
    </div>
  );
};

Searchbox.propTypes = {
  onChange: PropTypes.func,
};

Searchbox.defaultProps = {
  onChange: null,
};

export default Searchbox;
