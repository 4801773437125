import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Timeline from './Timeline';

export default function Schedule(props) {
  const {
    dates, blocks, onClickChart, scheduleData,
  } = props;

  // x-axis config
  function getXaxisConfig(daterange) {
    let dateDiff = 0;
    let dte = moment()
      .format('YYYY-MM-DD')
      .split('-')
      .map((d) => Number(d));
    if (daterange && daterange.length) {
      dateDiff = daterange[1].diff(daterange[0], 'day');
      dte = moment(daterange[0])
        .format('YYYY-MM-DD')
        .split('-')
        .map((d) => Number(d));
    }

    const obj = {
      x_size: dateDiff ? dateDiff + 1 : 7,
      x_start: 0,
      x_length: dateDiff ? dateDiff + 1 : 7,
      x_unit: 'day',
      x_date: '%d %M %Y',
      second_scale: {
        x_unit: 'week',
        x_date: 'Week %W',
      },
    };

    let initDate = new Date(dte[0], dte[1] - 1, dte[2]);
    if (dateDiff > 7 && dateDiff <= 14) {
      obj.x_size = dateDiff + 1;
      obj.x_length = dateDiff + 1;
      obj.x_date = '%d %M';
      obj.second_scale = {
        x_unit: 'week',
        x_date: 'Week %W',
      };
    } else if (dateDiff > 14) {
      obj.x_size = dateDiff + 1;
      obj.x_length = dateDiff + 1;
      obj.x_date = '%d';
    } else if (!dateDiff) {
      initDate = new Date(dte[0], dte[1] - 1, dte[2] - 1);
      const hourDiff = daterange[1].diff(daterange[0], 'hour');
      obj.x_size = hourDiff + 1;
      obj.x_start = hourDiff + 1;
      obj.x_length = hourDiff + 1;
      obj.x_unit = 'hour';
      obj.x_date = '%H:%i';
      obj.second_scale = {
        x_unit: 'day',
        x_date: '%d %M %Y',
      };
    }

    return { configObj: obj, initDate };
  }

  const callFunc = getXaxisConfig(dates);

  // Actual config to pass as props
  const config = {
    name: 'timeline',
    ...callFunc.configObj,
    y_unit: scheduleData.sections,
    fit_events: true,
    y_property: 'section_id',
    render: 'bar',
    dy: 100,
    dx: 130,

    event_dy: 'full',
    section_autoheight: true,
    sort(a, b) {
      if (a.irrId > b.irrId) {
        return -1;
      } if (a.irrId < b.irrId) {
        return 1;
      }
      // https://docs.dhtmlx.com/scheduler/timeline_view.html -> Sorting events
      return +a.start_date > +b.start_date ? 1 : -1;
    },
  };

  function getClass(daterange) {
    const dateDiff = daterange[1].diff(daterange[0], 'day');
    let styleStr = '';
    if (dateDiff > 7 && dateDiff <= 14) {
      styleStr = 'twoweeks-mode';
    } else if (dateDiff > 14) {
      styleStr = 'month-mode';
    } else if (!dateDiff) {
      styleStr = 'day-mode';
    } else if (dateDiff && dateDiff <= 7) {
      styleStr = 'week-mode';
    }
    return styleStr;
  }

  const customClass = getClass(dates);

  return (
    <div className={`scheduler-container ${customClass}`} id="scheduler-container">
      <Timeline
        config={config}
        scheduleData={scheduleData.data}
        initDate={callFunc.initDate}
        onClickChart={onClickChart}
        blocks={blocks}
      />
    </div>
  );
}

Schedule.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.any),
  dates: PropTypes.arrayOf(PropTypes.any),
  onClickChart: PropTypes.func,
  scheduleData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    sections: PropTypes.arrayOf(PropTypes.any),
  }),
};

Schedule.defaultProps = {
  blocks: null,
  dates: null,
  onClickChart: () => null,
  scheduleData: { data: [], sections: [] },
};
