const selectSelectedBlock = (state) => state.clientData.selected.block;
const selectSelectedRanch = (state) => state.clientData.selected.ranch;
const selectSelectedBlocks = (state) => state.clientData.selected.blocks;
const selectSelectedRanches = (state) => state.clientData.selected.ranches;
const selectEntitySidebarCollapseState = (state) => state.clientData.selected.entitySidebarState;
const selectUrlLoad = (state) => state.clientData.selected.urlLoad;
const selectMobileScheduler = (state) => state.clientData.selected.mobileScheduler;
const selectRanchBlock = (state) => state.clientData.selected.ranchBlock;

const selectMapShowSoilType = (state) => state
  .clientData.settings.mapShowSoilType;

const selectMapShowTemperature = (state) => state
  .clientData.settings.mapShowTemperature;

const selectMapShowWind = (state) => state
  .clientData.settings.mapShowWind;

const selectMapShowLabels = (state) => state
  .clientData.settings.mapShowLabels;

const selectShowOnlyPresentionModeSensors = (state) => state
  .clientData.settings.showOnlyPresentionModeSensors;

const selectMapPresentationModes = (state) => state
  .clientData.settings.mapPresentationModes;

const selectLoginUserInfo = (state) => state.clientData.selected.userInfo;

export {
  selectMapShowSoilType,
  selectMapShowTemperature,
  selectMapShowWind,
  selectMapShowLabels,
  selectShowOnlyPresentionModeSensors,
  selectMapPresentationModes,
  selectSelectedBlock,
  selectSelectedBlocks,
  selectSelectedRanch,
  selectSelectedRanches,
  selectEntitySidebarCollapseState,
  selectUrlLoad,
  selectMobileScheduler,
  selectRanchBlock,
  selectLoginUserInfo,
};
