import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { userApi } from 'farmx-api';

const name = 'userSettings';

export const loadUserSettings = createAsyncThunk(
  `${name}/loadUserSettings`,
  async (arg, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().settingsData.userSettings;
    if (loading !== true || requestId !== currentRequestId) {
      return null;
    }
    const response = await userApi.getUserSettings();
    return response.data;
  },
);

export const patchUserSettings = createAsyncThunk(
  `${name}/patchUserSettings`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await userApi.patchUserSettings(data);
      return response.data;
    } catch (err) {
      const error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const userSettingsSlice = createSlice({
  name,
  initialState: {
    loading: false,
    error: undefined,
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserSettings.pending, (state, action) => {
      state.loading = true;
      state.error = undefined;
      state.currentRequestId = action.meta.requestId;
    });

    builder.addCase(loadUserSettings.fulfilled, (state, action) => {
      const lastUpdated = Date.now();
      state.lastUpdated = lastUpdated;
      state.loading = false;
      state.currentRequestId = undefined;
      state.error = undefined;
      state.data = action.payload;
    });

    builder.addCase(loadUserSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.currentRequestId = undefined;
    });

    builder.addCase(patchUserSettings.pending, (state, action) => {
      state.data = {
        ...state.data,
        ...action.meta.arg,
      };
      state.loading = true;
    });

    builder.addCase(patchUserSettings.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(patchUserSettings.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default userSettingsSlice.reducer;
