import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Collapse, Table, Tooltip, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { selectors } from 'farmx-redux-core';
import { FaCalendarAlt } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './CommonStyles.less';
import OverrideStatus, { VFD_TYPE } from './OverrideStatus';
import SensorAlarmButton from './SensorAlarmButton';
import PumpStateTag from './PumpStateTag';
import PumpControlSensorList from './PumpControlSensorList';
import { getPumpScheduleMessage } from './scheduleMessage';

const { Panel } = Collapse;
const { Text } = Typography;

const {
  selectPumpSensorStatus,
} = selectors;

export default function PumpControlList({
  pumps,
  onClickCalendar,
  pumpLoading,
}) {
  const pumpData = useSelector((state) => selectPumpSensorStatus(state, pumps));
  const spinner = pumpData.reduce((a, p) => !p || a || p.pumpLoading, false);
  const { t } = useTranslation();

  const getMessageClassName = (state, online) => {
    const styleStr = `${styles['red-message']} `;
    const styleStrNot = `${styles['normal-message']} `;
    if (state === 'unknown' || (!online && state === 'off')) {
      return styleStr;
    }
    if (!state) return styleStr;
    return styleStrNot;
  };

  const getSchedule = (pump, text) => (
    <div
      className={styles['icon-container']}
      onClick={() => onClickCalendar({ identifier: pump.pumpIdentifier })}
      role="presentation"
    >
      <div className={styles.icon}>
        <FaCalendarAlt />
      </div>
      <div className={getMessageClassName(pump.pumpCurrentState, pump.pumpOnline)}>
        <span className={styles['schedule-content']}>
          {text}
        </span>
      </div>
    </div>
  );

  const getIndClass = ({ pumpOnline: online, pumpCurrentState: status, pumpLoading: loading }) => {
    const styleStr = `${styles.indicator} `;
    if (!loading) {
      if (!online) return styleStr + styles.red;
      if (online
        && status === 'off') return styleStr + styles['light-grey'];
      if (online
        && status === 'on') return styleStr + styles.blue;
    }
    return styleStr + styles['light-grey'];
  };

  const getCurrentState = (pump) => (
    <PumpStateTag
      state={pump.pumpCurrentState}
      online={pump.pumpOnline}
    />
  );

  const getRowClassName = (record) => {
    if (record.pumpCurrentState) {
      if (record.pumpCurrentState === 'unknown'
        || (!record.pumpOnline && (!record.pumpCurrentState
          || record.pumpCurrentState === 'off'))) {
        return styles['red-border-text'];
      }
    }
    const className = getIndClass(record);
    return className;
  };

  function widthCalc(columns) {
    const maxwid = 100;
    const result = columns().map((element) => {
      const values = element;
      if (element.title === 'Alarm') {
        values.width = 100;
      } if (element.title === 'Override') {
        values.width = 100;
      } if (element.title !== 'Override' && element.title !== 'Alarm') {
        values.width = `${(maxwid - 10) / (columns().length - 2)}%`;
      }
      return values;
    });
    return result;
  }

  const columns = () => [
    {
      title: t('Pump'),
      className: styles['schedule-item-header'],
      render: (pump) => {
        if (pump.pumpName) {
          return (
            <Tooltip title={pump.pumpName}>
              <span>{pump.pumpName}</span>
            </Tooltip>
          );
        }
        return <span style={{ color: 'lightgray' }}>{t('No Name')}</span>;
      },
    },
    {
      title: t('Schedule'),
      className: styles['schedule-header'],
      render: (pump) => getSchedule(pump, getPumpScheduleMessage(
        t,
        pump.pumpOnline,
        pump.pumpCurrentState,
        pump.pumpStartDate,
        pump.pumpStopDate,
        pump.pumpOverrideStatus && pump.pumpOverrideStatus.state,
      )),
    },
    {
      title: t('Override'),
      className: styles['override-header'],
      render: (pump) => (
        <OverrideStatus
          type={VFD_TYPE}
          overrideStatus={pump.pumpOverrideStatus}
          vfdIdentifier={pump.pumpIdentifier}
          currentState={pump.pumpCurrentState}
          confirmDialogMessages={[
            getPumpScheduleMessage(
              t,
              pump.pumpOnline,
              pump.pumpCurrentState,
              pump.pumpStartDate,
              pump.pumpStopDate,
            )]}
        />
      ),
    },
    {
      title: t('State'),
      className: styles['state-header'],
      render: (pump) => (
        <div className={styles.status}>
          {getCurrentState(pump)}
        </div>
      ),
    },
    {
      title: t('Alarms'),
      className: styles['alarms-header'],
      render: (pump) => (
        <SensorAlarmButton
          type="vfd"
          identifier={pump.pumpIdentifier}
          alarmCount={pump.alarmCount}
        />
      ),
    },
  ];

  return (
    <div className={styles['list-container']}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={['1']}
      >
        <Panel
          header={(
            <div>
              <Text style={{ fontSize: '16px' }}>Pumps</Text>
              {pumpLoading || spinner
                ? <LoadingOutlined style={{ marginLeft: '10px', fontSize: '16px' }} />
                : ''}
            </div>
          )}
          key="1"
          className={styles['collapse-header']}
        >
          {pumpData.length
            ? (
              <Table
                className={styles['custom-table']}
                rowClassName={getRowClassName}
                dataSource={pumpData}
                columns={widthCalc(columns)}
                pagination={false}
                rowKey={(record) => String(record.pumpId)}
                expandable={{
                  expandRowByClick: true,
                  rowExpandable: () => true,
                  expandedRowRender: (pump) => (
                    <PumpControlSensorList
                      pumpType="vfd"
                      pumpIdentifier={pump.pumpIdentifier}
                    />
                  ),
                }}
              />
            ) : (
              <section className={styles['configuration-message']}>
                No Pumps Configured
              </section>
            )}
        </Panel>
      </Collapse>
    </div>
  );
}

PumpControlList.propTypes = {
  pumps: PropTypes.arrayOf(PropTypes.object),
  onClickCalendar: PropTypes.func,
  pumpLoading: PropTypes.bool,
};

PumpControlList.defaultProps = {
  pumps: [],
  onClickCalendar: null,
  pumpLoading: null,
};
