import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Select } from 'antd';
import styles from './InputStyles.less';

function InputNumberUnits(props) {
  const {
    disabled,
    initialValue,
    onChange,
    unitOptions,
  } = props;
  const [units, setUnits] = useState((unitOptions && unitOptions[0] && unitOptions[0].key) || '');
  const [value, setValue] = useState(initialValue || 0);

  function onUnitsChange(newUnits) {
    setUnits(newUnits);
    if (onChange) {
      onChange({
        value,
        units: newUnits,
      });
    }
  }

  function onValueChange(newValue) {
    setValue(newValue);
    if (onChange) {
      onChange({
        value: newValue,
        units,
      });
    }
  }

  return (
    <div>
      <section className={styles.container}>
        <div className={styles['left-half']}>
          <InputNumber
            value={value}
            onChange={onValueChange}
            disabled={disabled}
            style={{ width: '100%' }}
          />
        </div>
        <div className={styles['right-half']}>
          <Select
            value={units}
            onChange={onUnitsChange}
            disabled={disabled}
          >
            {unitOptions.map((option) => (
              <Select.Option key={option.key} value={option.key}>{option.name}</Select.Option>
            ))}
          </Select>
        </div>
      </section>
    </div>
  );
}

InputNumberUnits.propTypes = {
  unitOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  initialValue: PropTypes.number,
  onChange: PropTypes.func,
};

InputNumberUnits.defaultProps = {
  disabled: false,
  initialValue: 0,
  onChange: null,
};

export default InputNumberUnits;
