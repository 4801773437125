import L from 'leaflet';
import {
  stateColorCode, icons, getColorCodeForSoilState, getAnomalyIconColor, getAnomalyIcon,
} from './constants';

/**
 * connectivity status
 * green - reported in last 30 min
 * yellow - reported in last 2 hours
 * red - hasn't reported in last 2 hours
 * grey - hasn't reported ever
 */
function getColorsByLastPostedInMinutes(feature) {
  const minutes = feature.properties.lastPostedInMinutes;
  if (minutes === undefined) {
    return stateColorCode[0];
  }
  if (minutes <= 30) {
    return stateColorCode[5];
  }
  if (minutes > 30 && minutes <= 120) {
    return stateColorCode[3];
  }
  if (minutes > 120) {
    return stateColorCode[2];
  }
  if (minutes === -1) {
    return stateColorCode[1];
  }

  return stateColorCode[1];
}

export function prepareMarker(feature, latlng, presentationMode, isSelected) {
  const opts = {};
  opts.markerColor = '#fff';
  opts.iconColor = '#000';
  opts.selected = false;
  switch (presentationMode) {
    case 'initial': {
      opts.markerColor = 'gray';
      opts.iconColor = 'white';
      break;
    }
    case 'soilstatus': {
      // if soil sensors
      if (['aquacheck_soil', 'pixl_soil'].includes(feature.properties.type)
        && feature.properties.block
      ) {
        const { soilState } = feature.properties;
        if (!soilState) {
          opts.markerColor = 'gray';
          opts.iconColor = 'white';
          break;
        }
        opts.markerColor = getColorCodeForSoilState(soilState);
        opts.iconColor = 'white';
        break;
      }

      opts.markerColor = 'white';
      opts.iconColor = 'black';
      break;
    }
    case 'connectivity': {
      const color = getColorsByLastPostedInMinutes(feature);
      opts.markerColor = color.markerColor;
      opts.iconColor = 'white';
      break;
    }
    case 'waterpressure': {
      opts.markerColor = 'gray';
      opts.iconColor = 'black';
      if (feature.properties.waterPressure !== undefined) {
        opts.data = {};
        opts.data.value = feature.properties.waterPressure;
        opts.data.valueColor = feature.properties.waterPressureRatio < 0.5
          ? 'black'
          : 'white';
        if (feature.properties.waterPressureColor) {
          opts.markerColor = feature.properties.waterPressureColor;
        }
      }
      break;
    }
    case 'control': {
      opts.markerColor = 'gray';
      opts.iconColor = 'black';
      if (feature.properties.type === 'water_pressure') {
        if (feature.properties.waterPressure !== undefined) {
          opts.data = {};
          opts.data.value = feature.properties.waterPressure;
          opts.data.valueColor = feature.properties.waterPressureRatio < 0.5
            ? 'black'
            : 'white';
          if (feature.properties.waterPressureColor) {
            opts.markerColor = feature.properties.waterPressureColor;
          }
        }
      } else if (feature.properties.controlSateON) {
        opts.markerColor = '#3C61B4';
        opts.iconColor = 'white';
      }

      break;
    }
    case 'provision': {
      opts.iconColor = 'white';
      opts.markerColor = '#e23549';
      if (feature.properties.provisioned) {
        opts.markerColor = '#3dc873';
      }
      break;
    }
    case 'anomaly': {
      opts.markerColor = getAnomalyIconColor(feature.properties.anomaly_type);
      opts.iconColor = '#fff';
      break;
    }

    default: {
      opts.markerColor = '#fff';
      opts.iconColor = '#000';
      opts.selected = false;
    }
  }

  if (feature.properties.alarmCount) {
    opts.markerBorderColor = 'red';
  }

  const markerIcon = L.VectorMarkers.icon({
    icon: presentationMode === 'anomaly' ? getAnomalyIcon(feature.properties.anomaly_type)
      : icons[feature.properties.type] || 'icon-radio-waves',
    prefix: 'icon',
    markerColor: opts.markerColor,
    iconColor: opts.iconColor,
    dataTestId: feature.id.split('/').join('-'),
    selected: opts.selected,
    selectedClass: isSelected
      ? 'marker-is-selected'
      : '',
    data: opts.data,
    markerBorderColor: opts.markerBorderColor,
  });

  // eslint-disable-next-line new-cap
  const marker = new L.marker(latlng, { icon: markerIcon });

  return marker;
}

export function prepareSingleMarkerIcon() {
  const opts = {};
  opts.markerColor = '#fff';
  opts.iconColor = '#000';
  opts.selected = false;

  return L.VectorMarkers.icon({
    icon: 'icon-radio-waves',
    prefix: 'icon',
    markerColor: opts.markerColor,
    iconColor: opts.iconColor,
    dataTestId: 'single-marker',
    selected: opts.selected,
  });
}
