/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

const name = 'sensorsgeojson';

const sensorsGeoJSON = createSlice({
  name,
  initialState: {},
  reducers: {
    setSensorsStateForGeoJSON(
      state,
      {
        payload: {
          sensorsFeatures,
        },
      },
    ) {
      if (!Object.keys(sensorsFeatures).length) {
        return state;
      }

      const ranchIdsObj = Object.values(sensorsFeatures)
        .reduce((acc, sensorFeature) => {
          acc[sensorFeature.properties.ranchId] = sensorFeature.properties.ranchId;
          return acc;
        }, {});

      const sensorsFeaturesRemaining = Object.values(state)
        .filter((sensorFeature) => !ranchIdsObj[sensorFeature.properties.ranchId])
        .reduce((acc, sensorFeature) => {
          acc[sensorFeature.properties.id] = cloneDeep(sensorFeature);

          return acc;
        }, {});

      return cloneDeep({
        ...sensorsFeaturesRemaining,
        ...sensorsFeatures,
      });
    },
  },
});

export const { setSensorsStateForGeoJSON } = sensorsGeoJSON.actions;
export default sensorsGeoJSON.reducer;
