import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { sensorApi } from 'farmx-api';

import {
  initialState,
  loadByTypeIdentifier,
  requestItemByParams,
  receiveItemUpsertNested,
  requestItemByParamsFailed,
} from '../../helpers';

import {
  sensorsAdapter,
} from '../selectors';

const name = 'capabilities';
export const getCapabilitiesState = (state) => state.sensorsData.capabilities;

export const loadSensorCapabilities = createAsyncThunk(
  `${name}/loadSingle`,
  loadByTypeIdentifier({
    getData: sensorApi.loadSensorCapabilities,
    getRequestState: (state, type, identifier) => {
      const id = sensorsAdapter.selectId({ type, identifier });
      return sensorsAdapter.getSelectors(getCapabilitiesState).selectById(state, id);
    },
  }),
);

const capabilities = createSlice({
  name,
  initialState: sensorsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [loadSensorCapabilities.pending]: requestItemByParams(sensorsAdapter),
    [loadSensorCapabilities.fulfilled]: receiveItemUpsertNested(sensorsAdapter),
    [loadSensorCapabilities.rejected]: requestItemByParamsFailed(sensorsAdapter),
  },
});

export default capabilities.reducer;
