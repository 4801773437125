import { createSelector } from '@reduxjs/toolkit';
import { farmAdapter } from './farmSlice';
import { plansAdapter } from './plans';
import { selectLoadingState } from '../helpers';
import {
  selectSensorStatus,
  selectSensorByParams,
  selectSensorsForIrrigationBlockId,
  selectSensorsForRanchId,
  populateSensorStatus,
} from '../sensor/selectors';
import { selectLoginUserInfo } from '../client/selectors';

const selectEntitiesState = (state) => state.farmData.entities;
const selectRanchesState = (state) => state.farmData.ranches;
const selectBlocksState = (state) => state.farmData.blocks;
const selectPlansState = (state) => state.farmData.plans;

const entitySelectors = farmAdapter.getSelectors(selectEntitiesState);
const ranchSelectors = farmAdapter.getSelectors(selectRanchesState);
const blockSelectors = farmAdapter.getSelectors(selectBlocksState);
const plansSelectors = plansAdapter.getSelectors(selectPlansState);

// Entity Selectors
const selectEntityById = entitySelectors.selectById;
const selectAllEntities = entitySelectors.selectAll;

// Ranch Selectors
const populateRanch = (state, ranch) => (ranch && {
  ...ranch,
  entity: selectEntityById(state, ranch.entity),
});
const selectRanchById = (state, id) => {
  const ranch = ranchSelectors.selectById(state, id);
  return populateRanch(state, ranch);
};
const selectRanchesForEntityId = (state, entityId) => ranchSelectors.selectAll(state).filter(
  (ranch) => ranch.entity === entityId,
).map(
  (ranch) => populateRanch(state, ranch),
);
const selectAllRanches = (state) => ranchSelectors.selectAll(state).map(
  (ranch) => populateRanch(state, ranch),
);
const sortByName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

const selectAllRanchesAlphabetically = createSelector(
  selectAllRanches,
  (ranches) => ranches.sort(sortByName),
);

// Block Selectors
const populateBlock = (state, block) => (block && {
  ...block,
  ranch: selectRanchById(state, block.ranch),
});
const selectBlockById = blockSelectors.selectById;
const selectAllBlocks = (state) => blockSelectors.selectAll(state).map(
  (block) => populateBlock(state, block),
);

const selectBlocksForRanchId = (state, ranchId) => blockSelectors.selectAll(state).filter(
  (block) => block.ranch === ranchId,
).map(
  (block) => populateBlock(state, block),
);

const selectBlocksForRanchIdAlphabetically = createSelector(
  selectBlocksForRanchId,
  (blocks) => blocks.sort(sortByName),
);

const selectIsAutoScheduled = createSelector(
  selectAllBlocks,
  (blocks) => blocks.every((item) => item.auto_schedule),
);

// Loading state selectors
const selectEntitiesLoadingState = (state) => selectLoadingState(selectEntitiesState(state));
const selectRanchesLoadingState = (state) => selectLoadingState(selectRanchesState(state));
const selectBlocksLoadingState = (state) => selectLoadingState(selectBlocksState(state));
const selectFarmDataLoadingState = (state) => {
  const entitiesLoadingState = selectEntitiesLoadingState(state);
  const ranchesLoadingState = selectRanchesLoadingState(state);
  const blocksLoadingState = selectBlocksLoadingState(state);
  const loading = (
    entitiesLoadingState.loading
    || ranchesLoadingState.loading
    || blocksLoadingState.loading
  );
  return {
    loading,
    entitiesLoadingState,
    ranchesLoadingState,
    blocksLoadingState,
  };
};

const selectBlockPressureSensors = (state, blockId) => populateSensorStatus(
  state,
  selectSensorsForIrrigationBlockId(state, blockId, 'water_pressure', 'installed'),
);

const selectBlockPumpController = (state, blockId) => {
  const block = selectBlockById(state, blockId);
  const vfdObj = block && block.vfd;
  if (vfdObj) {
    const sensor = selectSensorByParams(state, vfdObj);
    return {
      ...sensor,
      status: selectSensorStatus(state, sensor.type, sensor.identifier),
    };
  }
  return {};
};

const selectBlockPumpControllers = (state, blockId) => {
  const block = selectBlockById(state, blockId);
  const vfd = (block && block.vfd);
  const vfds = (block && block.vfds) || [vfd] || [];
  const pumpControllers = vfds.map((vfdObj) => {
    if (vfdObj) {
      const sensor = selectSensorByParams(state, vfdObj);
      return {
        ...sensor,
        blockId,
        blockName: block.name,
        status: selectSensorStatus(state, sensor.type, sensor.identifier),
      };
    }
    return {};
  }).reduce((acc, obj) => {
    acc[obj.identifier] = obj;
    return acc;
  }, {});
  return pumpControllers;
};

/**
 * Select pump controller data for multiple blocks
 * @param {*} state
 * @param {Array} blockIds
 * @param {boolean} returnAsArray
 */
const selectPumpControllersForBlockIds = (state, blockIds = [], returnAsArray = false) => {
  const pumpControllers = blockIds.reduce((acc, curr) => ({
    ...acc,
    ...selectBlockPumpControllers(state, curr),
  }), {});
  if (returnAsArray) {
    return Object.keys(pumpControllers).map((pumpController) => pumpControllers[pumpController]);
  }
  return pumpControllers;
};

const selectBlockValveController = (state, blockIds) => {
  if (blockIds && blockIds.length) {
    const valveControllerData = blockIds.map((blockId) => {
      const block = selectBlockById(state, blockId);
      const valveObj = block && block.valve;
      if (valveObj) {
        const sensor = selectSensorByParams(state, valveObj);
        return {
          ...sensor,
          blockId,
          status: selectSensorStatus(state, sensor.type, sensor.identifier),
        };
      }
      return {};
    }).reduce((acc, obj) => {
      acc[obj.blockId] = obj;
      return acc;
    }, {});
    return valveControllerData;
  }
  return {};
};


const selectBlockValveControllers = (state, blockId) => {
  const block = selectBlockById(state, blockId);
  const vIdentifiers = block && block.valve;
  const valves = (block && block.valves) || [];
  let valveDataForRanch = [];
  const valveIdentifiers = selectSensorsForRanchId(state, block && block.ranch, 'valve')
    .filter((d) => d && valves.includes(d.id))
    .map((d) => ({ type: d.type, identifier: d.identifier }));
  if (valveIdentifiers.length) {
    valveDataForRanch = valveIdentifiers;
  } else valveDataForRanch = [vIdentifiers];
  const valveControllers = valveDataForRanch.map((valveObj) => {
    if (valveObj) {
      const sensor = selectSensorByParams(state, valveObj);
      return {
        ...sensor,
        blockId,
        status: selectSensorStatus(state, sensor.type, sensor.identifier),
      };
    }
    return {};
  }).reduce((acc, obj) => {
    acc[obj.identifier] = obj;
    return acc;
  }, {});
  return valveControllers;
};

const selectValveControllersForBlocks = (state, blockIds) => {
  if (blockIds && blockIds.length) {
    const valveControllerDataArr = [];
    blockIds.forEach((blockId) => {
      const block = selectBlockById(state, blockId);
      const vIdentifiers = block && block.valve;
      const valves = (block && block.valves) || [];
      let valveDataForRanch = [];
      const valveIdentifiers = selectSensorsForRanchId(state, block && block.ranch, 'valve')
        .filter((d) => d && valves.includes(d.id))
        .map((d) => ({ type: d.type, identifier: d.identifier }));
      if (valveIdentifiers.length) {
        valveDataForRanch = valveIdentifiers;
      } else valveDataForRanch = [vIdentifiers];
      const valveControllers = valveDataForRanch.map((valveObj) => {
        if (valveObj) {
          const sensor = selectSensorByParams(state, valveObj);
          return {
            ...sensor,
            blockId,
            status: selectSensorStatus(state, sensor.type, sensor.identifier),
          };
        }
        return {};
      });
      valveControllerDataArr.push(...valveControllers);
    });
    const uniqueValveControllerArr = valveControllerDataArr
      .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index);
    const valveControllerData = {};
    uniqueValveControllerArr.forEach((obj) => {
      if (valveControllerData[obj.blockId]) {
        valveControllerData[obj.blockId].push(obj);
      } else {
        valveControllerData[obj.blockId] = [obj];
      }
    });
    return valveControllerData;
  }
  return {};
};

const selectBlockFlowMeter = (state, blockId) => {
  const block = selectBlockById(state, blockId);
  const flowMeters = selectSensorsForRanchId(state, block && block.ranch, 'water_flow_analog', 'installed');
  return flowMeters && flowMeters.length && populateSensorStatus(state, flowMeters)[0];
};

const selectPumpControllerIrrigationBlocks = (state, vfd) => selectAllBlocks(state).filter(
  (block) => {
    if (!block.vfd) return false;
    if (block.vfd.identifier !== vfd.identifier) return false;
    return block.vfd.type === vfd.type;
  },
);

// select block valve status
const selectBlockSensorStatus = (state, blocks) => blocks.map((block) => {
  let vid = null;
  if (block.valve) {
    vid = block.valve.identifier;
  }
  const valveState = selectSensorStatus(state, 'valve', vid);
  let online;
  let loading;
  if (valveState) {
    online = valveState.online;
    loading = valveState.loading;
  }

  let currState;
  let valveScheduledOpen;
  let valveScheduledClose;
  if (valveState && valveState.controlStatus) {
    currState = valveState.controlStatus.state;
  }

  if (valveState && valveState.scheduleStatus) {
    valveScheduledOpen = valveState.scheduleStatus.dateScheduledOpen;
    valveScheduledClose = valveState.scheduleStatus.dateScheduledClose;
  }

  let ovrdStatus = null;
  if (valveState && valveState.overrideStatus) {
    ovrdStatus = valveState.overrideStatus;
  }

  let pid = null;
  if (block.vfd) {
    pid = block.vfd.identifier;
  }
  const pumpState = selectSensorStatus(state, 'vfd', pid);

  let pumpCurrState;
  let pumpStart;
  let pumpStop;
  if (pumpState && pumpState.scheduleStatus) {
    pumpStart = pumpState.scheduleStatus.dateScheduledStart;
    pumpStop = pumpState.scheduleStatus.dateScheduledStop;
  }
  if (pumpState && pumpState.controlStatus) {
    pumpCurrState = pumpState.controlStatus.status;
  }

  return {
    blockId: block.id,
    blockName: block.name,
    valveIdentifier: vid,
    valveOnline: online,
    valveCurrentState: currState,
    valveOverrideStatus: ovrdStatus,
    valveControlEnabled: block.control_enabled,
    valveScheduledOpen,
    valveScheduledClose,
    vfdIdentifier: pid,
    pumpStartDate: pumpStart,
    pumpStopDate: pumpStop,
    valveLoading: loading,
    valveAlarmCount: valveState && valveState.alarmCount,
    blockAlarmCount: valveState && valveState.blockAlarmCount,
    pumpCurrentState: pumpCurrState,
  };
});

// select block valve status
const selectPumpSensorStatus = (state, pumps) => pumps.map((pump) => {
  let pid = null;
  if (pump.identifier) {
    pid = pump.identifier;
  }
  const pumpState = selectSensorStatus(state, 'vfd', pid);
  let online;
  let loading;
  if (pumpState) {
    online = pumpState.online;
    loading = pumpState.loading;
  }
  let currState;
  let datePumpStart;
  let datePumpStop;
  if (pumpState && pumpState.controlStatus) {
    currState = pumpState.controlStatus.status;
  }

  if (pumpState && pumpState.scheduleStatus) {
    datePumpStart = pumpState.scheduleStatus.dateScheduledStart;
    datePumpStop = pumpState.scheduleStatus.dateScheduledStop;
  }

  let ovrdStatus = null;
  if (pumpState && pumpState.overrideStatus) {
    ovrdStatus = pumpState.overrideStatus;
  }

  return {
    pumpId: pump.id,
    pumpName: pump.name,
    pumpIdentifier: pid,
    pumpCurrentState: currState,
    pumpOverrideStatus: ovrdStatus,
    pumpStartDate: datePumpStart,
    pumpStopDate: datePumpStop,
    pumpOnline: online,
    pumpLoading: loading,
    alarmCount: pumpState && pumpState.alarmCount,
  };
});

// Returns a map of blockId to blockAlarmCount given an array of blockIds
const selectBlockControlStatus = (state, blockIds) => blockIds.reduce((a, blockId) => {
  const block = selectBlockById(state, blockId);
  const valveIdentifier = block && block.valve && block.valve.identifier;
  const valveStatus = selectSensorStatus(state, 'valve', valveIdentifier);
  const vfdIdentifier = block && block.vfd && block.vfd.identifier;
  const vfdStatus = selectSensorStatus(state, 'vfd', vfdIdentifier);
  a[blockId] = {
    valveStatus,
    vfdStatus,
  };
  return a;
}, {});

const selectBlockControlStatusLoading = (state, blockIds) => blockIds.reduce((a, blockId) => {
  const block = selectBlockById(state, blockId);
  const valveIdentifier = block && block.valve && block.valve.identifier;
  const valveStatus = selectSensorStatus(state, 'valve', valveIdentifier);
  const vfdIdentifier = block && block.vfd && block.vfd.identifier;
  const vfdStatus = selectSensorStatus(state, 'vfd', vfdIdentifier);
  return a || (valveStatus && valveStatus.loading) || (vfdStatus && vfdStatus.loading);
}, false);

const selectBlocksForRanchBlockSelection = (state, selectedObj) => {
  let blocks = [];
  const { type, value } = selectedObj || {};
  if (type && type === 'ranch') {
    blocks = selectBlocksForRanchId(state, Number(value));
  } else if (type && type === 'block') {
    const blockObj = selectBlockById(state, Number(value));
    blocks = blockObj && [blockObj];
  } else {
    blocks = selectAllBlocks(state);
  }

  return blocks && blocks.sort((a, b) => a && b && a.name.localeCompare(b.name));
};

// returns array of ranches
const selectRanchesForRanchBlockSelection = (state, selectedObj) => {
  const userDetail = selectLoginUserInfo(state).payload;

  const { type, value } = selectedObj || {};
  if (type && type === 'ranch') {
    return [selectRanchById(state, Number(value))];
  }
  if (type && type === 'block') {
    const block = selectBlockById(state, Number(value));
    if (!block || !block.ranch) return [];
    const ranch = selectRanchById(state, block.ranch);
    return ranch ? [ranch] : [];
  }

  // Return all ranches only for non-admin users
  return userDetail && !userDetail.admin ? selectAllRanches(state) : [];
};

const selectPressureDetails = (state, blockId) => {
  let pressure = 0;
  let sensorList = [];
  let loading = false;
  const pressureSensorsList = [];
  let identifiers = [];
  const pressureCutoff = [];
  const pressureSensors = selectBlockPressureSensors(state, blockId);

  if (pressureSensors && pressureSensors.length) {
    pressureSensors.forEach((d) => {
      const statusObj = d && d.status;
      if (statusObj) {
        const psi = (Number(statusObj.waterPressure || 0));
        pressure += psi > 0 ? Math.abs(psi) : 0;
        pressureCutoff.push(statusObj.pressureCutoff);
      }
      pressureSensorsList.push({
        identifier: d && d.identifier,
        sensorId: d && d.id,
        alarmCount: statusObj && statusObj.alarmCount,
        lastUpdated: statusObj && statusObj.lastUpdated,
        name: d && d.name,
        sensorOnline: statusObj && statusObj.online,
        pressure: `${statusObj && statusObj.waterPressure > 0 ? (statusObj && statusObj.waterPressure).toFixed(2) : 0} psi`,
      });
    });

    loading = pressureSensors.reduce((a, p) => !p || a || (p.status && p.status.loading), false);
    pressure = pressure > 0 ? (pressure / pressureSensors.length).toFixed(2) : 0;
    sensorList = pressureSensors.map((d) => d && d.name);
    identifiers = pressureSensors.map((d) => d && d.identifier);
  }

  return {
    pressure: String(pressure),
    sensorList,
    pressureLoading: loading,
    pressureSensorsList,
    pressureSensorIdentifiers: JSON.stringify(identifiers),
    pressureCutoff,
  };
};

const selectEntityIdForRanchBlock = (state, ranchId, blockId) => {
  let entityId = [];
  if (ranchId) {
    const ranchObj = selectRanchById(state, ranchId);
    if (ranchObj) {
      entityId = [ranchObj].map((ranch) => ranch && ranch.entity && ranch.entity.id);
    }
  }
  if (blockId) {
    const block = selectBlockById(state, blockId);
    const ranchObj = selectRanchById(state, block && block.ranch);
    if (ranchObj) {
      entityId = [ranchObj].map((ranch) => ranch && ranch.entity && ranch.entity.id);
    }
  }

  return entityId;
};

const selectAllBlockIds = (state) => blockSelectors.selectIds(state, []);
const selectAllRanchIds = (state) => ranchSelectors.selectIds(state, []);

const selectBlockCount = (state) => blockSelectors.selectIds(state, []).length;

const selectBlocksLoaded = (state) => {
  const blockCount = blockSelectors.selectIds(state, []).length;
  if (blockCount) return true;
  return false;
};

export {
  selectFarmDataLoadingState,
  selectEntitiesLoadingState,
  selectEntityById,
  selectAllEntities,
  selectRanchesLoadingState,
  selectRanchById,
  selectRanchesForEntityId,
  selectAllRanches,
  selectAllRanchesAlphabetically,
  selectBlocksLoadingState,
  selectBlockById,
  selectAllBlocks,
  selectBlocksForRanchId,
  selectBlocksForRanchIdAlphabetically,
  selectBlockSensorStatus,
  selectPumpSensorStatus,
  ranchSelectors,
  entitySelectors,
  plansSelectors,
  selectBlockPressureSensors,
  selectBlockPumpController,
  selectBlockFlowMeter,
  selectPumpControllerIrrigationBlocks,
  selectBlockControlStatus,
  selectBlockControlStatusLoading,
  selectBlocksForRanchBlockSelection,
  selectRanchesForRanchBlockSelection,
  selectPressureDetails,
  selectEntityIdForRanchBlock,
  selectAllBlockIds,
  selectAllRanchIds,
  selectBlockCount,
  selectBlocksLoaded,
  selectBlockValveController,
  selectBlockValveControllers,
  selectBlockPumpControllers,
  selectPumpControllersForBlockIds,
  selectValveControllersForBlocks,
  selectIsAutoScheduled,
};
