import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectEntityById } from '../selectors';
import { loadEntityList } from '../actions';

export default function useEntity(id) {
  const dispatch = useDispatch();
  const entity = useSelector((state) => selectEntityById(state, id));
  useEffect(() => {
    // TODO: load entity by id, rather than whole list
    dispatch(loadEntityList());
  }, [dispatch]);
  return entity;
}
