import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import area from '@turf/area';

const name = 'ranchesgeojson';

const ranchesGeoJSON = createSlice({
  name,
  initialState: {},
  reducers: {
    setRanchesGeoJSON(
      state,
      {
        payload: {
          ranchesFeatures,
        },
      },
    ) {
      if (!Object.keys(ranchesFeatures).length) {
        return state;
      }

      const ranchesFeaturesRemaining = Object.values(state)
        .filter((ranchFeature) => !ranchesFeatures[ranchFeature.properties])
        .reduce((acc, ranchFeature) => {
          acc[ranchFeature.properties.id] = cloneDeep(ranchFeature);

          return acc;
        }, {});

      return {
        ...cloneDeep(ranchesFeaturesRemaining),
        ...cloneDeep(ranchesFeatures),
      };
    },
  },
});

export const { setRanchesGeoJSON } = ranchesGeoJSON.actions;
export default ranchesGeoJSON.reducer;
