import { combineReducers } from 'redux';
import entities from './entities';
import ranches from './ranches';
import blocks from './blocks';
import plans from './plans';
import blocksgeojson from './blocks-geojson';
import ranchesgeojson from './ranches-geojson';
import soilstate from './soilstate';

const farmReducer = combineReducers({
  entities,
  ranches,
  blocks,
  plans,
  blocksgeojson,
  ranchesgeojson,
  soilstate,
});

export default farmReducer;
