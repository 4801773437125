import { sensorApi } from 'farmx-api';
import createFlatSlice from '../flatSlice';

const { fetchSensorNotes } = sensorApi;

const sensorNotes = createFlatSlice(
  'notes',
  fetchSensorNotes,
  () => {}, // currently not implemented
  (state) => state.sensorsData.notes,
);

const {
  loadFlatList: loadSensorNotesList,
  loadSingle: loadSensorNote,
  entityAdapter: sensorNotesAdapter,
} = sensorNotes;

export {
  loadSensorNotesList,
  loadSensorNote,
  sensorNotesAdapter,
};

export default sensorNotes.slice.reducer;
