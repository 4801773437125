import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadSensorStatus } from '../actions';

export function useLoadStatus(list) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (list.length > 0) {
      const sensorList = JSON.parse(sessionStorage.getItem('sensorList')) || [];
      const sensorType = JSON.parse(sessionStorage.getItem('sensorType'));
      const { type } = list[0];

      /**
       * Load data when application loads for the first time only.
       * This prevents reading outdated data from cache.
       */
      if (sensorType && sensorType[type]) {
        return;
      }
      if (sensorType) {
        sessionStorage.setItem('sensorType', JSON.stringify({
          ...sensorType,
          [type]: true,
        }));
      } else {
        sessionStorage.setItem('sensorType', JSON.stringify({
          [type]: true,
        }));
      }

      /**
       * When ranch block selection is changed,
       * only load status for missing items.
       */
      const itemsToBeFetched = list.filter((item) => {
        const index = sensorList.findIndex((sl) => sl === `${item.type}/${item.identifier}`);

        if (index === -1) {
          sensorList.push(`${item.type}/${item.identifier}`);
          return true;
        }

        return false;
      });

      sessionStorage.setItem('sensorList', JSON.stringify(sensorList));

      /**
       * This is a temporary loop
       * Breaking the iteration so as to limit the network call
       */
      itemsToBeFetched.some((item, index) => {
        dispatch(loadSensorStatus({ type: item.type, identifier: item.identifier }));
      });

      /*
      list.forEach((item) => (
        dispatch(loadSensorStatus({ type: item.type, identifier: item.identifier }))
      ));
      */
    }
  }, [list, dispatch]);
}
