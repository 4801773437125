export const isNotNull = (value) => (value !== undefined && value !== null);

export const mean = (arr) => (
  arr.length
    ? arr.reduce((acc, curr) => acc + curr, 0) / arr.length
    : 0
);

export const getAverageBy = (arr, key) => {
  if (!arr.length) return '';

  let sum = 0;
  let count = 0;

  arr.forEach((element) => {
    if (isNotNull(element[key])) {
      sum += Number(element[key]);
      count += 1;
    }
  });

  if (!count) return '';
  return parseFloat((sum / count).toFixed(2));
};

export const getPercentAvailable = (vwc, wiltingPoint, fieldCapacity) => {
  const fieldWiltingDifference = fieldCapacity - wiltingPoint;
  if (fieldWiltingDifference === 0) return 0;
  const percentAvailable = (vwc - wiltingPoint) / (fieldWiltingDifference);
  return Number.isNaN(percentAvailable) ? 0 : percentAvailable;
};

export const getVolumeAvailable = (depth, vwc, wiltingPoint, fieldCapacity) => (
  parseFloat(depth * getPercentAvailable(vwc, wiltingPoint, fieldCapacity).toFixed(1))
);

export const getPercentAndVolume = (depth, vwc, wiltingPoint, fieldCapacity) => {
  const percent = getPercentAvailable(vwc, wiltingPoint, fieldCapacity);
  const volume = depth * percent;
  return { percent, volume };
};
