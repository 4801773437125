/* eslint-disable react/jsx-fragments */
import React, {
  useState, useCallback, useRef,
} from 'react';
import { Layout, Button } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useWindowSize } from '../hooks';

const { Header, Content } = Layout;

export default function AppTemplate(props) {
  const size = useWindowSize();
  const scrollRef = useRef(null);
  const isMobile = size.width <= 600;
  const [collapsed, setCollapsed] = useState(isMobile);
  const {
    sidebarComponent: SidebarComponent,
    navBarComponent: NavBarComponent,
    entitySideBarComponent: EntitySideBarComponent,
    children,
    title = 'FarmX',
    logo,
    headerMenu,
    theme,
  } = props;

  const onCollapse = useCallback((collapse) => {
    setCollapsed(collapse);
  }, [setCollapsed]);

  const fontColor = (theme === 'dark') ? 'white' : '#555';

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  return (
    <Layout className="layout">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header>
        <div className="menu-trigger">
          <MenuOutlined
            className="trigger"
            style={{ fontSize: '25px', color: fontColor }}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div className="logo">
          <img className="header-logo" alt="FarmX" src={logo} />
        </div>

        {NavBarComponent && (
          <React.Fragment>
            <Button
              className="scroll-button"
              type="primary"
              icon={<LeftOutlined />}
              onClick={() => scroll(-25)}
            />
            <div className="middle-menu" ref={scrollRef}>
              <NavBarComponent isMobile={isMobile} />
            </div>
            <Button
              className="scroll-button"
              type="primary"
              icon={<RightOutlined />}
              onClick={() => scroll(25)}
            />
          </React.Fragment>
        )}

        <div className="right-menu">
          {headerMenu}
        </div>
      </Header>
      <Layout>
        {SidebarComponent && (
          <SidebarComponent
            isMobile={isMobile}
            onCollapse={onCollapse}
            collapsed={collapsed}
          />
        )}
        {EntitySideBarComponent && (
        <EntitySideBarComponent
          isMobile={isMobile}
          onCollapse={onCollapse}
          collapsed={collapsed}
        />
        )}

        <Content className={collapsed ? 'content-container collapsed' : 'content-container'}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

AppTemplate.propTypes = {
  sidebarComponent: PropTypes.elementType,
  navBarComponent: PropTypes.elementType,
  entitySideBarComponent: PropTypes.elementType,
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  logo: PropTypes.string.isRequired,
  headerMenu: PropTypes.element.isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
};

AppTemplate.defaultProps = {
  sidebarComponent: null,
  navBarComponent: null,
  entitySideBarComponent: null,
  title: 'FarmX',
  theme: 'light',
};
