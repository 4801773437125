import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { scheduleApi } from 'farmx-api';

export const scheduledEventsAdapter = createEntityAdapter();

const name = 'scheduledEvents';

export const loadBlockIrrigationSchedule = createAsyncThunk(
  `${name}/loadBlockIrrigationSchedule`,
  async (blockId) => {
    const response = await scheduleApi.retrieveBlockScheduledIrrigationEvents(
      blockId,
    );
    return response.data;
  },
);

const scheduledEventsSlice = createSlice({
  name,
  initialState: scheduledEventsAdapter.getInitialState({}),
  reducers: [],
  extraReducers: {
    [loadBlockIrrigationSchedule.pending]: (state, action) => {
      const blockId = action.meta.arg;
      scheduledEventsAdapter.upsertOne(state, {
        id: blockId,
        loading: true,
        currentRequestId: action.meta.requestId,
        error: undefined,
      });
    },
    [loadBlockIrrigationSchedule.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const blockId = action.meta.arg;
      const item = {
        id: blockId,
        loading: false,
        error: undefined,
        lastUpdated: Date.now(),
        events: action.payload,
      };
      scheduledEventsAdapter.upsertOne(state, item);
    },
    [loadBlockIrrigationSchedule.rejected]: (state, action) => {
      const blockId = action.meta.arg;
      scheduledEventsAdapter.upsertOne(state, {
        id: blockId,
        loading: false,
        error: action.error,
        currentRequestId: undefined,
      });
    },
  },
});

export { scheduledEventsSlice };
export default scheduledEventsSlice.reducer;
