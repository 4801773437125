import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Collapse, Table, Tag, Button, Tooltip, Typography, Badge,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { selectors, actions } from 'farmx-redux-core';
import { FaCalendarAlt } from 'react-icons/fa';
import { LoadingOutlined, BellFilled } from '@ant-design/icons';
import styles from './CommonStyles.less';
import BlockControlSensorList from './BlockControlSensorList';
import { getPressureDetailsForBlockIds, getBlockIrrigationStateByWaterPressureState } from '../helper';
import { getValveScheduleMessage } from './scheduleMessage';


const { Panel } = Collapse;
const { Text } = Typography;

const {
  selectBlockSensorStatus,
} = selectors;

const { loadSensorStatus } = actions;

export default function BlockControlList({
  blocks,
  valves,
  onClickCalendar,
  blockLoading,
}) {
  const dispatch = useDispatch();
  const filteredBlocks = blocks ? blocks.filter((d) => d.valve) : null;
  const blocksUnsorted = useSelector((state) => selectBlockSensorStatus(state, filteredBlocks));
  const blockDataSorted = blocksUnsorted.sort((a, b) => a.blockName.localeCompare(b.blockName));
  const spinner = blockDataSorted.reduce((a, p) => !p || a || p.valveLoading, false);
  const { t } = useTranslation();

  const blockIds = filteredBlocks && filteredBlocks.map((d) => d && d.id);
  const pressureSensorsObj = useSelector((state) => getPressureDetailsForBlockIds(state, blockIds));
  const sensorIdentifierArr = [];
  Object.values(pressureSensorsObj).forEach((pressureSensors) => {
    const { pressureSensorIdentifiers } = pressureSensors;
    JSON.parse(pressureSensorIdentifiers).forEach((identifier) => {
      sensorIdentifierArr.push({
        type: 'water_pressure',
        identifier,
      });
    });
  });

  const sensorIdentifiers = JSON.stringify(sensorIdentifierArr);
  useEffect(() => {
    JSON.parse(sensorIdentifiers).forEach((sensorParams) => {
      dispatch(loadSensorStatus(sensorParams));
    });
  }, [dispatch, sensorIdentifiers]);

  const valveObj = blocks.reduce((acc, obj) => {
    acc[obj.id] = {
      valveIds: obj.valves,
    };
    return acc;
  }, {});
  const vfds = blocks.reduce((acc, obj) => {
    acc[obj.id] = obj.vfds;
    return acc;
  }, {});

  const blockData = blockDataSorted.map((d) => {
    const obj = d;
    obj.valves = valveObj[obj.blockId];
    obj.vfds = vfds[obj.blockId];
    return obj;
  });

  const getMessageClassName = (state, online) => {
    const styleStr = `${styles['red-message']} `;
    const styleStrNot = `${styles['normal-message']} `;
    if (state === 'unknown' || (!online && state === 'closed')) {
      return styleStr;
    }
    if (!state) return styleStr;
    return styleStrNot;
  };

  const getSchedule = (block, text) => (
    <div
      className={styles['icon-container']}
      onClick={() => onClickCalendar({ id: block.blockId })}
      role="presentation"
    >
      <div className={styles.icon}>
        <FaCalendarAlt />
      </div>
      <div className={getMessageClassName(block.valveCurrentState, block.valveOnline)}>
        <span className={styles['schedule-content']}>
          {text}
        </span>
      </div>
    </div>
  );

  const getIndClass = (blockStatus) => {
    const waterPressureState = pressureSensorsObj[blockStatus.blockId]
      && pressureSensorsObj[blockStatus.blockId].waterPressureState;
    const styleStr = `${styles.indicator} `;
    if (!blockStatus.valveLoading) {
      if (blockStatus.valveControlEnabled) {
        if (!blockStatus.valveOnline) return styleStr + styles.red;
        if (blockStatus.valveOnline && waterPressureState === 1
          && blockStatus.valveCurrentState === 'closed') return styleStr + styles['light-grey'];
        if (blockStatus.valveOnline && waterPressureState === 4
          && (blockStatus.valveCurrentState === 'open'
            || blockStatus.pumpCurrentState === 'on')) return styleStr + styles.blue;
      }
    }
    return styleStr + styles['light-grey'];
  };

  const getRowClassName = (record) => {
    if (record.valveCurrentState) {
      if (!record.valveControlEnabled
        || record.valveCurrentState === 'unknown') {
        return styles['red-border-text'];
      }
      if (record.valveControlEnabled
        && (!record.valveOnline
          && (record.valveCurrentState === 'closed' || !record.valveCurrentState))) {
        return styles['red-border-text'];
      }
    }
    if (!record.valveLoading && (!record.valveControlEnabled
      || !record.valveCurrentState)) {
      return styles['red-border-text'];
    }
    const className = getIndClass(record);
    return className;
  };

  function widthCalc(columns) {
    const maxwid = 100;
    const result = columns().map((element) => {
      const values = element;
      if (element.title === 'Alarm') {
        values.width = 100;
      } if (element.title === 'Override') {
        values.width = 100;
      } if (element.title !== 'Override' && element.title !== 'Alarm') {
        values.width = `${(maxwid - 10) / (columns().length - 2)}%`;
      }
      return values;
    });
    return result;
  }

  function getAlarmListUrl(block) {
    const params = {
      blockId: block.blockId,
      logLevel: 'error',
      active: true,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/events?${paramStr}`;
  }

  const columns = () => [
    {
      title: t('Block'),
      className: styles['schedule-item-header'],
      render: (block) => {
        if (block.blockName) {
          return (
            <Tooltip title={block.blockName}>
              <span>{block.blockName}</span>
            </Tooltip>
          );
        }
        return <span style={{ color: 'lightgray' }}>{t('No Name')}</span>;
      },
    },
    {
      title: t('Schedule'),
      className: styles['schedule-header'],
      render: (block) => {
        if (block.valveControlEnabled) {
          return getSchedule(block, getValveScheduleMessage(
            t,
            block.valveOnline,
            block.valveCurrentState,
            block.valveScheduledOpen,
            block.valveScheduledClose,
            block.valveOverrideStatus && block.valveOverrideStatus.state,
          ));
        }
        return t('Control not enabled');
      },
    },
    {
      title: t('Override'),
      className: styles['hidden-column'],
      render: null,
    },
    {
      title: t('Irrigation State'),
      className: styles['state-header'],
      render: (block) => {
        const waterPressureState = pressureSensorsObj[block.blockId]
          && pressureSensorsObj[block.blockId].waterPressureState;
        const statusDetails = getBlockIrrigationStateByWaterPressureState(waterPressureState);
        return (
          <Tag
            key={block.valveCurrentState}
            color={statusDetails.color}
            className={waterPressureState > 1
              ? [styles['tag-icon-irrigation-state'], styles['tag-icon-irrigating']]
              : styles['tag-icon-irrigation-state']}
          >
            {statusDetails.irrigationState && statusDetails.irrigationState.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: t('Alarms'),
      className: styles['alarms-header'],
      render: (block) => (
        <Link to={getAlarmListUrl(block)}>
          <Badge
            count={block.blockAlarmCount}
          >
            <Button
              className={styles['bell-icon-button']}
              icon={<BellFilled className={styles['bell-icon-size']} />}
            />
          </Badge>
        </Link>
      ),
    },
  ];

  function renderBlockDetail(blockControlStatus) {
    return <BlockControlSensorList controlStatus={blockControlStatus} valves={valves} />;
  }

  return (
    <div className={styles['list-container']}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={['1']}
      >
        <Panel
          header={(
            <div>
              <Text style={{ fontSize: '16px' }}>{t('Blocks')}</Text>
              {blockLoading || spinner
                ? <LoadingOutlined style={{ marginLeft: '10px', fontSize: '16px' }} />
                : ''}
            </div>
          )}
          key="1"
          className={styles['collapse-header']}
        >
          {blockData.length
            ? (
              <Table
                className={styles['custom-table']}
                rowClassName={getRowClassName}
                dataSource={blockData}
                columns={widthCalc(columns)}
                pagination={false}
                rowKey={(record) => String(record.blockId)}
                expandable={{
                  expandRowByClick: true,
                  rowExpandable: () => true,
                  expandedRowRender: (block) => renderBlockDetail(block),
                }}
              />
            ) : (
              <section className={styles['configuration-message']}>
                No Blocks Configured
              </section>
            )}
        </Panel>
      </Collapse>
    </div>
  );
}

BlockControlList.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  valves: PropTypes.arrayOf(PropTypes.object),
  onClickCalendar: PropTypes.func,
  blockLoading: PropTypes.bool,
};

BlockControlList.defaultProps = {
  blocks: [],
  valves: [],
  onClickCalendar: null,
  blockLoading: null,
};
