import { loadBlockSchedule, loadAllBlockSchedule, scheduleSlice } from './slices/schedule';
import { loadBlockList, cleanBlocksListRequestState } from '../farm/blocks';
import { cleanRanchesListRequestState, loadRanchList } from '../farm/ranches';

const { cleanListRequestState } = scheduleSlice.actions;
const cleanScheduleListRequestState = () => cleanListRequestState();

const secondsAllowed = 10;

const loadBlocksWithSchedules = (blockIds) => async (dispatch, getState) => {
  dispatch(cleanBlocksListRequestState());
  dispatch(cleanRanchesListRequestState());
  dispatch(cleanScheduleListRequestState());

  const blocksLastUpdated = getState().farmData.blocks.lastUpdated || 100000000;
  const blocksElapsedSecondsUpdate = Math.round((Date.now() - blocksLastUpdated) / 1000);

  if (blocksElapsedSecondsUpdate > secondsAllowed) {
    await Promise.allSettled([
      dispatch(loadRanchList()),
      dispatch(loadBlockList()),
    ]);
  }

  const blockEntities = getState().farmData.blocks.entities;

  let blockIdsHavingSchedule = [];
  if (blockIds) {
    blockIdsHavingSchedule = Object
      .values(blockEntities).filter((blockEntity) => blockIds.indexOf(blockEntity.id) >= 0)
      .filter((blockEntity) => blockEntity.scheduling_enabled)
      .map((blockEntity) => blockEntity.id);
  } else {
    blockIdsHavingSchedule = Object
      .values(blockEntities)
      .filter((blockEntity) => blockEntity.scheduling_enabled)
      .map((blockEntity) => blockEntity.id);
  }

  return dispatch(loadAllBlockSchedule(blockIdsHavingSchedule));
};

export {
  loadBlockSchedule,
  loadBlocksWithSchedules,
  loadAllBlockSchedule,
};
