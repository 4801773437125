import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { scheduleApi } from 'farmx-api';

export const renderedEventsAdapter = createEntityAdapter();

const name = 'renderedEvents';

export const loadBlockRenderedSchedule = createAsyncThunk(
  `${name}/loadBlockRenderedSchedule`,
  async ({ blockId, dateStart, dateEnd }) => {
    const response = await scheduleApi.retrieveBlockRenderedIrrigationEvents({
      blockId, dateStart, dateEnd,
    });
    return response.data;
  },
);

// for now, only the last time range that was requested is stored
// TODO: update to keep cache of old data

const renderedEventsSlice = createSlice({
  name,
  initialState: renderedEventsAdapter.getInitialState({}),
  reducers: [],
  extraReducers: {
    [loadBlockRenderedSchedule.pending]: (state, action) => {
      const { blockId } = action.meta.arg;
      renderedEventsAdapter.upsertOne(state, {
        id: blockId,
        loading: true,
        currentRequestId: action.meta.requestId,
        error: undefined,
      });
    },
    [loadBlockRenderedSchedule.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const { blockId } = action.meta.arg;
      const { payload: events } = action;
      const item = {
        id: blockId,
        loading: false,
        error: undefined,
        lastUpdated: Date.now(),
        events,
      };
      renderedEventsAdapter.upsertOne(state, item);
    },
    [loadBlockRenderedSchedule.rejected]: (state, action) => {
      const { blockId } = action.meta.arg;
      renderedEventsAdapter.upsertOne(state, {
        id: blockId,
        loading: false,
        error: action.error,
        currentRequestId: undefined,
      });
    },
  },
});

export { renderedEventsSlice };
export default renderedEventsSlice.reducer;
