/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';

function PrivateRoute({
  component: Component, authService, adminRequired, ...rest
}) {
  const userInfo = authService.useAuth();
  const authorized = userInfo && (!adminRequired || userInfo.admin);
  const location = useLocation();
  const render = (props) => <Component {...props} />;

  return (
    authorized
      ? (<Route render={render} {...rest} />)
      : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authService: PropTypes.shape({
    useAuth: PropTypes.func.isRequired,
  }).isRequired,
  adminRequired: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  adminRequired: false,
};

export default PrivateRoute;
