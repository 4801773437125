export const selectSensorsFeaturesByRanchId = (state, ranchId) => Object
  .values(state.sensorsData.sensorsgeojson)
  .filter(({ properties }) => properties.ranchId === ranchId);

export const selectBlocksFeaturesByRanchId = (state, ranchId) => Object
  .values(state.farmData.blocksgeojson)
  .filter(({ properties }) => properties.ranchId === ranchId);

export const selectRanchesFeatures = (state) => Object
  .values(state.farmData.ranchesgeojson);

export const selectRanchFeaturesByRanchId = (state, ranchId) => Object
  .values(state.farmData.ranchesgeojson)
  .filter(({ properties }) => properties.id === ranchId);

export const selectRanchesCollection = (state) => Object
  .values(state.farmData.ranches.entities);

export const selectSoilStateCollectionObj = (state) => state.farmData.soilstate.entities;
