import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { sensorApi } from 'farmx-api';

import {
  initialState,
  requestItemByParams,
  receiveItem,
  requestItemByParamsFailed,
  loadByTypeIdentifier,
} from '../../helpers';

import {
  sensorsAdapter,
  selectSensorStatus,
} from '../selectors';

const name = 'status';

export const loadSensorStatus = createAsyncThunk(
  `${name}/loadSingle`,
  loadByTypeIdentifier({
    getData: sensorApi.loadSensorStatus,
    getRequestState: selectSensorStatus,
  }),
);

const status = createSlice({
  name,
  initialState: sensorsAdapter.getInitialState(initialState),
  reducers: {
    addItems(state, { payload }) {
      const records = {};
      payload.forEach((req) => {
        const { data: s } = req.value;
        const id = `${s.type}/${s.identifier}`;
        records[id] = {
          ...s,
          lastUpdated: Date.now(),
          loading: false,
          error: undefined,
          currentRequestId: undefined,
        };
      });
      if (Object.keys(records).length) {
        sensorsAdapter.upsertMany(state, records);
      }
    },
  },
  extraReducers: {
    [loadSensorStatus.pending]: requestItemByParams(sensorsAdapter),
    [loadSensorStatus.fulfilled]: receiveItem(sensorsAdapter),
    [loadSensorStatus.rejected]: requestItemByParamsFailed(sensorsAdapter),
  },
});
export const { addItems } = status.actions;
export default status.reducer;
