import { farmApi } from 'farmx-api';
import { createFarmSlice } from './farmSlice';

const {
  getBlockList,
  getBlockDetail,
} = farmApi;

const {
  loadListThunk: loadBlockList,
  loadDetailThunk: loadBlockDetail,
  slice: blocksSlice,
} = createFarmSlice(
  'blocks',
  getBlockList,
  getBlockDetail,
  (state) => state.farmData.blocks,
);

const { cleanListRequestState, updateItem: updateBlockItem } = blocksSlice.actions;

export const cleanBlocksListRequestState = () => cleanListRequestState();

export {
  loadBlockDetail,
  loadBlockList,
  updateBlockItem,
};


export default blocksSlice.reducer;
