import { sensorApi } from 'farmx-api';
import {
  createEntityAdapter, createSelector,
} from '@reduxjs/toolkit';
import {
  getAverageBy, getPercentAndVolume, isNotNull, mean,
} from '../helper/sensors';

import { sensorNotesAdapter } from './reducers/notes';
import { sensorImagesAdapter } from './reducers/images';

import { selectLoadingState } from '../helpers';


const { getSensorKey } = sensorApi;

export const sensorsAdapter = createEntityAdapter({
  selectId: (sensor) => getSensorKey({ type: sensor.type, identifier: sensor.identifier }),
});

// sensor selectors

// To fix the page breaking issue related to sensors data - FF-1079
const selectSensorsState = (state) => {
  const sensorObj = { ...state.sensorsData.sensors };
  sensorObj.ids = sensorObj.ids.filter((d) => d);
  return sensorObj;
};

// This will be removed/uncommented in future
// const selectSensorsState = (state) => state.sensorsData.sensors;

const sensorsSelectors = sensorsAdapter.getSelectors(selectSensorsState);
const selectAllSensors = sensorsSelectors.selectAll;

const selectSensorsForRanchId = (state, ranchId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.ranch === ranchId;
  })
);

const selectSensorsByRanchId = (state, ranchId) => sensorsSelectors
  .selectAll(state)
  .filter((sensor) => sensor.ranch === ranchId);

const selectSensorsForIrrigationBlockId = (state, blockId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.irrigation_block === blockId;
  })
);

const selectSensorsForBlockId = (state, blockId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.block === blockId;
  })
);

// if blockIds are not passed, this method does not filter on blockIds and returns all
const selectAllSensorsForBlockIdsByType = (state, { blockIds, installState }) => (
  sensorsSelectors.selectAll(state).reduce((sensorData, sensor) => {
    if (sensor) {
      const checkBlockIds = blockIds ? blockIds.includes(sensor.block) : true;
      const checkInstallState = installState ? (installState === sensor.install_state) : true;

      if (checkBlockIds && checkInstallState) {
        if (sensorData[sensor.type]) {
          sensorData[sensor.type].push(sensor);
        } else {
          sensorData[sensor.type] = [sensor];
        }
      }
    }
    return sensorData;
  }, {})
);

const selectSensorsForGatewayId = (state, gatewayId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.gateway === gatewayId;
  })
);

const selectSensor = (state, type, identifier) => sensorsSelectors.selectById(
  state,
  getSensorKey({ type, identifier }),
);

const selectSensorById = (state, type, id) => {
  const sensorsState = selectSensorsState(state);
  const sensorsArray = Object.values(sensorsState.entities);
  return sensorsArray.find((sensor) => sensor.type === type && sensor.id === id);
};

/*
 * This method accepts sensor params of type and either id or identifier
 * It will try to resolve a sensor from the params
 * If no sensor is found, it returns the params
 * It prefers identifier if available
 */
const selectSensorByParams = (state, sensorParams) => {
  if (!sensorParams) return undefined;
  const { identifier, id, type } = sensorParams;

  if (type !== undefined && identifier !== undefined) {
    return selectSensor(state, type, identifier) || sensorParams;
  }

  if (type !== undefined && id !== undefined) {
    return selectSensorById(state, type, id) || sensorParams;
  }

  return sensorParams;
};

const selectLoadingSensors = (state) => selectLoadingState(selectSensorsState(state));

// capabilities

const selectCapabilitiesState = (state) => state.sensorsData.capabilities;
const capabilitiesSelectors = sensorsAdapter.getSelectors(selectCapabilitiesState);
const selectSensorCapabilities = (state, type, identifier) => {
  const capabilities = capabilitiesSelectors.selectById(
    state,
    getSensorKey({ type, identifier }),
  );

  if (capabilities) {
    return capabilities.items || [];
  }
  return [];
};
const selectLoadingCapabilities = (state) => selectLoadingState(selectCapabilitiesState(state));

// status

const selectStatusState = (state) => state.sensorsData.status;
const statusSelectors = sensorsAdapter.getSelectors(selectStatusState);
const selectSensorStatus = (state, type, identifier) => statusSelectors.selectById(
  state,
  getSensorKey({ type, identifier }),
);

const selectValveStatusById = (state, id) => (
  statusSelectors.selectAll(state)
    .find((sensor) => sensor.type === 'valve' && sensor.id === id)
);

/**
 * This selectors accepts valveIds and returns the details.
 * The details will contain data and status for every valve.
 * Details also include pressure data.
 * If passed sorted param as true, the output will be sorted
 * by valve name.
 */
const selectDetailsForValveIds = (state, ids = [], pressureSensors, sorted = false) => {
  if (ids.length) {
    const sensorsState = selectSensorsState(state);
    const sensorsArray = Object.values(sensorsState.entities);
    const pressureSensorsList = (pressureSensors && pressureSensors.pressureSensorsList) || [];
    // fetch data
    const filteredData = sensorsArray.filter((sensor) => ids.includes(sensor.id));

    // fetch status and create details array
    const details = filteredData.reduce((acc, curr) => {
      const status = selectSensorStatus(state, 'valve', curr.identifier);
      const pressureData = pressureSensorsList.find((item) => (
        item.sensorId === curr.pressure_sensor
      )) || {};
      pressureData.pressureCutoff = (pressureSensors && pressureSensors.pressureCutoff) || [];
      acc.push({ ...curr, ...status, pressureData });
      return acc;
    }, []);

    if (sorted) {
      return details.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }
    return details;
  }
  return [];
};

const selectLoadingStatus = (state) => selectLoadingState(selectStatusState(state));

const selectAllSensorStatusByType = (state, type) => (
  statusSelectors.selectAll(state)
    .filter((sensor) => sensor.type === type)
);

const selectAllSensorsStatus = statusSelectors.selectEntities;

const selectIRSensorSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const aggregatedData = list.reduce((acc, curr) => {
      const status = state[`${curr.type}/${curr.identifier}`];
      if (status) {
        const { totalOnline } = acc;
        const onlineCount = status.online ? totalOnline + 1 : totalOnline;

        if (isNotNull(status.cwsi)) acc.cwsiArr.push(status.cwsi);
        if (status.cropStress) acc.cropStressSet.add(status.cropStress);
        if (curr.name) acc.stationNames.push(curr.name);

        return {
          ...acc,
          totalOnline: onlineCount,
          lastUpdated: status.lastUpdated,
        };
      }
      return acc;
    }, {
      cwsiArr: [],
      totalOnline: 0,
      cropStressSet: new Set(),
      lastUpdated: '',
      stationNames: [],
    });

    return {
      cwsiVal: mean(aggregatedData.cwsiArr).toFixed(1),
      ...aggregatedData,
    };
  },
);

const selectSoilSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const summaryObj = list.reduce((acc, curr) => {
      let { onlineCount } = acc;
      const item = state[`${curr.type}/${curr.identifier}`];
      if (!item) {
        return acc;
      }

      if (curr.name) {
        acc.names.push(curr.name);
      }
      let depthSize = 1;
      if (isNotNull(item.depth1) && isNotNull(item.depth2)) {
        depthSize = item.depth2 - item.depth1;
      }
      const { percent, volume } = getPercentAndVolume(
        depthSize,
        item.vwcRootzone,
        item.wiltingPointRootzone,
        item.fieldCapacityRootzone,
      );
      acc.percentArr.push(percent);
      acc.volumeArr.push(volume);

      if (isNotNull(item.vwcRootzone)) {
        acc.vwcRootzoneList.push(item.vwcRootzone);
      }
      if (isNotNull(item.vwc1)) {
        acc.vwcArr.push(...[item.vwc1, item.vwc2, item.vwc3, item.vwc4, item.vwc5, item.vwc6]);
      }
      if (isNotNull(item.units)) {
        acc.units = item.units;
      }
      if (item.online) {
        onlineCount += 1;
      }

      return {
        ...acc,
        onlineCount,
      };
    }, {
      names: [],
      vwcRootzoneList: [],
      vwcArr: [],
      onlineCount: 0,
      units: {},
      percentArr: [],
      volumeArr: [],
    });

    /**
     * If summaryObj.vwcArr is empty, passing an array with Zero value makes sure that
     * the min and the max doesn't appear as Infinity/-Infinity
     *
     * And filter out the array to remove non-number values
     */
    const vwcArr = summaryObj.vwcArr.length ? summaryObj.vwcArr.filter((item) => (
      typeof item === 'number'
    )) : [0];
    const min = parseFloat((Math.min(...vwcArr) * 100).toFixed(1));
    const max = parseFloat((Math.max(...vwcArr) * 100).toFixed(1));

    return {
      onlineCount: summaryObj.onlineCount,
      names: summaryObj.names,
      units: summaryObj.units,
      min: Number.isNaN(min) ? 0 : min,
      max: Number.isNaN(max) ? 0 : max,
      vwcRootzone: mean(summaryObj.vwcRootzoneList),
      percentAvailable: mean(summaryObj.percentArr) * 100,
      volumeAvailable: parseFloat(mean(summaryObj.volumeArr).toFixed(1)),
    };
  },
);

const selectPlantSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const aggregatedData = list.reduce((acc, curr) => {
      const status = state[`${curr.type}/${curr.identifier}`];
      if (status) {
        const { totalOnline } = acc;
        const onlineCount = status.online ? totalOnline + 1 : totalOnline;

        if (isNotNull(status.twdMin) && isNotNull(status.twdMax)) {
          acc.twdArr.push(mean([status.twdMin, status.twdMax]));
        }
        if (status.cropStress) acc.cropStressSet.add(status.cropStress);
        if (curr.name) acc.stationNames.push(curr.name);

        return {
          ...acc,
          totalOnline: onlineCount,
          lastUpdated: status.lastUpdated,
        };
      }
      return acc;
    }, {
      twdArr: [],
      totalOnline: 0,
      cropStressSet: new Set(),
      lastUpdated: '',
      stationNames: [],
    });

    return {
      ...aggregatedData,
      twdAvg: parseFloat(mean(aggregatedData.twdArr).toFixed(1)),
    };
  },
);

const selectWeatherSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const aggregatedData = list.reduce((acc, curr) => {
      const status = state[`${curr.type}/${curr.identifier}`];

      if (!status) return acc;

      const { totalOnline } = acc;
      const onlineCount = status.online ? (totalOnline + 1) : totalOnline;
      if (status.weatherCurrent) acc.currentWeatherList.push(status.weatherCurrent);
      if (curr.name) acc.stationNames.push(curr.name);

      return {
        ...acc,
        totalOnline: onlineCount,
      };
    }, {
      currentWeatherList: [],
      totalOnline: 0,
      stationNames: [],
    });

    const { currentWeatherList, totalOnline, stationNames } = aggregatedData;

    return {
      airTemperature: getAverageBy(currentWeatherList, 'airTemperature'),
      precipitation: getAverageBy(currentWeatherList, 'precipitation'),
      windSpeed: getAverageBy(currentWeatherList, 'windSpeed'),
      totalOnline,
      stationNames,
    };
  },
);

const selectFrostSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const summaryObj = list.reduce((acc, curr) => {
      let { totalOnline } = acc;
      const item = state[`${curr.type}/${curr.identifier}`];

      if (!item) return acc;
      if (item.units) acc.status = item;
      if (curr.name) acc.names.push(curr.name);
      if (isNotNull(item.temperatureBud)) acc.temperatureBudArr.push(item.temperatureBud);
      if (isNotNull(item.temperatureLeaf)) acc.temperatureLeafArr.push(item.temperatureLeaf);
      totalOnline = item.online ? (totalOnline + 1) : totalOnline;

      return {
        ...acc,
        totalOnline,
      };
    }, {
      temperatureBudArr: [],
      temperatureLeafArr: [],
      status: {},
      names: [],
      totalOnline: 0,
    });

    return {
      status: summaryObj.status,
      names: summaryObj.names,
      totalOnline: summaryObj.totalOnline,
      temperatureBud: parseFloat(mean(summaryObj.temperatureBudArr).toFixed(1)),
      temperatureLeaf: parseFloat(mean(summaryObj.temperatureLeafArr).toFixed(1)),
    };
  },
);

const selectValveSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => list.reduce((acc, curr) => {
    let { openValveCount, totalOnline } = acc;
    const item = state[`${curr.type}/${curr.identifier}`];
    if (!item) return acc;

    const isOpen = item.controlStatus && item.controlStatus.state === 'open';
    openValveCount = isOpen ? (openValveCount + 1) : openValveCount;
    totalOnline = item.online ? (totalOnline + 1) : totalOnline;

    if (curr.name) {
      acc.allValvesName.push(curr.name);
      if (isOpen) {
        acc.openValvesName.push(curr.name);
      } else {
        acc.closedValvesName.push(curr.name);
      }
    }

    return {
      ...acc,
      openValveCount,
      totalOnline,
    };
  }, {
    openValveCount: 0,
    openValvesName: [],
    closedValvesName: [],
    allValvesName: [],
    totalOnline: 0,
  }),
);

const selectFlowSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const summaryObj = list.reduce((acc, curr) => {
      let { status, totalOnline } = acc;
      const item = state[`${curr.type}/${curr.identifier}`];
      if (!item) return acc;
      totalOnline = item.online ? (totalOnline + 1) : totalOnline;

      if (isNotNull(item.flowRate)) {
        status = item;
        acc.flowRateArr.push(item.flowRate);
      }
      if (curr.name) {
        acc.names.push(curr.name);
      }

      return {
        ...acc,
        status,
        totalOnline,
      };
    }, {
      names: [],
      status: {},
      flowRateArr: [],
      totalOnline: 0,
    });

    return {
      ...summaryObj,
      flowRate: parseFloat(mean(summaryObj.flowRateArr).toFixed(1)),
    };
  },
);

const selectPumpSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => list.reduce((acc, curr) => {
    let { openPumpsCount, totalOnline } = acc;

    const item = state[`${curr.type}/${curr.identifier}`];

    if (!item) return acc;

    const isOpen = item.controlStatus && item.controlStatus.state === 'on';
    openPumpsCount = isOpen ? (openPumpsCount + 1) : openPumpsCount;
    totalOnline = item.online ? (totalOnline + 1) : totalOnline;

    if (curr.name) {
      acc.allPumpsName.push(curr.name);
      if (isOpen) {
        acc.openPumpsName.push(curr.name);
      } else {
        acc.closePumpsName.push(curr.name);
      }
    }

    return {
      ...acc,
      openPumpsCount,
      totalOnline,
    };
  }, {
    openPumpsCount: 0,
    openPumpsName: [],
    closePumpsName: [],
    allPumpsName: [],
    totalOnline: 0,
  }),
);

const selectPressureSummary = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => {
    const summaryObj = list.reduce((acc, curr) => {
      let {
        onlineCount, irrigatingCount, totalCount,
      } = acc;
      const item = state[`${curr.type}/${curr.identifier}`];
      if (!item) {
        return acc;
      }

      totalCount += 1;

      if (curr.name) {
        acc.names.push(curr.name);
      }
      if (isNotNull(item.waterPressure)) acc.waterPressureArr.push(item.waterPressure);
      if (isNotNull(item.waterPressure) && isNotNull(item.cutoffPressure)) {
        if (item.waterPressure > item.cutoffPressure) {
          irrigatingCount += 1;
        }
      }
      if (item.online) {
        onlineCount += 1;
      }

      return {
        ...acc,
        onlineCount,
        irrigatingCount,
        totalCount,
      };
    }, {
      status: {},
      names: [],
      waterPressureArr: [],
      onlineCount: 0,
      irrigatingCount: 0,
      totalCount: 0,
    });

    return {
      ...summaryObj,
      waterPressure: parseFloat(mean(summaryObj.waterPressureArr).toFixed(2)),
    };
  },
);

const selectSensorLoadingStatus = createSelector(
  [
    selectAllSensorsStatus,
    (state, list) => list,
  ],
  (state, list) => list.some((i) => {
    const status = state[`${i.type}/${i.identifier}`];

    if (status) {
      return status.loading;
    }
    return false;
  }),
);

// if blockIds are not passed, this method does not filter on blockIds and returns all
const selectAllSensorStatusForBlockIdsByType = (state, blockIds) => (
  statusSelectors.selectAll(state).reduce((statusData, status) => {
    if (status) {
      const checkBlockIds = blockIds ? blockIds.includes(status.block) : true;
      if (checkBlockIds) {
        if (statusData[status.type]) {
          statusData[status.type].push(status);
        } else {
          statusData[status.type] = [status];
        }
      }
    }
    return statusData;
  }, {})
);

const populateSensorStatus = (state, sensors) => sensors.map((sensor) => (
  {
    ...sensor,
    status: selectSensorStatus(state, sensor.type, sensor.identifier),
  }
));

// sensor stats selectors

const selectSensorStatsState = (state) => state.sensorsData.stats.sensor;
const selectCavalierStatsState = (state) => state.sensorsData.stats.cavalier;
const selectGatewayStatsState = (state) => state.sensorsData.stats.gateway;

const sensorStatsSelectors = sensorsAdapter.getSelectors(selectSensorStatsState);
const cavalierStatsSelectors = sensorsAdapter.getSelectors(selectCavalierStatsState);
const gatewayStatsSelectors = sensorsAdapter.getSelectors(selectGatewayStatsState);

const selectSensorStats = (state, type, identifier) => {
  let selector = sensorStatsSelectors;
  if (type === 'gateway') selector = gatewayStatsSelectors;
  if (type === 'cavalier') selector = cavalierStatsSelectors;
  return selector.selectById(
    state,
    getSensorKey({ type, identifier }),
  );
};

const selectSensorErrors = (state, type, identifier) => {
  if (type !== 'cavalier') return null;
  const stats = selectSensorStats(state, type, identifier);
  return stats && stats.errors;
};

const selectAllSensorStats = sensorStatsSelectors.selectAll;
const selectLoadingSensorStats = (state) => selectLoadingState(selectSensorStatsState(state));

const selectAllCavalierStats = cavalierStatsSelectors.selectAll;
const selectLoadingCavalierStats = (state) => selectLoadingState(selectCavalierStatsState(state));

const selectAllGatewayStats = gatewayStatsSelectors.selectAll;
const selectLoadingGatewayStats = (state) => selectLoadingState(selectGatewayStatsState(state));

// sensor note / image selectors

const selectSensorNotesState = (state) => state.sensorsData.notes;
const sensorNotesSelectors = sensorNotesAdapter.getSelectors(selectSensorNotesState);
const selectAllSensorNotes = sensorNotesSelectors.selectAll;
const selectLoadingSensorNotes = (state) => selectLoadingState(selectSensorNotesState(state));

const selectSensorImagesState = (state) => state.sensorsData.images;
const sensorImagesSelectors = sensorImagesAdapter.getSelectors(selectSensorImagesState);
const selectAllSensorImages = sensorImagesSelectors.selectAll;
const selectLoadingSensorImages = (state) => selectLoadingState(selectSensorImagesState(state));

// sensor relationships

const selectPumpFlowMeter = (state, type, identifier) => {
  const pumpController = selectSensor(state, type, identifier);
  if (!pumpController) return undefined;
  const { gateway: gatewayId } = pumpController;
  if (!gatewayId) return undefined;
  const flowMeters = selectSensorsForGatewayId(state, gatewayId, 'water_flow_analog', 'installed');
  if (!flowMeters || !flowMeters.length) return null;
  return populateSensorStatus(state, [flowMeters[0]])[0];
};

const selectGatewayIsControlEnabled = (state, gatewayId) => {
  const vfds = selectSensorsForGatewayId(state, gatewayId, 'vfd');
  if (vfds && vfds.length) return true;
  const valves = selectSensorsForGatewayId(state, gatewayId, 'valve');
  if (valves && valves.length) return true;
  return false;
};

const selectControlGatewaysForRanchId = (state, ranchId) => {
  const gateways = selectSensorsForRanchId(state, ranchId, 'gateway', 'installed');
  return gateways.filter((gateway) => selectGatewayIsControlEnabled(state, gateway.id));
};

const selectSensorsStatus = (state, sensors) => {
  const status = sensors.map((sensor) => selectSensorStatus(state, {
    type: sensor.sensor_type,
    identifier: sensor.identifier,
  }));
  return status;
};

const selectWeatherStatusFirstRecordForBlockIds = (state, { blockIds, installState }) => {
  try {
    const { weather_station: weatherList } = selectAllSensorsForBlockIdsByType(state, {
      blockIds,
      installState: installState.INSTALLED,
    });

    if (weatherList) {
      const allSensors = selectAllSensorsStatus(state);
      if (isNotNull(allSensors)) {
        const record = weatherList.find((item) => {
          const status = allSensors[`${item.type}/${item.identifier}`];
          return isNotNull(status) && isNotNull(status.weatherCurrent);
        });
        if (isNotNull(record)) {
          return {
            status: allSensors[`${record.type}/${record.identifier}`],
            isLoading: selectSensorLoadingStatus(state, weatherList),
          };
        }
      }
    }
    return { status: null, isLoading: false };
  } catch {
    return { status: null, isLoading: false };
  }
};

/**
 * Returns list of weather sensors and weather status for one of the sensor
 * which has proper data.
 * @param {*} state
 * @param {Array} blockIds
 * @returns {*}
 */
const selectWeatherDataAndList = (state, blockIds) => {
  const sensors = selectAllSensorsForBlockIdsByType(state, {
    blockIds,
    installState: 'installed',
  });

  const list = sensors.weather_station || [];
  const item = list.find((i) => {
    const status = selectSensorStatus(state, i.type, i.identifier) || {};

    /**
     * This complexity can be reduced, if we can guarantee the api returns
     * data with weatherForecast and weatherHistory as not null/undefined.
     *
     * The additional check of status.weatherHistory[0].airTemperatureMin
     * Issue where weather history was not showing up, upon debugging found that the above
     * value was set to null for all weatherHistory items.
     */
    const weatherForecast = status.weatherForecast && status.weatherForecast[0];
    const weatherHistory = status.weatherHistory && status.weatherHistory[0];
    const airTemperatureMin = weatherHistory && weatherHistory.airTemperatureMin;
    return weatherForecast && airTemperatureMin;
  });

  return {
    list,
    weatherData: item ? selectSensorStatus(state, item.type, item.identifier) : {},
  };
};

const selectSensorsForBlockIds = (state, blockIds = [], type) => {
  const sensors = blockIds.map((blockId) => {
    const obj = {};
    const sensorsForType = selectSensorsForBlockId(state,
      blockId, type, 'installed');
    obj[blockId] = sensorsForType;
    return obj;
  }).reduce((acc, obj) => {
    const key = Object.keys(obj)[0];
    acc[key] = obj[key];
    return acc;
  }, {});

  return sensors;
};

export {
  selectSensorsState,
  selectAllSensors,
  selectSensorsForIrrigationBlockId,
  selectSensorsForRanchId,
  selectSensorsByRanchId,
  selectSensor,
  selectSensorById,
  selectAllSensorsForBlockIdsByType,
  selectAllSensorStatusByType,
  selectSensorsForBlockId,
  selectAllSensorStatusForBlockIdsByType,
  selectSensorByParams,
  selectPumpFlowMeter,
  selectLoadingSensors,
  selectSensorsForGatewayId,
  selectGatewayIsControlEnabled,
  selectControlGatewaysForRanchId,
  selectSensorLoadingStatus,
  // capabilities
  selectSensorCapabilities,
  selectLoadingCapabilities,
  // status
  selectSensorStatus,
  selectLoadingStatus,
  populateSensorStatus,
  selectSensorsStatus,
  selectValveStatusById,
  selectDetailsForValveIds,
  selectWeatherStatusFirstRecordForBlockIds,
  // stats
  selectSensorStats,
  selectAllSensorStats,
  selectLoadingSensorStats,
  selectAllCavalierStats,
  selectLoadingCavalierStats,
  selectAllGatewayStats,
  selectLoadingGatewayStats,
  selectSensorErrors,
  // notes
  selectAllSensorNotes,
  selectLoadingSensorNotes,
  // images
  selectAllSensorImages,
  selectLoadingSensorImages,
  // summary
  selectFrostSummary,
  selectPumpSummary,
  selectIRSensorSummary,
  selectSoilSummary,
  selectPlantSummary,
  selectWeatherSummary,
  selectValveSummary,
  selectFlowSummary,
  selectPressureSummary,
  selectWeatherDataAndList,
  selectSensorsForBlockIds,
};
