import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import BarHighchart from './BarHighchart';
import styles from './BarChart.css';

// BarChart component will receive the chartData, xCategories, onchange
// onChange: callback function to return 'yAxis' value when drag the horizontal line
// sample data:
// chartData: [1, 2, 5] (OR) [{y: 1, color: 'red'}, {y: 3, color: 'blue'}]
// xCategories: ['Jan', 'Feb'] || [10, 20, 30] || ['2021', '2022'] || ['2022/01/02', '2022/01/05']
// Render from web-ui/example/App.js
// import { BarChart } from 'farmx-web-ui';
// return (<BarChart chartData={[10,4,3]} xCategories={['Jan', 'Feb', 'Mar']} />)
function BarChart(props) {
  const { chartData, xCategories, onChange } = props;
  const chartRef = useRef(null);
  const [options, setOptions] = useState(null);
  const [newData, setNewData] = useState(chartData);

  const handleOnChange = useCallback((d) => {
    if (onChange) onChange(d);

    const changedData = newData.map((e) => {
      let obj = {};
      if (typeof e === 'object' && e.y <= d) {
        obj = e;
        obj.color = 'pink';
        return obj;
      } if (e.y > d) {
        obj = e;
        obj.color = '#7cb5ec';
        return obj;
      }
      obj = { y: e, color: e <= d ? 'pink' : '#7cb5ec' };

      return obj;
    });

    setNewData(changedData);
  }, [newData, onChange]);

  const chartConfig = useCallback(
    () => ({
      title: 'Bar Chart With Draggable Line',
      chart: {
        type: 'column',
      },
      legend: { enabled: false },
      xAxis: {
        categories: xCategories,
      },
      yAxis: {
        plotLines: [
          {
            id: 'y2',
            color: 'skyblue',
            width: 2,
            value: 1,
            zIndex: 5,
            dashStyle: 'dash',
            label: {
              align: 'right',
              y: -12,
              x: 7,
              useHTML: true,
              text: `<svg class=${styles.icon}>
              <path style=" stroke:none;fill-rule:nonzero;
              fill:rgb(29.019608%,16.470588%,78.039216%);fill-opacity:1;" 
              d="M 9.179688 49.894531 L 9.179688 0.09375 L 41.976562 0.09375 L 41.976562 
              49.894531 Z M 9.179688 49.894531 "/>
              <path style=" stroke:none;fill-rule:evenodd;
              fill:rgb(100%,100%,100%);fill-opacity:1;" 
              d="M 33.53125 20.722656 L 17.359375 20.722656 L 25.445312 6.636719 Z M 
              33.53125 20.722656 "/>
              <path style=" stroke:none;fill-rule:evenodd;
              fill:rgb(100%,100%,100%);fill-opacity:1;" 
              d="M 33.433594 29.132812 L 17.722656 29.132812 L 25.578125 42.683594 Z M 
              33.433594 29.132812 "/>
              <path style=" stroke:none;fill-rule:nonzero;
              fill:rgb(100%,100%,100%);fill-opacity:1;" 
              d="M 15.355469 25.492188 L 35.800781 25.492188 L 35.800781 24.507812 L 
              15.355469 24.507812 Z M 15.355469 25.492188 "/>
              </svg>`,
            },
            onDragStart() {
              return null;
            },
            onDragChange() {
              return null;
            },
            onDragFinish(newValue) {
              handleOnChange(newValue);
            },
          },
        ],
      },
      plotOptions: {
        series: {
          borderRadius: 8,
        },
      },
      series: [
        {
          showInLegend: 'false',
          data: newData,
        },
      ],
    }),
    [newData, handleOnChange, xCategories],
  );

  function getChartElem(chartElem) {
    chartRef.current = chartElem;
  }

  useEffect(() => {
    if (chartRef.current) {
      const { chart } = chartRef.current;
      if (chart) {
        chart.update({
          series: [
            {
              data: newData,
            },
          ],
          xAxis: {
            categories: xCategories,
          },
        });
      }
    }
  }, [newData, xCategories]);

  useEffect(() => {
    const config = chartConfig();
    if (config && !options) {
      setOptions(config);
    }
  }, [options, chartConfig]);

  return (
    <BarHighchart
      options={options}
      chartElem={getChartElem}
    />
  );
}

BarChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  xCategories: PropTypes.arrayOf(PropTypes.any),
};

BarChart.defaultProps = {
  chartData: null,
  onChange: null,
  xCategories: null,
};

export default BarChart;
