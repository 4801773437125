import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { controlApi } from 'farmx-api';
import {
  initialState,
  requestItemById,
  receiveItem,
  requestItemByIdFailed,
  loadDetail,
} from '../../helpers';

const {
  fetchBlockSchedule,
  fetchAllBlockSchedule,
} = controlApi;

export const scheduleAdapter = createEntityAdapter();

const name = 'schedule';

export const loadBlockSchedule = createAsyncThunk(
  `${name}/loadBlockSchedule`,
  loadDetail({
    getData: fetchBlockSchedule,
    getRequestState: (id, state) => state.controlData.schedule.entities[id],
  }),
);

export const loadAllBlockSchedule = createAsyncThunk(
  `${name}/loadAllBlockSchedule`,
  async (blockIds) => fetchAllBlockSchedule(blockIds),
);

const scheduleSlice = createSlice({
  name,
  initialState: scheduleAdapter.getInitialState(initialState),
  reducers: {
    cleanListRequestState(state) {
      state.loading = false;
      state.currentRequestId = undefined;
      state.error = null;
    },
  },
  extraReducers: {
    [loadBlockSchedule.pending]: requestItemById(scheduleAdapter),
    [loadBlockSchedule.fulfilled]: receiveItem(scheduleAdapter),
    [loadBlockSchedule.rejected]: requestItemByIdFailed(scheduleAdapter),

    [loadAllBlockSchedule.pending]: (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.error = undefined;
        state.currentRequestId = action.meta.requestId;
      }
    },

    [loadAllBlockSchedule.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === true && state.currentRequestId === requestId) {
        const lastUpdated = Date.now();
        state.lastUpdated = lastUpdated;
        state.loading = false;
        state.currentRequestId = undefined;
        state.error = undefined;
        if (action.payload instanceof Array) {
          scheduleAdapter.setAll(state, action.payload);
        }
      }
    },

    [loadAllBlockSchedule.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === true && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export { scheduleSlice };
export default scheduleSlice.reducer;
