import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const getColorForValue = (value) => {
  if (value) return 'green';
  return 'red';
};

function BooleanTag({ value, labels }) {
  if (value === undefined || value === null) return null;
  const [labelTrue, labelFalse] = labels;
  return (
    <Tag color={getColorForValue(value)}>
      { value ? labelTrue : labelFalse }
    </Tag>
  );
}

BooleanTag.propTypes = {
  value: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
};

BooleanTag.defaultProps = {
  value: undefined,
  labels: ['yes', 'no'],
};

export default BooleanTag;
