/*
 * A flat slice is for simple data keyed by a field 'id'
 */

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  initialState,
  requestList,
  receiveListCheckDate,
  requestListFailed,
  requestItemById,
  receiveItemCheckDate,
  requestItemByIdFailed,
  loadList,
  loadDetail,
} from '../helpers';

const entityAdapter = createEntityAdapter();

const createFlatSlice = (name, getList, getDetail, getRequestState) => {
  const loadFlatList = createAsyncThunk(
    `${name}/loadList`,
    loadList({
      getData: getList,
      getRequestState,
    }),
  );

  const loadSingle = createAsyncThunk(
    `${name}/loadSingle`,
    loadDetail({
      getData: getDetail,
      getRequestState: (id, state) => {
        const { entities } = getRequestState(state);
        return entities[id];
      },
    }),
  );

  const slice = createSlice({
    name,
    initialState: entityAdapter.getInitialState(initialState),
    reducers: {},
    extraReducers: {
      [loadFlatList.pending]: requestList,
      [loadFlatList.fulfilled]: receiveListCheckDate(entityAdapter),
      [loadFlatList.rejected]: requestListFailed,
      [loadSingle.pending]: requestItemById(entityAdapter),
      [loadSingle.fulfilled]: receiveItemCheckDate(entityAdapter),
      [loadSingle.rejected]: requestItemByIdFailed(entityAdapter),
    },
  });

  return {
    loadFlatList,
    loadSingle,
    entityAdapter,
    slice,
  };
};

export default createFlatSlice;
