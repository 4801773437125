import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  FaSignOutAlt,
  FaCog,
} from 'react-icons/fa';

import './UserMenu.less';

function UserMenu(props) {
  const { userInfo } = props;
  const { t } = useTranslation();
  if (!userInfo) return null;
  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item>
            <Link to="/settings">
              <FaCog />
              <span>{t('Settings')}</span>
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <Link to="/logout">
              <FaSignOutAlt />
              <span>{t('Logout')}</span>
            </Link>
          </Menu.Item>
        </Menu>
    )}
    >
      { userInfo
        ? (
          <Button type="link" className="ant-dropdown-link user-menu">
            <span className="user-title">{ userInfo.username }</span>
            <UserOutlined />
          </Button>
        )
        : <Button />}
    </Dropdown>
  );
}

UserMenu.propTypes = {
  userInfo: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }),
};

UserMenu.defaultProps = {
  userInfo: null,
};

export default UserMenu;
