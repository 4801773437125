import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSelectedRanch, selectUrlLoad, selectSelectedBlocks, selectSelectedBlock,
} from '../selectors';
import {
  setBlocks, setRanch, setUrlLoad, setRanchMobile, setBlockMobile,
} from '../actions';

const isMobile = window.innerWidth < 599;

// Set URL param from state
export function useSetURLFromState(urlSearch, search, pathname, scheduleInputData, locationState) {
  /**
   * Funtion to create param string
   * @param {*} blocks - Input blocks from state
   * @returns param string
   */
  function createParamStr(blocks) {
    const startDate = scheduleInputData.dateRange.length ? scheduleInputData.dateRange[0] : null;
    const endDate = scheduleInputData.dateRange.length ? scheduleInputData.dateRange[1] : null;
    const params = {};

    // create sensor params
    if (blocks && blocks.length) {
      params.blockId = blocks.join(',');
    }

    if (scheduleInputData.irrigationTypes) params.irrigationTypes = scheduleInputData.irrigationTypes.join(',');
    if (startDate) params.startDate = startDate.toISOString();
    if (endDate) params.endDate = endDate.toISOString();
    if (scheduleInputData.radioOption) params.radioOption = scheduleInputData.radioOption;

    let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (paramStr.length) paramStr = `?${paramStr}`;
    return paramStr;
  }
  const ranchId = useSelector(selectSelectedRanch).payload;
  const blockIds = useSelector(selectSelectedBlocks).payload || [];
  const blockIdMobile = useSelector(selectSelectedBlock).payload || [];
  const history = useHistory();
  let paramStr;
  if (scheduleInputData) {
    paramStr = createParamStr(blockIds);
  }
  useEffect(() => {
    if (!isMobile) {
      // If not mobile screen and ranchId exist in state
      if (ranchId && ranchId.length && (pathname.includes('control')
      || pathname.includes('dashboard')) && (!urlSearch.current.includes(ranchId[0])
      || !search.includes(ranchId[0]))) {
        history.push({ search: `?ranchId=${ranchId[0]}` });
        urlSearch.current = `?ranchId=${ranchId[0]}`;
      }
      // Handle the schedule page URL update logic
      if (blockIds && scheduleInputData && pathname.includes('schedule') && !urlSearch.current.includes('ranchId')
        && (!urlSearch.current || search !== paramStr)) {
        history.replace({ pathname, search: paramStr });
        urlSearch.current = paramStr;
      }
    } else if (isMobile && ranchId && ranchId.length && blockIdMobile && !blockIdMobile.length
        && !urlSearch.current.includes(ranchId[0])) {
      // If mobile screen and only ranchId exist in state
      history.push({ search: `?ranchId=${ranchId[0]}`, state: locationState });
      urlSearch.current = `?ranchId=${ranchId[0]}`;
    } else if (isMobile && ranchId && !ranchId.length && blockIdMobile && blockIdMobile.length
        && !urlSearch.current.includes(blockIdMobile[0])) {
      // If mobile screen and only blockId exist in state
      history.push({ search: `?blockId=${blockIdMobile[0]}`, state: locationState });
      urlSearch.current = `?blockId=${blockIdMobile[0]}`;
    }
  }, [blockIds, scheduleInputData, history, paramStr, pathname, ranchId, search, urlSearch,
    locationState, blockIdMobile]);
}

// Reading URL param and set to state
export function useSetStateFromURL(urlSearch) {
  const ranchId = useSelector(selectSelectedRanch).payload || [];
  const blockIds = useSelector(selectSelectedBlocks).payload || [];
  const urlLoad = useSelector(selectUrlLoad).payload;
  const dispatch = useDispatch();
  if (urlSearch.current) {
    const params = new URLSearchParams(urlSearch.current);
    const ranchIdParam = params.get('ranchId');
    const blockIdParam = params.get('blockId');
    let blockIdsFromURL;
    if (blockIdParam) {
      blockIdsFromURL = blockIdParam.split(',').filter((d) => d.length > 0).map((d) => Number(d));
    }

    // If not mobile screen
    if (!isMobile) {
      // Only for control page and ranchId param exist in the URL
      // Set ranchId to state only if not already exist
      if (urlLoad && ranchIdParam && ranchId && Number(ranchIdParam)
      && !urlSearch.current.includes(ranchId[0]) && !urlSearch.current.includes('blockId')) {
        dispatch(setRanch(Number(ranchIdParam)));
        dispatch(setUrlLoad(false));
      }
      // Only for schedule page and blockId param exist in the URL
      // Set blockIds to state only if not already exist
      if (urlLoad && (blockIdsFromURL || (blockIds && blockIds.length))
      && !urlSearch.current.includes(`blockId=${blockIds.join(',')}&`)
      && !urlSearch.current.includes('ranchId')) {
        dispatch(setBlocks(blockIdsFromURL));
        dispatch(setUrlLoad(false));
      }
    } else if (isMobile && urlLoad && ranchIdParam && Number(ranchIdParam) && !blockIdParam) {
      // If mobile screen and only ranchId exist in URL
      dispatch(setRanchMobile(Number(ranchIdParam)));
      dispatch(setUrlLoad(false));
    } else if (isMobile && urlLoad && blockIdParam && Number(blockIdParam)) {
      // If mobile screen and blockId exist in URL
      dispatch(setBlockMobile([Number(blockIdParam)]));
      dispatch(setUrlLoad(false));
    }
  }
}
