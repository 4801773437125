import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import { Tag, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ItemSelect from '../ItemSelect';
import SensorOption from './SensorOption';
import FilterListItem, { OP } from './FilterListItem';
import styles from './SensorSelect.less';
import SensorSelectContext from './SensorSelectContext';
import Searchbox from './SearchBox';
import calculatedHeightForMobile from '../helper';

const { Option } = Select;

const { loadAllSensors, loadFarmData } = actions;
const {
  selectAllSensors,
  selectLoadingSensors,
  selectSensor,
  selectSensorById,
} = selectors;

const sensorTagRender = (args) => {
  const { closable, onClose, label } = args;
  return (
    <Tag
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label.props ? label.props.sensor.name : label}
    </Tag>
  );
};

const defaultItemRenderer = (d) => {
  const valueObject = JSON.stringify({ type: d.type, identifier: d.identifier, id: d.id });
  if (!d.count) {
    return (
      <Option
        key={`${d.identifier} ${d.name}`}
        value={valueObject}
        label={d.name ? d.name : 'No Name'}
        data-testid={`${d.name}`}
      >
        <SensorOption sensor={d} />
      </Option>
    );
  } if (d.count) {
    return (
      <Option key={`${d.name}`} label={d.name} data-testid={`${d.name}`} disabled>
        <div style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)' }}>{d.name}</div>
      </Option>
    );
  }
  return null;
};

const SensorSelect = ({
  loadItems,
  items,
  mode,
  renderItem,
  onSelect,
  onChange,
  tagRender,
  placeholder,
  defaultOption,
  dropdownStyle,
  sensorTypeConstraint,
  sensorType,
  sensorIdentifier,
  sensorId,
  selectedFilters,
  onChangeFilters,
  applyFilter,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectAllSensors);
  const sensors = items || data;
  const [filterItemList, setFilterItemList] = useState([]);
  const [searchText, setSearchText] = useState(''); // state to maintain search text
  const [modal, setModalState] = useState(false);
  let mobileScreen = window.innerWidth < 599;
  const leftValue = useRef(null);
  const minWidthValue = useRef(null);

  const sensorsByPropValues = useSelector((state) => selectSensor(state,
    sensorType, sensorIdentifier));
  const sensorsByPropId = useSelector((state) => selectSensorById(state, sensorType, sensorId));
  const sensorLoading = useSelector((state) => selectLoadingSensors(state));

  useEffect(() => {
    if (sensorType && !filterItemList.length) {
      const obj = { type: 'type', value: sensorType, op: 'AND' };
      setFilterItemList([obj]);
    }
  }, [sensorType, filterItemList.length]);

  useEffect(() => {
    if (selectedFilters && selectedFilters.length) {
      setFilterItemList(selectedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (sensorTypeConstraint && sensorTypeConstraint.length > 0 && !filterItemList.length) {
      const objList = sensorTypeConstraint.map(
        (d) => (
          {
            type: 'type', value: d, op: 'OR', noEdit: true, noDelete: true,
          }),
      );
      setFilterItemList([...objList]);
    }
  }, [sensorTypeConstraint, filterItemList.length]);

  let sensorValue;
  if (sensorsByPropValues) {
    sensorValue = {
      type: sensorsByPropValues.type,
      identifier: sensorsByPropValues.identifier,
      id: sensorsByPropValues.id,
    };
  } else if (sensorsByPropId) {
    sensorValue = {
      type: sensorsByPropId.type,
      identifier: sensorsByPropId.identifier,
      id: sensorsByPropId.id,
    };
  } else if (sensorId && !sensorValue && sensorType
    && sensorIdentifier) {
    sensorValue = { type: sensorType, identifier: sensorIdentifier, id: sensorId };
  }

  useEffect(() => {
    const divElement = document.querySelector('#sensorSelect');
    if (divElement !== null && !leftValue.current) {
      setTimeout(() => {
        const declare = divElement.parentNode.parentNode.style;
        if (!declare.left.includes('-') && declare.left !== null) {
          leftValue.current = declare.left;
          minWidthValue.current = declare.minWidth;
        }
      }, 1000);
    }
  });


  const filteredSensorData = sensors
    .filter((sensor) => {
      if (sensorTypeConstraint && sensorTypeConstraint.length > 0) {
        return sensorTypeConstraint.indexOf(sensor.type) >= 0;
      }
      return true;
    })
    .filter((sensor) => (
      filterItemList.filter((d) => d.value).reduce((prev, curr) => {
        if (curr.op === OP.OR) {
          return prev || sensor[curr.type] === curr.value;
        }
        return prev && sensor[curr.type] === curr.value;
      }, true)));
  const countOfFilteredSensorData = [
    {
      name: `${filteredSensorData.length} results`,
      count: true,
    }];
  const sensorData = [...countOfFilteredSensorData, ...filteredSensorData];

  function onChangeFilter(dat) {
    if (onChangeFilters) onChangeFilters(dat);
  }

  // Below logic is for dynamic height calculation
  let height = 135; // 135px is fixed height for Done button section
  if (filterItemList.length > 0 && filterItemList.length <= 2) {
    // each filterlist height is approximately 50px
    height = (filterItemList.length * 50) + 135;
  } else if (filterItemList.length > 2) {
    height = 235;
  }

  /**
   * Below filter function only for mobile
   * @param {*} searchStr - Input: searchText and sensorData
   * Output: Filtered sensorData based on searchText
   */
  function customFilterMobile(searchStr) {
    const filterResult = sensorData.filter((d) => (!d.count && ((d && d.name
       && d.name.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0)
        || (d && d.type.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0)
        || (d && d.identifier
          && d.identifier.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0)
        || (d && d.install_state && d.install_state.toLowerCase()
          .indexOf(searchStr.toLowerCase()) >= 0))));
    const countOfFilterResult = [
      {
        name: `${filterResult.length} results`,
        count: true,
      }];
    return [...countOfFilterResult, ...filterResult];
  }

  const listHeight = calculatedHeightForMobile();

  const dropdownRender = (menu) => (
    <div>
      <div
        id="sensorSelect"
        className={styles['flex-container']}
        style={{ height: mobileScreen ? `${listHeight}px` : 'auto' }}
      >
        {mobileScreen
          ? (
            <div className={styles['modal-select-header']}>
              <div className={styles['modal-select-header-text']}>
                Sensor Select
              </div>
              <div />
            </div>
          ) : null}
        {mobileScreen
          ? (
            <Searchbox onChange={(d) => setSearchText(d.target.value)} />
          ) : null}
        <div
          className={styles['filter-outerdiv']}
          style={{
            overflowY: filterItemList.length > 2 ? 'auto' : 'none',
          }}
          data-testid="scrollableFilterOptionDiv"
        >
          {/* display the list of selected filters */}
          {filterItemList.map((item, index) => (
            <FilterListItem
              key={String(index)}
              type={item.type}
              value={item.value}
              onRemove={(obj) => {
                filterItemList.splice(obj.index, 1);
                setFilterItemList([...filterItemList]);
                onChangeFilter([...filterItemList]);
              }}
              onChange={(obj) => {
                filterItemList[obj.index] = { type: obj.type, value: obj.value, op: obj.op };
                setFilterItemList([...filterItemList]);
                onChangeFilter([...filterItemList]);
              }}
              changeIndex={index}
              op={index !== 0 && item.op ? item.op : ''}
              typeFilter={sensorTypeConstraint}
              noEdit={item.noEdit}
              noDelete={item.noDelete}
            />
          ))}

          {/* The main filter item to add to above list */}
          <FilterListItem
            onAdd={(obj) => {
              setFilterItemList([...filterItemList, obj]);
              onChangeFilter([...filterItemList, obj]);
            }}
            onChange={(obj) => {
              filterItemList[obj.index] = { type: obj.type, value: obj.value, op: obj.op };
              setFilterItemList([...filterItemList]);
              onChangeFilter([...filterItemList]);
            }}
            op={filterItemList.length > 0 ? OP.AND : ''}
            typeFilter={sensorTypeConstraint}
          />
        </div>
        <div className={styles['sensor-option-scrolldiv-outer']}>
          <div className={styles['sensor-option-scrolldiv-inner']}>
            {menu}
          </div>
        </div>
      </div>
      {mobileScreen
        ? (
          <div className={styles['button-centered']}>
            <Button id="goBack" type="primary" block onClick={() => setModalState(false)}>
              {t('done')}
            </Button>
          </div>
        ) : null}
    </div>
  );

  useEffect(() => {
    if (loadItems) loadItems();
    else dispatch(loadAllSensors());
  }, [loadItems, dispatch]);

  useEffect(() => {
    dispatch(loadFarmData(applyFilter));
  }, [applyFilter, dispatch]);

  function onSelectAction(d) {
    if (onSelect) onSelect(d);
    if (!mobileScreen) setModalState(false);
  }

  const onChangeAction = (d) => {
    const multipleMode = mode === 'multiple';
    if (onChange && d && !multipleMode) {
      onChange(JSON.parse(d));
    } else if (onChange && d && multipleMode) {
      onChange(d.map((e) => JSON.parse(e)));
    } else if (onChange && !d) {
      onChange({ id: undefined, type: undefined, identifier: undefined });
      onChangeFilter([]);
    }
  };

  const customFilter = (input, option) => {
    if ((option.children.props.sensor
      && option.children.props.sensor.name
      && option.children.props.sensor.name.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0)
      || (option.children.props.sensor
        && option.children.props.sensor.type
          .toLowerCase().indexOf(input.toLowerCase()) >= 0)
      || (option.children.props.sensor
        && option.children.props.sensor.identifier
        && option.children.props.sensor.identifier.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0)
      || (option.children.props.sensor
        && option.children.props.sensor.install_state
        && option.children.props.sensor.install_state.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0)) {
      return true;
    }
    return false;
  };

  /**
   * Logic to calculate minWidth for dropDown style on narrow screens
   * 500px is the mininum width and 950px is the max width upto the dropdown in admin-web
   */
  let minWidthForDropdown = (window.innerWidth > 500 && window.innerWidth < 950)
    ? { minWidth: `${(window.innerWidth - 360) > 450 ? (window.innerWidth - 360) : 450}px` }
    : { minWidth: '600px' };

  // Funtion to calculate minWidth on screen resize
  window.onresize = function onresize() {
    const { innerWidth } = window;
    mobileScreen = innerWidth < 599;
    if (innerWidth > 500 && innerWidth < 950) {
      minWidthForDropdown = { minWidth: `${innerWidth - 360}px` };
    }
  };

  // Logic to calculate minWidth on browser resize
  const fixedStyle = (window.innerWidth <= 500 && window.innerHeight > 600) ? {
    minWidth: `${window.innerWidth - 50}px`,
  } : undefined;

  return (
    <SensorSelectContext.Provider value={{ leftValue, minWidthValue }}>
      <div className={styles['sensor-select-outer']}>
        <ItemSelect
          showSearch={!mobileScreen}
          mode={mode}
          renderItem={renderItem}
          items={!mobileScreen ? sensorData : customFilterMobile(searchText)}
          onSelect={onSelectAction}
          onChange={onChangeAction}
          onBlur={() => (!mobileScreen ? setModalState(false) : null)}
          tagRender={tagRender || sensorTagRender}
          placeholder={modal ? t('Type to search') : placeholder}
          filterOption={(input, option) => customFilter(input, option)}
          dropdownRender={dropdownRender}
          defaultOption={defaultOption}
          value={sensorValue ? [JSON.stringify(sensorValue)] : null}
          dropdownStyle={mobileScreen ? fixedStyle : dropdownStyle || minWidthForDropdown}
          menuIsOpen={mobileScreen ? modal : undefined}
          loading={sensorLoading.loading}
          listHeightOffset={height}
          onDropdownVisibleChange={mobileScreen ? () => setModalState(true) : undefined}
        />
      </div>
    </SensorSelectContext.Provider>
  );
};

SensorSelect.propTypes = {
  mode: PropTypes.string,
  loadItems: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  tagRender: PropTypes.func,
  placeholder: PropTypes.string,
  defaultOption: PropTypes.arrayOf(PropTypes.any),
  dropdownStyle: PropTypes.shape({}),
  sensorTypeConstraint: PropTypes.arrayOf(PropTypes.string),
  sensorId: PropTypes.number,
  sensorIdentifier: PropTypes.string,
  sensorType: PropTypes.string,
  selectedFilters: PropTypes.arrayOf(PropTypes.object),
  onChangeFilters: PropTypes.func,
  applyFilter: PropTypes.bool,
};

SensorSelect.defaultProps = {
  mode: null,
  loadItems: null,
  items: null,
  renderItem: defaultItemRenderer,
  onSelect: null,
  onChange: null,
  tagRender: null,
  placeholder: null,
  defaultOption: [],
  dropdownStyle: null,
  sensorTypeConstraint: null,
  sensorId: null,
  sensorIdentifier: null,
  sensorType: null,
  selectedFilters: null,
  onChangeFilters: null,
  applyFilter: false,
};

export default SensorSelect;
