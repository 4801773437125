import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { sensorApi } from 'farmx-api';
import cloneDeep from 'lodash/cloneDeep';

const name = 'soilstate';

const initialState = {
  loading: false,
  currentRequestId: undefined,
  error: null,
  lastUpdated: null,
};

export const soilstateAdapter = createEntityAdapter();

export const loadSoilState = createAsyncThunk(
  `${name}/loadList`,
  async () => {
    const response = await sensorApi.fetchSoilStatus();
    return response.data;
  },
);

// datastructure is accessible by ranch id
export const soilstateSlice = createSlice({
  name,
  initialState: soilstateAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [loadSoilState.pending]: (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.error = undefined;
        state.currentRequestId = action.meta.requestId;
      }
    },

    [loadSoilState.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === true && state.currentRequestId === requestId) {
        const lastUpdated = Date.now();
        state.lastUpdated = lastUpdated;
        state.loading = false;
        state.currentRequestId = undefined;
        state.error = undefined;
        if (action.payload instanceof Array) {
          const soilstateData = action.payload.reduce((acc, entity) => {
            const { id: entityId } = entity;
            entity.ranches.forEach((ranch) => {
              acc.push({
                ...cloneDeep(ranch),
                entityId,
                lastUpdated,
              });
            });
            return acc;
          }, []);
          soilstateAdapter.setAll(state, soilstateData);
        } else {
          soilstateAdapter.setAll(state, []);
        }
      }
    },

    [loadSoilState.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === true && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export default soilstateSlice.reducer;
