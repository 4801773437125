import React from 'react';
import PropTypes from 'prop-types';
import InputNumberUnits from './InputNumberUnits';

const timeUnits = [
  {
    key: 'minutes',
    name: 'Minutes',
  },
  {
    key: 'hours',
    name: 'Hours',
  },
];

function InputTime(props) {
  const { disabled, initialValue, onChange } = props;
  return (
    <InputNumberUnits
      unitOptions={timeUnits}
      onChange={onChange}
      disabled={disabled}
      initialValue={initialValue}
    />
  );
}

InputTime.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.number,
  onChange: PropTypes.func,
};

InputTime.defaultProps = {
  onChange: null,
  disabled: false,
  initialValue: 0,
};

export default InputTime;
