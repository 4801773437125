import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import { TreeSelect, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { actions, hooks } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import styles from './RanchBlockStyles.less';
import DropdownModal from './sensor/DropdownModal';
import calculatedHeightForMobile from './helper';

const { TreeNode } = TreeSelect;
const { Text } = Typography;
const mobileScreen = window.innerWidth < 599;

const {
  loadFarmData,
  setUrlLoad,
} = actions;

const {
  useEntityRanchBlock,
} = hooks;

function getTreeNode(
  tnValue,
  tnKey,
  tnName,
  titleKey,
  titleClass,
  titleContent,
  selectable,
  tnContent,
  tnId,
) {
  return (
    <TreeNode
      key={tnValue || tnKey}
      value={tnValue || tnKey}
      name={tnName}
      title={<Text key={titleKey} id={tnId} className={titleClass}>{titleContent}</Text>}
      selectable={selectable}
    >
      {tnContent}
    </TreeNode>
  );
}

function getClassName(id, index, selectedValue) {
  let stylestr = '';
  if (index % 2 && selectedValue && selectedValue === String(id)) {
    stylestr = `${styles['block-header']} ${styles.selected}`;
  } else if (index % 2 && selectedValue && selectedValue !== String(id)) {
    stylestr = styles['block-header'];
  } else if (!(index % 2) && selectedValue && selectedValue === String(id)) {
    stylestr = `${styles['block-name']} ${styles.selected}`;
  } else if (!(index % 2) && selectedValue && selectedValue !== String(id)) {
    stylestr = styles['block-name'];
  }

  return stylestr;
}

function getNoRanchTreeNode(data, t) {
  const expandKeys = [];
  const nrKey = `No_Ranch_${data[0].id}_${data[0].name}`;
  expandKeys.push(nrKey);
  return {
    nodes: getTreeNode(
      null,
      nrKey,
      'No Ranch',
      `No_Ranch_${data[0].ranches.length}_${data[0].name}`,
      styles['no-ranch'],
      t('No Ranch'),
      false,
      undefined,
      `No_Ranch_${data[0].id}`,
    ),
    expandKeys,
  };
}

// Returns TreeNodes and keys for blocks
function getBlockContent(data = [], rIndex, selectedValue) {
  const expandKeys = [];
  return {
    nodes: data.map((block, bIndex) => {
      if (block && block.id) {
        const bKey = `${block.id}`;
        expandKeys.push(bKey);
        return (
          getTreeNode(
            bKey,
            null,
            block.name,
            `${String(rIndex)}_${block.id}`,
            getClassName(block.id, bIndex, selectedValue),
            block.name,
            true,
            undefined,
            `Block_${block.id}`,
          )
        );
      } return null;
    }),
    expandKeys,
  };
}

// Returns TreeNodes and keys for ranches and blocks
// It calls getBlockContent() to get block tree nodes
function getRanchContent(data = [], eId, eIndex, selectedValue, t) {
  let expandKeys = [];
  return {
    nodes: data.map((ranch, rIndex) => {
      if (ranch && ranch.id) {
        const rKey = `${eId}_${ranch.id}`;
        const blockTreeNodes = getBlockContent(ranch.blocks, rIndex, selectedValue);
        expandKeys.push(rKey);
        expandKeys = [...expandKeys, ...blockTreeNodes.expandKeys];
        return getTreeNode(
          rKey,
          null,
          ranch.name,
          `${eId}_${String(eIndex)}_${ranch.id}`,
          selectedValue && selectedValue.includes(String(ranch.id))
            ? `${styles['ranch-header']} ${styles.selected}`
            : styles['ranch-header'],
          ranch.name,
          true,
          ranch.blocks.length ? blockTreeNodes.nodes
            : getTreeNode(
              null,
              `No_Block_${String(eIndex)}_${String(rIndex)}`,
              'No Block',
              `${String(eIndex)}_${String(rIndex)}`,
              styles['no-block'],
              t('No Block'),
              false,
              undefined,
              `No_Block_${String(eIndex)}_${String(rIndex)}`,
            ),
          `Ranch_${ranch.id}`,
        );
      } return null;
    }),
    expandKeys,
  };
}

// Returns TreeNodes and keys for entity, ranch, block
// It calls getRanchContent() to get ranch tree nodes
function getEntityLevelHierarchy(data = [], selectedValue, t) {
  let expandKeys = [];
  return {
    nodes: data.map((entity, eIndex) => {
      if (entity && entity.id) {
        const eKey = `${entity.name}_${entity.id}_${String(eIndex)}`;
        const ranchTreeNodes = getRanchContent(entity.ranches, entity.id, eIndex,
          selectedValue, t);
        expandKeys.push(eKey);
        expandKeys = [...expandKeys, ...ranchTreeNodes.expandKeys];
        return getTreeNode(
          null,
          eKey,
          entity.name,
          `${entity.id}_${String(eIndex)}`,
          styles['entity-header'],
          entity.name,
          false,
          entity.ranches.length ? ranchTreeNodes.nodes : getTreeNode(
            null,
            `No_Ranch_${entity.id}_${entity.name}`,
            'No Ranch',
            `${String(eIndex)}`,
            styles['no-ranch'],
            t('No Ranch'),
            false,
            undefined,
            `No_Ranch_${entity.id}_${entity.name}`,
          ),
          `Entity_${entity.id}`,
        );
      }
      return null;
    }),
    expandKeys,
  };
}

// Returns TreeNodes and keys for single entity, ranch, block
function getRanchLevelHierarchy(data = [], selectedValue, t) {
  let expandKeys = [];
  return {
    nodes: data.map((ranch, rIndex) => {
      if (ranch && ranch.id) {
        const rKey = `${String(rIndex)}_${ranch.id}`;
        const ranchTreeNodes = getRanchContent(data[0].ranches, data[0].id, 0, selectedValue, t);
        expandKeys.push(rKey);
        expandKeys = [...expandKeys, ...ranchTreeNodes.expandKeys];
        return ranchTreeNodes.nodes;
      } return null;
    }),
    expandKeys,
  };
}

function getCustomSelectOption(data, selectedValue, t) {
  let processedData = {};
  if (data && data.length) {
    if (data.length > 1) {
      processedData = getEntityLevelHierarchy(data, selectedValue, t);
    } else if (data[0] && data[0].ranches.length) {
      processedData = getRanchLevelHierarchy(data, selectedValue, t);
    } else {
      processedData = getNoRanchTreeNode(data, t);
    }
  }

  return processedData;
}

const RanchBlockSelect = ({
  onSelect, selected, getSelectedRanchBlock, title, admin,
}) => {
  const [modal, setModalState] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState(''); // state to maintain search text
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [options, setOptions] = useState([]); // state to maintain select options

  // Load all entities, blocks and ranches
  useEffect(() => {
    setTimeout(() => { dispatch(loadFarmData(true)); }, 0);
  }, [dispatch]);

  // Get data as array of object format
  const finalData = useEntityRanchBlock(options);

  useEffect(() => {
    if (JSON.stringify(finalData) !== JSON.stringify(options)) {
      setOptions(finalData);
    }
  }, [finalData, options]);

  const onChange = (value) => {
    if (!mobileScreen) dispatch(setUrlLoad(false));
    if (value) {
      const ids = value.split('_');
      const obj = {
        key: value,
        value: ids[ids.length - 1],
        type: ids.length === 2 ? 'ranch' : 'block',
      };
      if (onSelect) onSelect(obj);
      if (getSelectedRanchBlock instanceof Function) {
        getSelectedRanchBlock(obj);
      }
    } else {
      const obj = { key: 'All Ranches', value, type: 'All Ranches' };
      if (onSelect) onSelect(obj);
      if (getSelectedRanchBlock instanceof Function) {
        getSelectedRanchBlock(obj);
      }
    }
  };

  // To get the scrollable element by Id
  let scrollElementId;
  if (selected.type && selected.type === 'ranch') scrollElementId = `Ranch_${selected.value}`;
  if (selected.type && selected.type === 'block') scrollElementId = `Block_${selected.value}`;

  let scrollElem;
  const filterTreeNode = (txt, node) => {
    const filterTitle = node.title.props.children;
    const result = !!(filterTitle
      && String(filterTitle).toLowerCase().indexOf(txt.toLowerCase()) !== -1);

    // To get the element when change the search text
    scrollElem = document.getElementById(scrollElementId);

    return result;
  };

  // To scroll the element to top when change text
  useEffect(() => {
    if (scrollElem) scrollElem.scrollIntoView({ block: 'start' });
  });

  const handleOnClick = (d) => {
    if (d.target && (!d.target.id.includes('Entity') && !d.target.id.includes('No_'))) {
      setModalState(false); setSearchText(); setShowSearch(false);
    }
  };

  // Get TreeNodes and expandKeys
  const selectObj = getCustomSelectOption(options, selected.value || [], t);

  const listHeight = calculatedHeightForMobile();

  const selectedValue = selected.key && selected.key.toLowerCase() === 'all ranches' ? t('All Ranches') : selected.key;

  return (
    <div style={{ margin: 2 }}>
      <TreeSelect
        showSearch={mobileScreen ? showSearch : !showSearch}
        style={{ width: '100%' }}
        bordered={false}
        value={selectedValue}
        placeholder={t('Please select')}
        multiple={false}
        treeDefaultExpandAll
        onChange={onChange}
        onSearch={(d) => setSearchText(d)}
        listHeight={mobileScreen ? listHeight - 70 : 330}
        onDropdownVisibleChange={(d) => {
          if (mobileScreen) setModalState(true);
          else setModalState(d);
        }}
        open={modal}
        filterTreeNode={filterTreeNode}
        treeNodeFilterProp="value"
        treeNodeLabelProp="name"
        searchValue={searchText}
        treeExpandedKeys={selectObj.expandKeys}
        dropdownRender={mobileScreen ? (menu) => (
          <DropdownModal
            title={title || 'Select Ranch or Block'}
            displayOptions={menu}
            mobileScreen={mobileScreen}
            onClick={handleOnClick}
            onChange={(d) => { setSearchText(d.target.value); setShowSearch(true); }}
            displaySearch
            buttonText="cancel"
            size={mobileScreen ? 'large' : 'middle'}
          />
        ) : undefined}
        getPopupContainer={mobileScreen ? null : (triggerNode) => triggerNode.parentNode}
      >
        {!admin ? (
          <TreeNode
            value={null}
            name="All Ranches"
            key={null}
            title={(
              <Text
                key="all_ranches"
                className={
                  selected.value === null
                    ? `${styles['select-all']} ${styles.selected}`
                    : styles['select-all']
                }
              >
                {t('All Ranches')}
              </Text>
            )}
          />
        ) : null}
        {selectObj.nodes}
      </TreeSelect>
    </div>
  );
};

RanchBlockSelect.propTypes = {
  getSelectedRanchBlock: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.shape({
    value: PropTypes.any,
    key: PropTypes.any,
    type: PropTypes.any,
  }),
  title: PropTypes.string,
  admin: PropTypes.bool,
};

RanchBlockSelect.defaultProps = {
  getSelectedRanchBlock: null,
  onSelect: null,
  selected: {},
  title: null,
  admin: false,
};

export default RanchBlockSelect;
