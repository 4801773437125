/* eslint-disable react/jsx-fragments */
import {
  useState, useCallback, useEffect,
} from 'react';

export default function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }), [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);
  const handleResize = useCallback(() => {
    setWindowSize(getSize());
  }, [setWindowSize, getSize]);

  useEffect(() => {
    if (!isClient) return false;
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
