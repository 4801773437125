import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  initialState,
  requestListThrottled,
  receiveList,
  requestListFailed,
  requestItemById,
  receiveItem,
  requestItemByIdFailed,
  loadDetail,
  loadList,
} from '../helpers';

export const farmAdapter = createEntityAdapter();

export const createFarmSlice = (name, getList, getDetail, getRequestState) => {
  const loadListThunk = createAsyncThunk(
    `${name}/loadList`,
    loadList({
      getData: getList,
      getRequestState,
    }),
  );

  const loadDetailThunk = createAsyncThunk(
    `${name}/loadDetail`,
    loadDetail({
      getData: getDetail,
      getRequestState: (id, state) => {
        const { entities } = getRequestState(state);
        return entities[id];
      },
    }),
  );

  const slice = createSlice({
    name,
    initialState: farmAdapter.getInitialState(initialState),
    reducers: {
      cleanListRequestState(state) {
        state.loading = false;
        state.currentRequestId = undefined;
        state.error = null;
      },
      updateItem: farmAdapter.updateOne,
    },
    extraReducers: {
      [loadListThunk.pending]: requestListThrottled(),
      [loadListThunk.fulfilled]: receiveList(farmAdapter),
      [loadListThunk.rejected]: requestListFailed,
      [loadDetailThunk.pending]: requestItemById(farmAdapter),
      [loadDetailThunk.fulfilled]: receiveItem(farmAdapter),
      [loadDetailThunk.rejected]: requestItemByIdFailed(farmAdapter),
    },
  });

  return {
    loadListThunk,
    loadDetailThunk,
    slice,
  };
};
