/* eslint-disable */

/**
 * Source for the original can be found here https://github.com/mutsuyuki/Leaflet.SmoothWheelZoom
 * This is modified version from the Farmx angular application
 */
export function leafletVectorMarkers(L) {
  if (L.VectorMarkers) {
    return;
  }
  L.VectorMarkers = {};
  L.VectorMarkers.version = "1.0.0";
  L.VectorMarkers.MAP_PIN = 'M6.19,28.81a16,16,0,1,1,22.63,0L17.5,40.13Z';
  L.VectorMarkers.PIN_INNER = 'M7.9,27.1a13.58,13.58,0,1,1,19.21,0l-9.6,9.6Z';
  L.VectorMarkers.Icon = L.Icon.extend({
    options: {
      iconSize: [32, 40],
      iconAnchor: [16, 40],
      popupAnchor: [2, -40],
      shadowAnchor: [24, 24],
      shadowSize: [32, 40],
      className: "vector-marker",
      prefix: "fa",
      spinClass: "fa-spin",
      extraClasses: "",
      extraClassesSvg: "",
      icon: "home",
      markerColor: "blue",
      iconColor: "white",
      selected: false,
      selectedClass: '',
      data: undefined,
    },
    initialize: function (options) {
      return options = L.Util.setOptions(this, options);
    },
    createIcon: function (oldIcon) {
      var div, icon, options, pin_path, pin_inner;
      div = (oldIcon && oldIcon.tagName === "DIV" ? oldIcon : document.createElement("div"));
      options = this.options;
      div.setAttribute('data-test-id', `marker-${options.dataTestId}`);

      if (options.data && typeof options.data.value !== 'undefiend') {
        let x = 10;
        if (Number(options.data.value) === 0) {
          x = 14;
        } else if (Number(options.data.value) >= 100) {
          x = 7;
        } else if (Number(options.data.value) >= 10) {
          x = 11;
        } else if (String(options.data.value).split('').length === 1) {
          x = 14;
        } else {
          x = 10;
        }
        icon = `<text x="${x}" y="22" fill="${options.data.valueColor}" font-weight="bold">${options.data.value}</text>`;
      } else if (options.icon) {
        icon = this._createInner();
      }
      pin_path = L.VectorMarkers.MAP_PIN;
      pin_inner = L.VectorMarkers.PIN_INNER;
      const borderColor = options.markerBorderColor || options.markerColor;
      if(icon) {
        div.innerHTML = '<svg width="35px" height="40px" ' + `class="${options.extraClassesSvg}"` + ' viewBox="0 0 35 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
        + '<path class="pin-outer ' + `marker-pin-outer-${options.dataTestId}` + '" d="' + pin_path
        + '" fill="' + borderColor + '"></path>'
        + '<path class="pin-inner ' + `marker-pin-inner-${options.dataTestId}` + '" d="' + pin_inner
        + '" fill="' + options.markerColor + '"></path>' + icon + '</svg>';
      } else {
        div.innerHTML = '<svg width="35px" height="40px" ' + `class="${options.extraClassesSvg}"` + ' viewBox="0 0 35 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
        + '<path class="pin-outer ' + `marker-pin-outer-${options.dataTestId}` + '" d="' + pin_path
        + '" fill="gray"></path>'
        + '<path class="pin-inner ' + `marker-pin-inner-${options.dataTestId}` + '" d="' + pin_inner
        + '" fill="gray"></path>' 
        + '<text x="7" y="22" fill="white" font-weight="bold">N/A</text>' 
        + '</svg>';

      }
      
      this._setIconStyles(div, "icon");
      if (options.selected) {
        this._setIconStyles(div, " selected-icon");
      }

      return div;
    },
    _createInner: function () {
      var iconClass, iconColorClass, iconColorStyle, iconSpinClass, options;
      iconClass = void 0;
      iconSpinClass = "";
      iconColorClass = "";
      iconColorStyle = "";
      var options = this.options;
      if (options.data) {
        return;
      }
      if (options.icon.slice(0, options.prefix.length + 1) === options.prefix + "-") {
        iconClass = options.icon;
      } else {
        iconClass = options.prefix + "-" + options.icon;
      }
      if (options.spin && typeof options.spinClass === "string") {
        iconSpinClass = options.spinClass;
      }
      if (options.iconColor) {
        if (options.iconColor === "white" || options.iconColor === "black") {
          iconColorClass = "icon-" + options.iconColor;
        } else {
          iconColorStyle = "style='color: " + options.iconColor + "' ";
        }
      }
      return "<i " + iconColorStyle + "class='" + options.extraClasses + " " + options.prefix + " " + iconClass + " " + iconSpinClass + " " + iconColorClass + "'></i>";
    },
    _setIconStyles: function (img, name) {
      var anchor, options, size;
      options = this.options;
      size = L.point(options[(name === "shadow" ? "shadowSize" : "iconSize")]);
      anchor = void 0;
      if (name === "shadow") {
        anchor = L.point(options.shadowAnchor || options.iconAnchor);
      } else {
        anchor = L.point(options.iconAnchor);
      }
      if (!anchor && size) {
        anchor = size.divideBy(2, true);
      }
      img.className = "vector-marker" + name + " " + options.className;
      if (options.selectedClass) {
        img.classList.add(options.selectedClass);
      }
      if (anchor) {
        img.style.marginLeft = (-anchor.x) + "px";
        img.style.marginTop = (-anchor.y) + "px";
      }
      if (size) {
        img.style.width = size.x + "px";
        return img.style.height = size.y + "px";
      }
    },
    createShadow: function () {
      var div;
      div = document.createElement("div");
      this._setIconStyles(div, "shadow");
      return div;
    }
  });

  L.VectorMarkers.icon = function (options) {
    return new L.VectorMarkers.Icon(options);
  };
}

/* 
 * Workaround for 1px lines appearing in some browsers due to fractional transforms
 * and resulting anti-aliasing.
 * https://github.com/Leaflet/Leaflet/issues/3575
 */
(function () {
  const originalInitTile = L.GridLayer.prototype._initTile
  L.GridLayer.include({
    _initTile: function (tile) {
      originalInitTile.call(this, tile);

      const tileSize = this.getTileSize();

      tile.style.width = tileSize.x + 1 + 'px';
      tile.style.height = tileSize.y + 1 + 'px';
    }
  });
})()
