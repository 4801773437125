import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUserLanguage } from '../../settings/selectors';
import { useLoadUserSettings } from './useLoadUserSettings';

const apiVersion = 'v2';
const baseUrl = 'https://farmx.zendesk.com/api';
const auth = `Basic ${process.env.REACT_APP_ZENDESK_API_AUTH}`;

const fetchZendeskContent = (path) => {
  const url = `${baseUrl}/${apiVersion}/${path}`;

  try {
    const options = {
      headers: {
        Authorization: auth,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    return fetch(url, options)
      .then((res) => {
        if (res && res.status !== 200) {
          return { error: 'Unable to load data' };
        }
        return res.json();
      })
      .then((res) => {
        if (res && !res.error) {
          return res;
        }
        return { error: res.description || res.error };
      });
  } catch (e) {
    return Promise.resolve({ error: e || 'Unable to load data' });
  }
};

function useZendesk() {
  useLoadUserSettings();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(async (path) => {
    setLoading(true);
    const response = await fetchZendeskContent(path);
    if (response.error) {
      setError(response.error);
      setData(null);
    } else {
      setError(null);
      setData(response);
    }
    setLoading(false);
  }, []);

  return [data, loading, error, loadData];
}

/**
 * Load article from Zendesk help center
 * @param {*} articleId
 * @returns {Array} [data, loading, error]
 */
export function useZendeskHelpContent(articleId) {
  const locale = useSelector(selectUserLanguage);
  const userLocale = locale === 'en' ? 'en-us' : locale;
  const path = `/help_center/${userLocale}/articles/${articleId}`;
  const [data, loading, error, loadContent] = useZendesk();
  useEffect(() => {
    loadContent(path);
  }, [loadContent, path]);
  return [data, loading, error];
}

/**
 * Perform search on Zendesk articles. The fourth item in the array is function.
 * This function can be called with search parameter to perform search.
 * @returns {Array} [data, loading, error, searchApi]
 */
export function useZendeskSearch() {
  useLoadUserSettings();
  const locale = useSelector(selectUserLanguage);
  const userLocale = locale === 'en' ? 'en-us' : locale;
  const [data, loading, error, loadContent] = useZendesk();

  const getArticle = useCallback((query) => {
    const params = new URLSearchParams({
      locale: userLocale,
      query,
    });
    const path = `/help_center/articles/search.json?${params}`;
    loadContent(path);
  }, [loadContent, userLocale]);

  return [data, loading, error, getArticle];
}

/**
 * Get all the help articles
 * TODO: Support pagination
 * @returns {Array} [data, loading, error]
 */
export function useZendeskArticles() {
  const locale = useSelector(selectUserLanguage);
  const userLocale = locale === 'en' ? 'en-us' : locale;
  const path = `/help_center/${userLocale}/articles`;
  const [data, loading, error, loadContent] = useZendesk();
  useEffect(() => {
    loadContent(path);
  }, [loadContent, path]);
  return [data, loading, error];
}
