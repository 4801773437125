import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import selected from './slices/selected';
import settings from './slices/settings';

const clientReducer = combineReducers({
  settings,
  selected: persistReducer(
    {
      key: 'selected',
      storage,
      blacklist: ['urlLoad'],
    }, selected,
  ),
});

export default clientReducer;
