import {
  createSlice,
} from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep'; // for immutable data

const name = 'settings';

const settingsSlice = createSlice({
  name,
  initialState: {
    // mapPresentationModes are used to render customer devices on the field
    mapPresentationModes: {
      irrigationRecommendation: false, // missing implementation
      soilstatus: true,
      connectivity: false,
      waterpressure: false,
      control: false, // missing implementation
      provision: false,
      anomaly: false,
    },
    // There are filtered selections of data we want to show the user in Map views
    // e.g. different presentation modes for soil sensors, waterpressure sensors, gateways
    // This sets up a flag to initially show only markers determined by Presentation mode
    // When showOnlyPresentionModeSensors gets set to false all sensors are shown regardless
    // of the selected presentation mode.
    showOnlyPresentionModeSensors: true,
    mapShowLabels: false,
    mapShowSoilType: false,
    mapShowTemperature: false,
    mapShowWind: false,
  },
  reducers: {
    setMapShowSoilType: (state, action) => {
      state.mapShowSoilType = action.payload;
    },
    setMapShowTemperature: (state, action) => {
      state.mapShowTemperature = action.payload;
    },
    setMapShowWind: (state, action) => {
      state.mapShowWind = action.payload;
    },
    setMapShowLabels: (state, action) => {
      state.mapShowLabels = action.payload;
    },
    setShowOnlyPresentionModeSensors: (state, action) => {
      state.showOnlyPresentionModeSensors = action.payload;
    },
    // Example payload { waterpressure: false}
    setMapPresentationModes: (state, action) => {
      const presModesAllFalse = Object
        .keys(state.mapPresentationModes)
        .reduce((acc, curr) => {
          acc[curr] = false;
          return acc;
        }, {});
      state.mapPresentationModes = cloneDeep({
        ...presModesAllFalse,
        ...action.payload,
      });
    },
  },
});

export const {
  setMapShowSoilType,
  setMapShowTemperature,
  setMapShowWind,
  setMapShowLabels,
  setShowOnlyPresentionModeSensors,
  setMapPresentationModes,
} = settingsSlice.actions;
export default settingsSlice.reducer;
