import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { sensorApi } from 'farmx-api';
import {
  initialState,
  requestList,
  receiveListCheckDate,
  requestListFailed,
  requestItemByParams,
  receiveItem,
  requestItemByParamsFailed,
  loadList,
  loadByTypeIdentifier,
} from '../../helpers';

import {
  sensorsAdapter,
  selectSensorStats,
} from '../selectors';

const {
  fetchSensorStatsList,
  fetchCavalierStatsList,
  fetchGatewayStatsList,
  fetchSensorStatsSingle,
} = sensorApi;

const createStatsSlice = (name, getList, getDetail, getRequestState) => {
  const loadStatsList = createAsyncThunk(
    `stats/${name}/loadList`,
    loadList({
      getData: getList,
      getRequestState,
    }),
  );

  const loadStatsSingle = createAsyncThunk(
    `stats/${name}/loadSingle`,
    loadByTypeIdentifier({
      getData: getDetail,
      getRequestState: selectSensorStats,
    }),
  );

  const slice = createSlice({
    name,
    initialState: sensorsAdapter.getInitialState(initialState),
    reducers: {},
    extraReducers: {
      [loadStatsList.pending]: requestList,
      [loadStatsList.fulfilled]: receiveListCheckDate(sensorsAdapter),
      [loadStatsList.rejected]: requestListFailed,
      [loadStatsSingle.pending]: requestItemByParams(sensorsAdapter),
      [loadStatsSingle.fulfilled]: receiveItem(sensorsAdapter),
      [loadStatsSingle.rejected]: requestItemByParamsFailed(sensorsAdapter),
    },
  });

  return {
    loadStatsList,
    loadStatsSingle,
    slice,
  };
};

const sensorStats = createStatsSlice(
  'sensor',
  fetchSensorStatsList,
  fetchSensorStatsSingle,
  (state) => state.sensorsData.stats.sensor,
);
const cavalierStats = createStatsSlice(
  'cavalier',
  fetchCavalierStatsList,
  fetchSensorStatsSingle,
  (state) => state.sensorsData.stats.cavalier,
);
const gatewayStats = createStatsSlice(
  'gateway',
  fetchGatewayStatsList,
  fetchSensorStatsSingle,
  (state) => state.sensorsData.stats.gateway,
);

const {
  loadStatsList: loadSensorStatsList,
  loadStatsSingle: loadSensorStatsSingle,
} = sensorStats;
const {
  loadStatsList: loadCavalierStatsList,
  loadStatsSingle: loadCavalierStatsSingle,
} = cavalierStats;
const {
  loadStatsList: loadGatewayStatsList,
  loadStatsSingle: loadGatewayStatsSingle,
} = gatewayStats;

export {
  loadSensorStatsList,
  loadSensorStatsSingle,
  loadCavalierStatsList,
  loadCavalierStatsSingle,
  loadGatewayStatsList,
  loadGatewayStatsSingle,
};

export default combineReducers({
  sensor: sensorStats.slice.reducer,
  cavalier: cavalierStats.slice.reducer,
  gateway: gatewayStats.slice.reducer,
});
