/* eslint-disable react/jsx-fragments */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import './map.less';
import './lib/leaflet-markercluster/MarkerCluster.less';
import './lib/leaflet-vector-markers/leafletVectorMarkers.less';
import WebMercatorViewport from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import ReactResizeDetector from 'react-resize-detector';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import L from 'leaflet';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Map as LeafletMap,
  TileLayer,
  GeoJSON,
  Marker,
} from 'react-leaflet';
import { MarkerCluster } from './MarkerCluster';
import { leafletVectorMarkers } from './lib/leaflet-vector-markers/leafletVectorMarkers';
import { prepareSingleMarkerIcon } from './prepareMarker';
import { LabelMarker } from './LabelMarker';
import { stateColorCode, getColorCodeForIrrigationState, getColorCodeForSoilState } from './constants';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const isEqual = require('react-fast-compare');

leafletVectorMarkers(L);

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const initialState = {
  viewBox: {
    latitude: 37.646,
    longitude: -121.016,
    zoom: 6,
  },
  sensorsGeoJSON: {
    features: [],
  },
  ranchesGeoJSON: {
    features: [],
  },
};

function SingleMarker(singleMarkerGeoJSON) {
  return (
    <Marker
      position={[
        singleMarkerGeoJSON.geometry.coordinates[0],
        singleMarkerGeoJSON.geometry.coordinates[1]]}
      icon={prepareSingleMarkerIcon()}
    />
  );
}

function renderAnomalyLayer(anomalyGeoJSON, presentationMode, isShowingImagery,
  zoomShowSensorsState, onClickBlock) {
  const feature = anomalyGeoJSON;
  const style = {
    color: '#3388ff',
    dashArray: '8 8',
    weight: 3,
    opacity: 1.0,
    fillColor: '#000000',
    fillOpacity: 0.0,
    editing: {
      dashArray: '10, 10',
      fill: true,
      fillColor: '#fe57a1',
      fillOpacity: 0.1,
      maintainColor: false,
    },
  };
  const selected = false;

  return (
    <GeoJSON
      className={`map-ranch-block-b-${feature.name}`}
      style={style}
      key={`${presentationMode}${JSON.stringify(feature.properties)}${selected}${isShowingImagery.visible}${zoomShowSensorsState}`}
      data={[feature]}
      onClick={() => {
        if (onClickBlock) {
          onClickBlock(feature.properties.id);
        }
      }}
    />
  );
}

const maxZoom = 20;

function Map({
  children,
  sensorsGeoJSON,
  mapBoxPadding,
  width,
  height,
  activePopUp,
  selectSensor,
  showAllSensorLabels,
  recenterGeoJSON,
  expandedGeoJSON,
  ranchesGeoJSON,
  blocksGeoJSON,
  showPopUpOnMarkerClick,
  presentationMode,
  selectedSensor,
  isDataLoading,
  onClickRanch,
  onClickBlock,
  onClickMap,
  singleMarkerGeoJSON,
  dynamicBoundsGeoJSON,
  onClickDynamicBounds,
  isShowingImagery,
  exposeZoomChange,
  colors,
  anomalyGeoJSON,
}) {
  if (dynamicBoundsGeoJSON && !dynamicBoundsGeoJSON?.features) {
    const dynamicBoundsGeoJSONBuffer = {
      type: 'FeatureCollection',
      features: [],
    };
    dynamicBoundsGeoJSONBuffer.features.push(cloneDeep(dynamicBoundsGeoJSON));
    dynamicBoundsGeoJSON = dynamicBoundsGeoJSONBuffer;
  }

  const [state, setState] = useState(initialState);
  const mounted = useRef(false);
  const mapRef = useRef(null);
  const previousZoom = useRef(state.viewBox);

  const el = document;
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, false);
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
    mounted.current = true;

    el.dispatchEvent(event);

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if (typeof exposeZoomChange === 'function' && mapRef.current) {
      exposeZoomChange(mapRef.current.getZoom(), mapRef.current);
    }

    const incomingState = {
      recenterGeoJSON,
      expandedGeoJSON,
      ranchesGeoJSON,
      dynamicBoundsGeoJSON,
      sensorsGeoJSON,
      mapBoxPadding,
      width,
      height,
      viewBox: state.viewBox,
    };

    if (isEqual(incomingState, state) && !anomalyGeoJSON) {
      return;
    }

    if (recenterGeoJSON && recenterGeoJSON.features.length && recenterGeoJSON.features[0]) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(recenterGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          recenterGeoJSON,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom: zoom > maxZoom ? maxZoom : zoom,
          },
          width,
          height,
        };
        previousZoom.current = newState.viewBox;
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport recenterGeoJSON', error);
      }
      if (!anomalyGeoJSON) {
        return;
      }
    }

    // To zoom the map when clicking on anomlay icon
    if (anomalyGeoJSON && anomalyGeoJSON?.properties?.center?.coordinates) {
      const [longitude, latitude] = anomalyGeoJSON.properties.center.coordinates;

      const changedViewBox = {
        latitude,
        longitude,
        zoom: 17,
      };
      setState({
        ...state,
        viewBox: changedViewBox,
      });

      return;
    }

    if (expandedGeoJSON && expandedGeoJSON.features.length) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(expandedGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          recenterGeoJSON,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom: zoom > maxZoom ? maxZoom : zoom,
          },
          width,
          height,
        };
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport expandedGeoJSON', error);
      }
      return;
    }

    if (!ranchesGeoJSON && !sensorsGeoJSON && singleMarkerGeoJSON) {
      try {
        if (!width || !height) {
          return;
        }

        const newState = {
          ...state,
          recenterGeoJSON,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          singleMarkerGeoJSON,
          viewBox: {
            longitude: singleMarkerGeoJSON.geometry.coordinates[1],
            latitude: singleMarkerGeoJSON.geometry.coordinates[0],
            zoom: 17,
          },
          width,
          height,
        };
        setState(newState);
        return;
      } catch (error) {
        console.log('WebMercatorViewport singleMarkerGeoJSON', error);
      }
    }

    if (dynamicBoundsGeoJSON && dynamicBoundsGeoJSON.features.length) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(dynamicBoundsGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          recenterGeoJSON,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom: zoom > maxZoom ? maxZoom : zoom,
          },
          width,
          height,
        };
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport ranchesGeoJSON', error);
      }
      return;
    }

    if (ranchesGeoJSON && ranchesGeoJSON.features.length && ranchesGeoJSON.features[0]) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(ranchesGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          recenterGeoJSON,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom: zoom > maxZoom ? maxZoom : zoom,
          },
          width,
          height,
        };
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport ranchesGeoJSON', error);
      }
      return;
    }

    if (!sensorsGeoJSON) {
      setState({
        ...state,
        recenterGeoJSON,
        expandedGeoJSON,
        ranchesGeoJSON,
        dynamicBoundsGeoJSON,
        sensorsGeoJSON,
        mapBoxPadding,
      });
      return;
    }

    sensorsGeoJSON.features = sensorsGeoJSON.features
      .filter((feature) => feature.geometry.coordinates[0] && feature.geometry.coordinates[1]);

    if (!sensorsGeoJSON.features.length) {
      setState({
        ...state,
        recenterGeoJSON,
        expandedGeoJSON,
        ranchesGeoJSON,
        dynamicBoundsGeoJSON,
        sensorsGeoJSON,
        mapBoxPadding,
      });
      return;
    }

    try {
      if (!width || !height) {
        return;
      }

      const [minLng, minLat, maxLng, maxLat] = bbox(sensorsGeoJSON);
      const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

      const { longitude, latitude, zoom } = webMercatorViewport
        .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: {
            top: mapBoxPadding.top,
            bottom: mapBoxPadding.bottom,
            left: mapBoxPadding.left,
            right: mapBoxPadding.right,
          },
        });

      const newState = {
        ...state,
        recenterGeoJSON,
        expandedGeoJSON,
        ranchesGeoJSON,
        dynamicBoundsGeoJSON,
        sensorsGeoJSON,
        mapBoxPadding,
        viewBox: {
          longitude,
          latitude,
          zoom: zoom > maxZoom ? maxZoom : zoom,
        },
        width,
        height,
      };
      setState(newState);
    } catch (error) {
      console.log('WebMercatorViewport sensorsGeoJSON', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recenterGeoJSON,
    expandedGeoJSON,
    singleMarkerGeoJSON,
    ranchesGeoJSON,
    dynamicBoundsGeoJSON,
    sensorsGeoJSON,
    mapBoxPadding,
    width,
    height,
    anomalyGeoJSON,
  ]);

  useEffect(() => {
    // Zoom out the map when clear anomalyGeoJSON
    if (!anomalyGeoJSON && previousZoom.current) {
      setState({
        ...state,
        viewBox: previousZoom.current,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomalyGeoJSON]);

  const [zoomShowSensorsState, setZoomShowSensorsState] = useState(true);
  return (
    <Fragment>
      {
        isDataLoading && (
          <div
            style={{
              position: 'absolute',
              zIndex: '9999',
            }}
            className="map-data-is-loading"
          >
            <Spin indicator={antIcon} />
          </div>
        )
      }
      <LeafletMap
        maxZoom={maxZoom}
        ref={(r) => {
          if (r && r.leafletElement) {
            mapRef.current = r.leafletElement;
          } else {
            mapRef.current = null;
          }
        }}
        doubleClickZoom={false}
        onClick={onClickMap}
        attributionControl={false}
        className="map-leaflet"
        viewport={{
          center: [state.viewBox.latitude, state.viewBox.longitude],
          zoom: state.viewBox.zoom > maxZoom ? maxZoom : state.viewBox.zoom,
        }}
        onViewportChanged={(viewport) => {
          if (viewport && viewport.center) {
            if (
              viewport.center[0] !== state.viewBox.latitude
              || viewport.center[1] !== state.viewBox.longitude
              || state.viewBox.zoom !== viewport.zoom) {
              const zoom = viewport.zoom > maxZoom ? maxZoom : viewport.zoom;

              setState({
                ...state,
                viewBox: {
                  latitude: viewport.center[0],
                  longitude: viewport.center[1],
                  zoom,
                },
              });
              const zoomShowSensors = zoom > 11;
              if (zoomShowSensors !== zoomShowSensorsState) {
                setZoomShowSensorsState(zoomShowSensors);
              }
              if (typeof exposeZoomChange === 'function') {
                exposeZoomChange(zoom, mapRef.current);
              }
            }
          }
          try {
            el.dispatchEvent(event);
          } catch (error) {
            console.log('Error map viewport changed', error);
          }
        }}
        zoomControl={false}
      >
        <TileLayer
          url="https://api.mapbox.com/styles/v1/farmx/cinjdgdic0015adnjk1bl7775/tiles/{z}/{x}/{y}?access_token={accessToken}"
          accessToken={accessToken}
          id="farmx.cinjdgdic0015adnjk1bl7775"
          tileSize={512}
          zoomOffset={-1}
        />

        {Boolean(singleMarkerGeoJSON) && SingleMarker(singleMarkerGeoJSON)}

        {Boolean(state.sensorsGeoJSON) && Boolean(state.sensorsGeoJSON.features.length)
          && (
            <MarkerCluster
              key={JSON.stringify({ presentationMode, s: state.sensorsGeoJSON })}
              sensorsGeoJSON={state.sensorsGeoJSON}
              activePopUp={activePopUp || {}}
              selectSensor={selectSensor}
              showAllSensorLabels={showAllSensorLabels || false}
              showPopUpOnMarkerClick={showPopUpOnMarkerClick}
              presentationMode={presentationMode}
              selectedSensor={selectedSensor}
              refreshMap={() => { el.dispatchEvent(event); }}
            />
          )}

        {Boolean(dynamicBoundsGeoJSON) && Boolean(dynamicBoundsGeoJSON.features.length) && (
          dynamicBoundsGeoJSON.features.map((feature) => (
            <GeoJSON
              className={`map-dynamic-boundary-${feature.id}`}
              style={{
                // color: '#e23549',
                color: '#FF0000',
                weight: 4,
                fillOpacity: 0,
                fillColor: '#e23549',
                dashArray: [10, 10],
              }}
              key={`${JSON.stringify(feature)}`}
              data={[feature]}
              onClick={onClickDynamicBounds}
            />
          ))
        )}

        {zoomShowSensorsState && Boolean(ranchesGeoJSON)
          && Boolean(ranchesGeoJSON.features.length)
          && ranchesGeoJSON.features[0]
          && (
            ranchesGeoJSON.features.map((feature) => {
              let selected = false;
              const featureRanch = cloneDeep(feature);
              featureRanch.geometry.type = 'MultiLineString';
              const style = {
                color: 'white',
                weight: 2,
              };
              try {
                // space for any conditional style rendering based on properties
                if (expandedGeoJSON?.features
                  && expandedGeoJSON.features.length === 1
                  && expandedGeoJSON.features[0].properties.id === feature.properties.id) {
                  delete style.dashArray;
                  selected = true;
                  style.weight = 4;
                  style.dashArray = [10, 10];
                }

                if (presentationMode) {
                  switch (presentationMode) {
                    case 'soilstatus': {
                      if (feature.properties.state && feature.properties.state.state) {
                        style.color = (colors || stateColorCode)[feature.properties.state.state]
                          .markerColor;
                      }
                      break;
                    }
                    case 'connectivity': {
                      if (feature.properties.connectivityState) {
                        style.color = (colors
                          || stateColorCode)[feature.properties.connectivityState].markerColor;
                      }
                      break;
                    }
                    case 'waterpressure': {
                      if (feature.properties.waterPressureState) {
                        style.color = (colors
                          || stateColorCode)[feature.properties.waterPressureState].markerColor;
                      }
                      break;
                    }
                    case 'control': {
                      if (feature.properties.controlState) {
                        style.color = (colors || stateColorCode)[feature.properties.controlState]
                          .markerColor;
                      }
                      break;
                    }
                    case 'provision': {
                      if (feature.properties.provisionState) {
                        style.color = (colors
                          || stateColorCode)[feature.properties.provisionState].markerColor;
                      }
                      break;
                    }

                    default:
                      style.color = 'white';
                  }
                }
              } catch (error) {
                console.log('ERROR in Map.jsx ranch data anomaly', error);
              }

              return (
                <GeoJSON
                  className={`map-ranch-boundary-${feature.id}`}
                  style={style}
                  key={`${JSON.stringify(feature)}${selected}${presentationMode}${zoomShowSensorsState}`}
                  data={[featureRanch]}
                  onClick={() => {
                    if (onClickRanch) {
                      onClickRanch(feature.properties.id);
                    }
                  }}
                />
              );
            })
          )}

        {zoomShowSensorsState && Boolean(blocksGeoJSON)
          && Boolean(blocksGeoJSON.features.length)
          && blocksGeoJSON.features[0]
          && (
            blocksGeoJSON.features.map((feature) => {
              const [minLng, minLat, maxLng, maxLat] = bbox(feature);
              const position = [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
              const style = {
                color: 'white',
                weight: 2,
                fillOpacity: 0,
              };
              let selected = false;
              try {
                if (expandedGeoJSON?.features
                  && expandedGeoJSON.features.length === 1
                  && expandedGeoJSON.features[0].properties.id === feature.properties.id) {
                  style.weight = 4;
                  style.fillOpacity = 0;
                  style.dashArray = [10, 10];
                  selected = true;
                }
                if (presentationMode) {
                  switch (presentationMode) {
                    case 'soilstatus': {
                      const { soilStateStringOfBlock } = feature.properties;
                      if (soilStateStringOfBlock) {
                        const color = getColorCodeForSoilState(soilStateStringOfBlock);
                        style.color = color;
                        if (!isShowingImagery.visible) {
                          style.fillColor = color;
                          style.fillOpacity = 0.5;
                        }
                      }
                      break;
                    }
                    case 'connectivity': {
                      if (feature.properties.connectivityState) {
                        style.color = (colors
                          || stateColorCode)[feature.properties.connectivityState].markerColor;
                        if (!isShowingImagery.visible) {
                          style.fillColor = (colors
                            || stateColorCode)[feature.properties.connectivityState].markerColor;
                          style.fillOpacity = 0.5;
                        }
                      }
                      break;
                    }
                    case 'waterpressure': {
                      if (typeof feature.properties.waterPressureState !== 'undefined') {
                        style.color = (colors
                          || stateColorCode)[feature.properties.waterPressureState].markerColor;
                        if (!isShowingImagery.visible) {
                          style.fillColor = (colors
                            || stateColorCode)[feature.properties.waterPressureState].markerColor;
                          style.fillOpacity = 0.5;
                        }
                      }
                      break;
                    }
                    case 'control': {
                      if (typeof feature.properties.controlState !== 'undefined') {
                        style.color = (colors
                          || stateColorCode)[feature.properties.waterPressureState].markerColor;
                        if (!isShowingImagery.visible) {
                          style.fillColor = (colors
                            || stateColorCode)[feature.properties.waterPressureState].markerColor;
                          style.fillOpacity = 0.5;
                        }
                      }
                      break;
                    }
                    case 'provision': {
                      if (feature.properties.provisionState) {
                        style.color = (colors
                          || stateColorCode)[feature.properties.provisionState].markerColor;
                        if (!isShowingImagery.visible) {
                          style.fillColor = (colors
                            || stateColorCode)[feature.properties.provisionState].markerColor;
                          style.fillOpacity = 0.5;
                        }
                      }
                      break;
                    }
                    case 'irrigationRecommendation': {
                      if (feature.properties.recommendationInfo
                        && feature.properties.recommendationInfo.state) {
                        const color = getColorCodeForIrrigationState(
                          feature.properties.recommendationInfo.state,
                        );
                        style.color = color;
                        if (!isShowingImagery.visible) {
                          style.fillColor = color;
                          style.fillOpacity = 0.5;
                        }
                      } else {
                        const color = getColorCodeForIrrigationState();
                        style.color = getColorCodeForIrrigationState();
                        if (!isShowingImagery.visible) {
                          style.fillColor = color;
                          style.fillOpacity = 0.5;
                        }
                      }
                      break;
                    }
                    default:
                      style.color = 'white';
                  }
                }
              } catch (error) {
                console.log('ERROR in Map.jsx block data anomaly', error);
              }

              return (
                <GeoJSON
                  className={`map-ranch-block-b-${feature.properties.name}`}
                  style={style}
                  key={`${presentationMode}${JSON.stringify(feature.properties)}${selected}${isShowingImagery.visible}${zoomShowSensorsState}`}
                  data={[feature]}
                  onClick={() => {
                    if (onClickBlock) {
                      onClickBlock(feature.properties.id);
                    }
                  }}
                >
                  {
                    mapRef.current && mapRef.current.getZoom() > 13 && (
                      <LabelMarker position={position} name={feature.properties.name} className={`map-ranch-block-i-${feature.properties.name}`} />
                    )
                  }
                </GeoJSON>
              );
            })
          )}

        {anomalyGeoJSON && renderAnomalyLayer(anomalyGeoJSON, presentationMode, isShowingImagery,
          zoomShowSensorsState, onClickBlock, mapRef)}

        {children}

      </LeafletMap>
    </Fragment>
  );
}

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  activePopUp: PropTypes.object,
  selectSensor: PropTypes.func,
  showAllSensorLabels: PropTypes.bool,
  presentationMode: PropTypes.string,
  recenterGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  expandedGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  ranchesGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  dynamicBoundsGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  blocksGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  sensorsGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.number),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  singleMarkerGeoJSON: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    geometry: PropTypes.shape({
      coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
      type: PropTypes.string,
    }),
    properties: PropTypes.object,
  }),
  mapBoxPadding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  showPopUpOnMarkerClick: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedSensor: PropTypes.any,
  isDataLoading: PropTypes.bool,
  onClickRanch: PropTypes.func,
  onClickBlock: PropTypes.func,
  onClickMap: PropTypes.func,
  onClickDynamicBounds: PropTypes.func,
  isShowingImagery: PropTypes.shape({
    visible: PropTypes.bool,
    data: PropTypes.any,
  }),
  exposeZoomChange: PropTypes.func,
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  anomalyGeoJSON: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    geometry: PropTypes.shape({
      coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
      type: PropTypes.string,
    }),
    properties: PropTypes.object,
  }),
};

Map.defaultProps = {
  children: null,
  width: 500,
  height: 500,
  activePopUp: undefined,
  selectSensor: undefined,
  showAllSensorLabels: undefined,
  ranchesGeoJSON: undefined,
  dynamicBoundsGeoJSON: undefined,
  recenterGeoJSON: undefined,
  expandedGeoJSON: undefined,
  blocksGeoJSON: undefined,
  sensorsGeoJSON: undefined,
  presentationMode: undefined,
  singleMarkerGeoJSON: undefined,
  mapBoxPadding: {
    top: 100,
    bottom: 100,
    left: 100,
    right: 100,
  },
  showPopUpOnMarkerClick: true,
  selectedSensor: undefined,
  isDataLoading: false,
  onClickRanch: undefined,
  onClickBlock: undefined,
  onClickMap: undefined,
  onClickDynamicBounds: undefined,
  isShowingImagery: {
    visible: false,
    data: undefined,
  },
  exposeZoomChange: undefined,
  colors: null,
  anomalyGeoJSON: undefined,
};

export default function ResizeDetector(props) {
  const [dimensions, setDimensions] = useState({ width: 500, height: 500 });
  return (
    <div className="map-wrapper">
      <div className="map-inner-container">
        {/* here refresh rate might be different for desktop vs mobile
      > 500 seems to stop the "screen freeze" in mobile devices */}
        <ReactResizeDetector
          onResize={(width, height) => {
            if (!isEqual(dimensions, { width, height })) {
              setDimensions({ width, height });
            }
          }}
          handleWidth
          handleHeight
          refreshMode="debounce"
          refreshRate={633}
        />

        <Map
          key={JSON.stringify(props.sensorsGeoJSON)}
          {...{ ...props }}
          width={Math.round(dimensions.width)}
          height={Math.round(dimensions.height)}
        />
      </div>
    </div>
  );
}
