import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'dhtmlx-scheduler';
import EventCalendar from './EventCalendar';

const initialMobileScheduler = { schedulerMode: 'week', schedulerStartDate: new Date() };

export default function SchedulerMobile(props) {
  const {
    dates,
    onClickChart,
    scheduleData,
    is24Hour,
  } = props;
  const hourFormat = is24Hour ? '%G' : '%g';
  const [mobileScheduler, setMobileScheduler] = useState(initialMobileScheduler);

  function onModeChange(obj) {
    const { mode, date } = obj;
    const dataObj = { schedulerMode: mode, schedulerStartDate: new Date(date) };
    setMobileScheduler(dataObj);
    props.setScheduler(dataObj);
    props.onModeChange(obj);
  }

  return (
    <div className="scheduler-container-mobile">
      <EventCalendar
        scheduleData={scheduleData}
        dates={dates}
        hourFormat={hourFormat}
        onClickChart={onClickChart}
        schedulerObject={mobileScheduler}
        onModechange={onModeChange}
      />
    </div>
  );
}

SchedulerMobile.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.any),
  onClickChart: PropTypes.func,
  colorObj: PropTypes.shape({}),
  scheduleData: PropTypes.arrayOf(PropTypes.any),
  is24Hour: PropTypes.bool,
  onModeChange: PropTypes.func,
  setScheduler: PropTypes.func,
};

SchedulerMobile.defaultProps = {
  dates: null,
  onClickChart: () => null,
  colorObj: {},
  scheduleData: [],
  is24Hour: true,
  onModeChange: () => null,
  setScheduler: () => null,
};
