/* eslint-disable no-await-in-loop */
import { sensorApi } from 'farmx-api';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import {
  cleanSensorsFetchState,
  loadAllSensors,
  setSensorsStateForGeoJSON,
} from '../sensor/actions';
import {
  selectSensorsByRanchId,
} from '../sensor/selectors';
import { loadBlockList, cleanBlocksListRequestState } from '../farm/blocks';
import { cleanRanchesListRequestState, loadRanchList } from '../farm/ranches';
import { cleanEntitiesListRequestState } from '../farm/entities';
import {
  selectBlocksForRanchId,
  selectRanchById,
} from '../farm/selectors';
import {
  selectRanchesCollection,
  selectSoilStateCollectionObj,
} from './selectors';
import { setBlocksGeoJSON, setRanchesGeoJSON } from '../farm/actions';
import { loadSoilState } from '../farm/soilstate';
import { prepareData, prepareRanchesData } from './utils';
import { loadRecommendationForBlock } from '../recommendation/actions';
import { selectRecommendationsForBlocks } from '../recommendation/selectors';

const secondsAllowed = 60;
/**
 * If ranchIds is undefined or [] then only GeoJSON for ranches gets prepared
 * @param {*} ranchIds
 * @returns
 */
export const loadAndPrepareMapDataByRanchIds = (ranchIds) => async (dispatch, getState) => {
  await dispatch(cleanBlocksListRequestState());
  await dispatch(cleanRanchesListRequestState());
  await dispatch(cleanEntitiesListRequestState());
  await dispatch(cleanSensorsFetchState());

  await Promise.allSettled([dispatch(loadRanchList(true)), dispatch(loadBlockList(true))]);

  const ranches = selectRanchesCollection(getState());
  const { ranchesFeatures } = prepareRanchesData({ ranches });


  const soilStateLastUpdated = getState().farmData.soilstate.lastUpdated || 100000000;
  const soilStateElapsedSecondsUpdate = Math.round((Date.now() - soilStateLastUpdated) / 1000);

  setTimeout(async () => {
    try {
      if (soilStateElapsedSecondsUpdate > secondsAllowed) {
        await dispatch(loadSoilState());
      }

      const soilState = selectSoilStateCollectionObj(getState());
      const { ranchesFeaturesWithStatus } = prepareRanchesData({
        ranches,
        soilState,
      });

      if (Object.keys(ranchesFeaturesWithStatus).length) {
        dispatch(setRanchesGeoJSON({
          ranchesFeatures: ranchesFeaturesWithStatus,
        }));
      }
    } catch (error) {
      console.log('loadSoilState all error', error);
    }
  }, 0);

  if (!(ranchIds instanceof Array) || !ranchIds.length) {
    if (Object.keys(ranchesFeatures).length) {
      dispatch(setRanchesGeoJSON({
        ranchesFeatures,
      }));
    }
    return;
  }

  let sensorFeaturesAll = {};
  let blockFeaturesAll = {};
  let ranchFeaturesAll = {};
  let sensorsAll = [];
  let sensorsByRanchesObj = [];
  const errMsg = [];

  const soilState = selectSoilStateCollectionObj(getState());

  await dispatch(loadAllSensors());

  let recommendationBlocks;
  for (let i = 0; i < ranchIds.length; i += 1) {
    const ranchId = ranchIds[0];

    const ranch = selectRanchById(getState(), ranchId);

    const ranchesArr = [];
    ranchesArr.push(ranch);

    const blocks = selectBlocksForRanchId(getState(), ranchId);
    const blockIds = blocks.map((b) => b.id);

    await Promise.allSettled(blocks
      .map((b) => dispatch(loadRecommendationForBlock(b.id))));
    recommendationBlocks = groupBy(selectRecommendationsForBlocks(getState(), blockIds), 'block');
    const sensors = selectSensorsByRanchId(getState(), ranchId);

    sensorsAll = sensorsAll.concat(sensors);

    const sensorRanchRelationObj = sensorsAll.reduce((acc, sensor) => {
      acc[sensor.id] = sensor.ranch;
      return acc;
    }, {});

    const [sensorsState] = await Promise.allSettled([
      sensorApi.fetchStatusForRanchSensors(sensorsAll),
    ]);

    if (sensorsState.status !== 'fulfilled') {
      errMsg.push('Problem loading sensors state.');
    }

    sensorsByRanchesObj = sensorsState.value.reduce((acc, curr) => {
      if (curr.status === 'fulfilled' && sensorRanchRelationObj[curr.value.data.id]) {
        const ranchIdFromObj = sensorRanchRelationObj[curr.value.data.id];
        if (!acc[ranchIdFromObj]) {
          acc[ranchIdFromObj] = [];
        }
        acc[ranchIdFromObj].push(curr.value.data);
      }
      return acc;
    }, {});

    const {
      sensorFeatures,
      blockFeatures,
      ranchFeatures,
    } = prepareData({
      sensors,
      blocks,
      ranches: ranchesArr,
      soilState: soilState[ranchId],
      sensorStates: sensorsByRanchesObj[ranchId],
      recommendationBlocks,
    });

    sensorFeaturesAll = { ...sensorFeatures, ...cloneDeep(sensorFeaturesAll) };
    blockFeaturesAll = { ...blockFeatures, ...cloneDeep(blockFeaturesAll) };
    ranchFeaturesAll = { ...ranchFeatures, ...cloneDeep(ranchFeaturesAll) };
  }

  if (Object.keys(blockFeaturesAll).length) {
    dispatch(setRanchesGeoJSON({
      ranchesFeatures: ranchFeaturesAll,
    }));
  }

  if (Object.keys(blockFeaturesAll).length) {
    dispatch(setBlocksGeoJSON({
      blocksFeatures: blockFeaturesAll,
    }));
  }

  if (Object.keys(sensorFeaturesAll).length) {
    dispatch(setSensorsStateForGeoJSON({
      sensorsFeatures: sensorFeaturesAll,
    }));
  }

  const statesAll = Object.keys(sensorsByRanchesObj).reduce((acc, curr) => {
    const ranchId = Number(curr);
    const ranch = selectRanchById(getState(), ranchId);
    const ranchesArr = [];
    ranchesArr.push(ranch);
    const {
      sensorFeatures,
      blockFeatures,
      ranchFeatures,
    } = prepareData({
      sensors: selectSensorsByRanchId(getState(), ranchId),
      blocks: selectBlocksForRanchId(getState(), ranchId),
      ranches: ranchesArr,
      soilState: soilState[ranchId],
      sensorStates: sensorsByRanchesObj[ranchId],
      recommendationBlocks,
    });

    acc.sensorFeatures = { ...sensorFeatures, ...cloneDeep(acc.sensorFeatures) };
    acc.blockFeatures = { ...blockFeatures, ...cloneDeep(acc.blockFeatures) };
    acc.ranchFeatures = { ...ranchFeatures, ...cloneDeep(acc.ranchFeatures) };
    return acc;
  }, {
    sensorFeatures: {},
    blockFeatures: {},
    ranchFeatures: {},
  });

  if (Object.keys(statesAll.ranchFeatures).length) {
    dispatch(setRanchesGeoJSON({
      ranchesFeatures: statesAll.ranchFeatures,
    }));
  }

  if (Object.keys(statesAll.blockFeatures).length) {
    dispatch(setBlocksGeoJSON({
      blocksFeatures: statesAll.blockFeatures,
    }));
  }

  if (Object.keys(statesAll.sensorFeatures).length) {
    dispatch(setSensorsStateForGeoJSON({
      sensorsFeatures: statesAll.sensorFeatures,
    }));
  }

  if (errMsg.length) {
    throw new Error(errMsg.join(' '));
  }
};
