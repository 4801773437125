import { farmApi } from 'farmx-api';
import { createFarmSlice } from './farmSlice';

const {
  getEntityList,
  getEntityDetail,
} = farmApi;

const {
  loadListThunk: loadEntityList,
  loadDetailThunk: loadEntityDetail,
  slice: entitiesSlice,
} = createFarmSlice(
  'entities',
  getEntityList,
  getEntityDetail,
  (state) => state.farmData.entities,
);

export {
  loadEntityDetail,
  loadEntityList,
};

const { cleanListRequestState } = entitiesSlice.actions;
export const cleanEntitiesListRequestState = () => cleanListRequestState();

export default entitiesSlice.reducer;
