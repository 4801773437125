import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import { scheduleAdapter } from './slices/schedule';
import { selectAllBlocks, selectBlockControlStatus } from '../farm/selectors';
import { selectLoadingState, initialState } from '../helpers';

const selectScheduleState = (state) => state.controlData.schedule;
const scheduleSelectors = scheduleAdapter.getSelectors(selectScheduleState);
const selectBlockSchedule = scheduleSelectors.selectById;
const selectAllBlockSchedule = scheduleSelectors.selectAll;

const selectLoadingBlockSchedule = (state, blockId) => {
  const blockSchedule = selectBlockSchedule(state, blockId);
  if (!blockSchedule) return initialState;
  return selectLoadingState(blockSchedule);
};

const selectSchedulerTimelineData = (
  state, dates, blockIds, irrigationTypes, mobileScreen, colorObj,
) => {
  const chartData = [];
  const sections = [];
  const jsonKeys = {
    recommended: 'recommended_events',
    past: 'past_events',
    scheduled: 'scheduled_events',
  };

  const irrIds = {
    recommended: 1,
    past: 2,
    scheduled: 3,
  };

  const colors = {
    recommended: 'rgb(193, 218, 214)',
    past: 'rgb(232, 208, 169)',
    scheduled: 'lightblue',
  };

  const dummyStartDate = moment(moment(dates[1]).format('YYYY-MM-DD 23:59:57')).toISOString();
  const dummayEndDate = moment(moment(dates[1]).format('YYYY-MM-DD 23:59:58')).toISOString();

  const dateRange = dates[1].diff(dates[0], 'day') === 0
    ? [moment(dates[0]).subtract('1', 'day'), moment(dates[1]).add('1', 'day')]
    : [moment(dates[0]).subtract('1', 'week'), moment(dates[1]).add('1', 'week')];

  const allBlockScheduleData = selectAllBlockSchedule(state);
  const blockScheduleData = allBlockScheduleData.filter(
    (bSch) => bSch && blockIds.some((d) => d === bSch.id),
  );

  const blockControlStatus = selectBlockControlStatus(state, blockIds);

  //= =============== DATA Preparation ==================================
  function getAlarmListUrl(blockId) {
    const params = {
      blockId,
      logLevel: 'error',
      active: true,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/events?${paramStr}`;
  }

  function getControlPageUrl(blockId) {
    const params = {
      blockId,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/control?${paramStr}`;
  }

  function renderOverrideNotice(block) {
    if (!block.overrideActive) return null;
    return (`
      <a
        class="overrideAlert"
        href=${getControlPageUrl(block.blockId)}
      >
        Override Active
      </a>`
    );
  }

  const blocks = selectAllBlocks(state);
  const filteredBlocks = blocks.filter((d) => blockIds.some((id) => id === d.id));
  const blockNames = filteredBlocks.reduce((a, block) => {
    a[block.id] = block.name;
    return a;
  }, {});

  const abbrDuration = (duration) => {
    const durationHr = duration.asHours();
    if (durationHr % 1 !== 0) return `${durationHr.toFixed(1)} hrs`;
    return `${durationHr.toFixed()} hrs`;
  };

  const abbrPeriod = (data) => {
    const duration = moment.duration(moment(data[1]).diff(moment(data[0])));
    return abbrDuration(duration);
  };


  function addColorToBlocks(blockSch, irrgType) {
    return mobileScreen ? colorObj[blockSch.id] : colors[irrgType];
  }

  const loading = blockScheduleData.reduce((a, p) => !p || a || p.loading, false);
  if (!loading) {
    blockScheduleData.forEach((blockSch) => {
      Object.keys(jsonKeys).forEach((irrgType) => {
        if (blockSch[jsonKeys[irrgType]] && irrigationTypes.includes(irrgType)) {
          // Filter by selected dates
          let events = [];
          let dummyEvent = true;
          if (mobileScreen) {
            events = blockSch[jsonKeys[irrgType]];
          } else {
            events = blockSch[jsonKeys[irrgType]].filter((event) => (
              (moment(event.start_date).diff(dateRange[0]) >= 0
                && moment(event.stop_date).diff(dateRange[1]) <= 0))
              || (moment(event.start_date).isBetween(dateRange[0], dateRange[1])
                || moment(event.stop_date).isBetween(dateRange[0], dateRange[1])));
          }
          events.forEach((event) => {
            chartData.push({
              name: blockNames[blockSch.id],
              id: event.id,
              irrId: irrIds[irrgType],
              start_date: event.start_date,
              end_date: event.stop_date,
              text: abbrPeriod([event.start_date, event.stop_date]),
              type: jsonKeys[irrgType],
              color: addColorToBlocks(blockSch, irrgType),
              section_id: blockSch.id,
            });
            if ((moment(dummyStartDate).isBetween(event.started, event.stopped, 'hours'))
              || (moment(dummyStartDate).isBetween(event.start_date, event.stop_date, 'hours'))) {
              dummyEvent = false;
            }
          });
          // Adding dummy event for event ordering
          if ((irrgType !== 'recommended' && !mobileScreen) && dummyEvent) {
            chartData.push({
              name: blockNames[blockSch.id],
              id: events.id,
              irrId: irrIds[irrgType],
              start_date: dummyStartDate,
              end_date: dummayEndDate,
              text: '',
              type: jsonKeys[irrgType],
              color: colors[irrgType],
              section_id: blockSch.id,
            });
          }
        }
      });

      const pumpOverride = blockControlStatus
        && blockControlStatus[blockSch.id].vfdStatus
        && blockControlStatus[blockSch.id].vfdStatus.overrideStatus;

      const valveOverride = blockControlStatus
        && blockControlStatus[blockSch.id].valveStatus
        && blockControlStatus[blockSch.id].valveStatus.overrideStatus;

      const overrideActive = !!(valveOverride || pumpOverride);

      // Object for override active
      const obj = {
        name: blockNames[blockSch.id],
        blockId: blockSch.id,
        overrideActive,
        alarmCount: blockControlStatus
          && blockControlStatus[blockSch.id].valveStatus
          && blockControlStatus[blockSch.id].valveStatus.blockAlarmCount,
      };

      sections.push({
        key: blockSch.id,
        label:
          `<div class="container">
            <div class="sub-container"><div class="name">${blockNames[blockSch.id]}</div>
            ${obj.overrideActive ? `<div class="active-name">${renderOverrideNotice(obj)}</div>` : ''}
          </div>
          <div class="image">
            <a href="${getAlarmListUrl(blockSch.id)}" class="notification">
                <span class="svg-container">
                  <button class="btn">
                    <svg viewBox="64 64 896 896" id="bell_svg" focusable="false" class="" data-icon="bell" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M816 768h-24V428c0-141.1-104.3-257.8-240-277.2V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.8C336.3 170.2 232 286.9 232 428v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48z"></path></svg>
                  </button>
                </span>
                ${obj.alarmCount ? `<span class="badge">${obj.alarmCount}</span>` : '<span class="nobadge"></span>'}
              </a>
          </div>
        </div>`,
      });
    });
  }
  return [chartData, sections, loading];
};

const selectSchedulesPreparedData = createSelector(
  [
    selectAllBlockSchedule,
    selectAllBlocks,
  ],
  (schedules, blocks) => {
    if (!(schedules.length && blocks.length)) {
      return [];
    }
    const blocksObj = groupBy(blocks, 'id');
    // TODO remove .subtract(X, 'months'), this is demo purposes only
    const today = moment().subtract(2, 'months').startOf('day');

    return Object.values(
      schedules
        .map((blockSchedule) => ({
          ...blockSchedule,
          block: blocksObj[blockSchedule.id][0],
          blockName: blocksObj[blockSchedule.id][0].name,
        }))
        .reduce(
          (acc, curr) => {
            if (!acc[curr.block.ranch.id]) {
              acc[curr.block.ranch.id] = {};
              acc[curr.block.ranch.id].blocks = [];
            }
            acc[curr.block.ranch.id].id = curr.block.ranch.id;
            acc[curr.block.ranch.id].name = curr.block.ranch.name;
            curr.upcomingSchedules = curr.scheduled_events
              .filter((scheduledEvent) => {
                /*
                  block: 738131
                  flow: null
                  gpm: "20.000"
                  id: 4272
                  start_date: "2021-10-26T18:20:00Z"
                  started: null
                  stop_date: "2021-10-28T18:20:00Z"
                  stopped: null
                  user: 346
                */

                // Not sure about the correct approach here
                // checking if the stop date is in the future
                // for scheduled event

                // Per discussion with Robin - there will be new api to resolve
                // recurring schedules

                // TODO implement data of reccuring schedules here
                const diff = moment(moment(scheduledEvent.stop_date)).diff(today, 'days');
                return diff >= 0;
              });
            acc[curr.block.ranch.id].blocks.push(curr);
            return acc;
          },
          {},
        ),
    );
  },
);

// TODO continue from here after map PR work
const selectScheduleById = (id) => createSelector(
  [selectSchedulesPreparedData],
  (schedules) => {
    let schedule = {};
    if (!schedules.length) {
      return schedule;
    }
    schedules
      .some((r) => r.blocks.some((b) => b.upcomingSchedules.some((s) => {
        if (s.id === Number(id)) {
          schedule = Object.assign(schedule, { ranchInfo: cloneDeep(r) });
          schedule = Object.assign(schedule, { blockInfo: cloneDeep(b) });
          schedule = Object.assign(schedule, cloneDeep(s));
          const startMoment = moment(s.start_date);
          const stopMoment = moment(s.stop_date);
          const diff = stopMoment.diff(startMoment, 'seconds');
          schedule.duration = moment.utc(moment.duration(diff, 'seconds').asMilliseconds()).format('hh:mm');
          schedule.startTime = moment(s.start_date).format('ddd, MMM, Do, hh:mm A');
          schedule.endTime = moment(s.stop_date).format('ddd, MMM, Do, hh:mm A');
          schedule.repeats = s.is_recurring;
          schedule.blockName = schedule.blockInfo.blockName;
          return true;
        }
        return false;
      })));
    return schedule;
  },
);

export {
  selectBlockSchedule,
  selectLoadingBlockSchedule,
  selectAllBlockSchedule,
  selectSchedulerTimelineData,
  selectSchedulesPreparedData,
  selectScheduleById,
};
