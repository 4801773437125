import React, { useEffect, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

NoDataToDisplay(Highcharts);
const defaultOptions = {
  title: null,
  chart: {
    type: 'column',
  },
  xAxis: {},
  yAxis: {},
  series: [],
  credits: {
    enabled: false,
  },
  lang: {
    noData: 'No data',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030',
    },
  },
  time: {
    useUTC: false,
  },
};

function BarHighchart(props) {
  const highchartsComponentRef = React.createRef();
  const {
    options, constructorType, chartElem,
  } = props;
  const plotLineId = 'y2';

  const draggablePlotLine = useCallback((axis) => {
    let clickY;
    const getPlotLine = () => {
      for (let i = 0; i < axis.plotLinesAndBands.length; i += 1) {
        if (axis.plotLinesAndBands[i].id === plotLineId) {
          return axis.plotLinesAndBands[i];
        }
      }
      return null;
    };

    const getValue = () => {
      const plotLine = getPlotLine();
      const translation = plotLine.svgElem.translateY;
      let newValue = axis.toValue(translation) - axis.toValue(0) + plotLine.options.value;
      newValue = Math.max(axis.min, Math.min(axis.max, newValue));
      return newValue;
    };

    const dragStep = (e) => {
      const plotLine = getPlotLine();
      let newTranslation = e.pageY
        ? e.pageY - clickY
        : e.changedTouches['0'].pageY - clickY;
      let newValue = axis.toValue(newTranslation)
          - axis.toValue(0)
          + plotLine.options.value;
      newValue = Math.max(axis.min, Math.min(axis.max, newValue));
      newTranslation = axis.toPixels(
        newValue + axis.toValue(0) - plotLine.options.value,
      );
      plotLine.svgElem.translate(0, newTranslation);

      if (getPlotLine().label) {
        plotLine.label.translate(0, newTranslation);
      }

      if (plotLine.options.onDragChange) {
        plotLine.options.onDragChange(newValue);
      }

      if (
        plotLine.label
        && Math.round(newValue) > axis.min
        && Math.round(newValue) < axis.max
      ) {
        plotLine.label.attr({
          y: (e.pageY ? e.pageY : e.changedTouches['0'].pageY) - newValue,
        });
      }
    };

    let dragStop = null;
    const dragStart = (e) => {
      document.addEventListener('mousemove', dragStep, false);
      document.addEventListener('mouseup', dragStop, false);
      document.addEventListener('touchstart', dragStep, false);
      document.addEventListener('touchmove', dragStep, false);
      document.addEventListener('touchend', dragStop, false);

      const plotLine = getPlotLine();
      clickY = (e.pageY ? e.pageY : e.changedTouches['0'].pageY)
        - plotLine.svgElem.translateY;
      if (plotLine.options.onDragStart) {
        plotLine.options.onDragStart(getValue());
      }
    };

    dragStop = (e) => {
      document.removeEventListener('mousemove', dragStep, false);
      document.removeEventListener('mouseup', dragStop, false);
      document.removeEventListener('touchstart', dragStep, false);
      document.removeEventListener('touchmove', dragStep, false);
      document.removeEventListener('touchend', dragStop, false);
      const plotLine = getPlotLine();

      if (plotLine) {
        const plotLineOptions = plotLine.options;
        // Remove + Re-insert plot line
        // Otherwise it gets messed up when chart is resized
        if (Object.prototype.hasOwnProperty.call(plotLine.svgElem, 'translateX')) {
          plotLineOptions.value = getValue();
          axis.removePlotLine(plotLineOptions.id);
          axis.addPlotLine(plotLineOptions);

          if (plotLineOptions.onDragFinish) {
            plotLineOptions.onDragFinish(plotLineOptions.value);
          }
        }
        const range = getValue();
        if (plotLine.label && range < axis.max && range > axis.min) {
          plotLine.label.attr({
            y: (e.pageY ? e.pageY : e.changedTouches['0'].pageY) - range,
          });
        }

        getPlotLine()
          .svgElem.css({ cursor: 'pointer' })
          .translate(0, 0)
          .on('touchstart', dragStart)
          .on('mousedown', dragStart);

        if (getPlotLine().label) {
          getPlotLine()
            .label.css({ cursor: 'pointer' })
            .translate(0, 0)
            .on('touchstart', dragStart)
            .on('mousedown', dragStart);
        }
      }
    };
    dragStop();
  }, []);

  useEffect(() => {
    const { chart } = highchartsComponentRef.current;
    if (chart && chart.yAxis[0]) {
      draggablePlotLine(chart.yAxis[0]);
      chartElem(highchartsComponentRef.current);
    }
  }, [highchartsComponentRef, chartElem, draggablePlotLine]);

  return (
    <HighchartsReact
      constructorType={constructorType || 'chart'}
      containerProps={{ style: { height: '100%' } }}
      highcharts={Highcharts}
      options={{
        ...defaultOptions,
        ...options,
      }}
      ref={highchartsComponentRef}
    />
  );
}

BarHighchart.propTypes = {
  options: PropTypes.shape({}),
  constructorType: PropTypes.string,
  chartElem: PropTypes.func,
  chartRef: PropTypes.shape({
    current: PropTypes.any,
  }),
};

BarHighchart.defaultProps = {
  options: null,
  chartElem: null,
  constructorType: null,
  chartRef: null,
};

export default BarHighchart;
