import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import styles from './CommonStyles.less';

export default function PumpStateTag({ state, online }) {
  const color = state === 'on' ? '#1890FF' : '#D40202';
  if (state) {
    if (state !== 'unknown') {
      if (online && state === 'off') {
        return (
          <Tag color="" key={state} className={styles['tag-icon']}>
            {state.toUpperCase()}
          </Tag>
        );
      }
      return (
        <Tag
          color={color}
          key={state}
          className={[styles['tag-icon'], styles['tag-icon-irrigating']]}
        >
          {state.toUpperCase()}
        </Tag>
      );
    }
    return <WarningFilled className={styles['warning-icon']} />;
  }
  if (!state) {
    return <WarningFilled className={styles['warning-icon']} />;
  }
  return '';
}

PumpStateTag.propTypes = {
  state: PropTypes.string,
  online: PropTypes.bool,
};

PumpStateTag.defaultProps = {
  state: null,
  online: null,
};
