import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Collapse, Table, Tag, Button, Tooltip, Typography, Badge,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { selectors } from 'farmx-redux-core';
import { FaCalendarAlt } from 'react-icons/fa';
import { WarningFilled, LoadingOutlined, BellFilled } from '@ant-design/icons';
import styles from './CommonStyles.less';
import OverrideStatus, { VALVE_TYPE } from './OverrideStatus';
import BlockControlSensorList from './BlockControlSensorList';
import { getValveScheduleMessage, getPumpScheduleMessageBasic } from './scheduleMessage';

const { Panel } = Collapse;
const { Text } = Typography;

const {
  selectBlockSensorStatus,
} = selectors;

/**
 * @param {*} blockNames - blockName object with blockId as key
 * @param {*} blockIds - array of blockIds
 * @returns - block name/ count of blocks based on blockIds length
 * Ex: If blockIds = [1], Result: 'BLOCK 1'
 *     If blockIds = [1, 2], Result: '2 blocks'
 */
function getBlockNameForValve(blockNames, blockIds) {
  let blockName = '';
  if (blockIds && blockIds.length) {
    if (blockIds.length === 1) {
      blockName = blockNames[blockIds[0]];
    } else {
      blockName = `${blockIds.length} blocks`;
    }
  }
  return blockName;
}

export default function ValveControlList({
  valves,
  blocks,
  onClickCalendar,
  valveLoading,
}) {
  const filteredBlocks = blocks ? blocks.filter((d) => d.valve || d.valves) : null;
  const blocksUnsorted = useSelector((state) => selectBlockSensorStatus(state, filteredBlocks));
  const blockData = blocksUnsorted.sort((a, b) => a.blockName.localeCompare(b.blockName));
  const spinner = blockData.reduce((a, p) => !p || a || p.valveLoading, false);
  const { t } = useTranslation();

  const blockNames = filteredBlocks.reduce((acc, obj) => {
    acc[obj.id] = obj.name;
    return acc;
  }, {});

  const blockDataObj = blockData.reduce((acc, b) => {
    acc[b.blockId] = b;
    return acc;
  }, {});

  const valveData = [];
  valves.forEach((d) => {
    const blockId = d.irrigation_block || (d.irrigation_blocks?.length && d.irrigation_blocks[0]);
    if (blockId) valveData.push({ ...d, ...blockDataObj[blockId] });
  });

  const getMessageClassName = (state, online) => {
    const styleStr = `${styles['red-message']} `;
    const styleStrNot = `${styles['normal-message']} `;
    if (state === 'unknown' || (!online && state === 'closed')) {
      return styleStr;
    }
    if (!state) return styleStr;
    return styleStrNot;
  };

  const getSchedule = (valve, text) => (
    <div
      className={styles['icon-container']}
      onClick={() => onClickCalendar({ id: valve.blockId })}
      role="presentation"
    >
      <div className={styles.icon}>
        <FaCalendarAlt />
      </div>
      <div className={getMessageClassName(valve.valveCurrentState, valve.valveOnline)}>
        <span className={styles['schedule-content']}>
          {text}
        </span>
      </div>
    </div>
  );

  const getIndClass = (blockStatus) => {
    const styleStr = `${styles.indicator} `;
    if (!blockStatus.valveLoading) {
      if (blockStatus.valveControlEnabled) {
        if (!blockStatus.valveOnline) return styleStr + styles.red;
        if (blockStatus.valveOnline
          && blockStatus.valveCurrentState === 'closed') return styleStr + styles['light-grey'];
        if (blockStatus.valveOnline
          && blockStatus.valveCurrentState === 'open') return styleStr + styles.blue;
      }
    }
    return styleStr + styles['light-grey'];
  };

  const getCurrentState = (valve) => {
    const color = valve.valveCurrentState === 'open' ? '#1890FF' : '#D40202';
    if (valve.valveCurrentState) {
      if (valve.valveCurrentState !== 'unknown') {
        if (valve.valveOnline && valve.valveCurrentState === 'closed') {
          return (
            <Tag color="" key={valve.valveCurrentState} className={styles['tag-icon']}>
              {t('Closed').toUpperCase()}
            </Tag>
          );
        }
        const state = valve.valveCurrentState === 'open'
          ? t('Open_status').toUpperCase()
          : valve.valveCurrentState;
        return (
          <Tag
            color={color}
            key={valve.valveCurrentState}
            className={[styles['tag-icon'], styles['tag-icon-irrigating']]}
          >
            {state.toUpperCase()}
          </Tag>
        );
      }
      return <WarningFilled className={styles['warning-icon']} />;
    }
    if (!valve.valveCurrentState) {
      return <WarningFilled className={styles['warning-icon']} />;
    }
    return '';
  };

  const getRowClassName = (record) => {
    if (record.valveCurrentState) {
      if (!record.valveControlEnabled
        || record.valveCurrentState === 'unknown') {
        return styles['red-border-text'];
      }
      if (record.valveControlEnabled
        && (!record.valveOnline
          && (record.valveCurrentState === 'closed' || !record.valveCurrentState))) {
        return styles['red-border-text'];
      }
    }
    if (!record.valveLoading && (!record.valveControlEnabled
      || !record.valveCurrentState)) {
      return styles['red-border-text'];
    }
    const className = getIndClass(record);
    return className;
  };

  function widthCalc(columns) {
    const maxwid = 100;
    const result = columns().map((element) => {
      const values = element;
      if (element.title === 'Alarm') {
        values.width = 100;
      } if (element.title === 'Override') {
        values.width = 100;
      } if (element.title !== 'Override' && element.title !== 'Alarm') {
        values.width = `${(maxwid - 10) / (columns().length - 2)}%`;
      }
      return values;
    });
    return result;
  }

  function getAlarmListUrl(valve) {
    const params = {
      blockId: valve.blockId,
      logLevel: 'error',
      active: true,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/events?${paramStr}`;
  }

  const columns = () => [
    {
      title: t('Block(s)'),
      className: styles['schedule-item-header'],
      render: (valve) => {
        const blockIds = valve.irrigation_blocks && valve.irrigation_blocks.length
          ? valve.irrigation_blocks : [valve.block];
        const blockName = getBlockNameForValve(blockNames, blockIds);

        if (blockName) {
          return (
            <Tooltip title={blockName}>
              <span>{blockName}</span>
            </Tooltip>
          );
        }
        return <span style={{ color: 'lightgray' }}>{t('No Name')}</span>;
      },
    },
    {
      title: t('Valve'),
      className: styles['schedule-item-header'],
      render: (valve) => {
        if (valve.name) {
          return (
            <Tooltip title={valve.name}>
              <span>{valve.name}</span>
            </Tooltip>
          );
        }
        return <span style={{ color: 'lightgray' }}>{t('No Name')}</span>;
      },
    },
    {
      title: t('Schedule'),
      className: styles['schedule-header'],
      render: (valve) => {
        if (valve.valveControlEnabled) {
          return getSchedule(valve, getValveScheduleMessage(
            t,
            valve.valveOnline,
            valve.valveCurrentState,
            valve.valveScheduledOpen,
            valve.valveScheduledClose,
            valve.valveOverrideStatus && valve.valveOverrideStatus.state,
          ));
        }
        return t('Control not enabled');
      },
    },
    {
      title: t('Override'),
      className: styles['override-header'],
      render: (valve) => {
        if (valve.valveControlEnabled) {
          return (
            <OverrideStatus
              type={VALVE_TYPE}
              overrideStatus={valve.valveOverrideStatus}
              valveIdentifier={valve.valveIdentifier}
              vfdIdentifier={valve.vfdIdentifier}
              currentState={valve.valveCurrentState}
              confirmDialogMessages={[
                getPumpScheduleMessageBasic(
                  t,
                  valve.pumpStartDate,
                  valve.pumpStopDate,
                ),
                getValveScheduleMessage(
                  t,
                  valve.valveOnline,
                  valve.valveCurrentState,
                  valve.valveScheduledOpen,
                  valve.valveScheduledClose,
                )]}
            />
          );
        }
        return null;
      },
    },
    {
      title: t('State'),
      className: styles['state-header'],
      render: (valve) => {
        if (valve.valveControlEnabled) {
          return (
            <div className={styles.status}>
              {getCurrentState(valve)}
            </div>
          );
        }
        return null;
      },
    },
    {
      title: t('Alarms'),
      className: styles['alarms-header'],
      render: (valve) => (
        <Link to={getAlarmListUrl(valve)}>
          <Badge
            count={valve.valveAlarmCount}
          >
            <Button
              className={styles['bell-icon-button']}
              icon={<BellFilled className={styles['bell-icon-size']} />}
            />
          </Badge>
        </Link>
      ),
    },
  ];

  function renderValveDetail(valveControlStatus) {
    return <BlockControlSensorList controlStatus={valveControlStatus} showBlockList />;
  }

  return (
    <div className={styles['list-container']}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={['1']}
      >
        <Panel
          header={(
            <div>
              <Text style={{ fontSize: '16px' }}>{t('Valves')}</Text>
              {valveLoading || spinner
                ? <LoadingOutlined style={{ marginLeft: '10px', fontSize: '16px' }} />
                : ''}
            </div>
          )}
          key="1"
          className={styles['collapse-header']}
        >
          {valveData.length
            ? (
              <Table
                className={styles['custom-table']}
                rowClassName={getRowClassName}
                dataSource={valveData}
                columns={widthCalc(columns)}
                pagination={false}
                rowKey={(record) => String(record.id)}
                expandable={{
                  expandRowByClick: true,
                  rowExpandable: () => true,
                  expandedRowRender: (valve) => renderValveDetail(valve),
                }}
              />
            ) : (
              <section className={styles['configuration-message']}>
                No Valves Configured
              </section>
            )}
        </Panel>
      </Collapse>
    </div>
  );
}

ValveControlList.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  valves: PropTypes.arrayOf(PropTypes.object),
  onClickCalendar: PropTypes.func,
  valveLoading: PropTypes.bool,
};

ValveControlList.defaultProps = {
  blocks: [],
  valves: [],
  onClickCalendar: null,
  valveLoading: null,
};
