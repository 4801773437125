import React from 'react';
import PropTypes from 'prop-types';
import styles from './CommonStyle.less';

const BlockOption = ({ block }) => (
  <div>
    <span className={styles['left-align']}>{block.name}</span>
    <span className={styles['right-align']}>{block.ranch && block.ranch.name}</span>
  </div>
);

BlockOption.propTypes = {
  block: PropTypes.shape({
    name: PropTypes.string,
    ranch: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default BlockOption;
