import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip } from 'antd';
import Moment from 'react-moment';
import BooleanTag from '../BooleanTag';
import SensorAlarmButton from './SensorAlarmButton';
import ControlSummaryRefreshContext from './RefreshContext';
import styles from './CommonStyles.less';

const {
  selectPumpFlowMeter,
} = selectors;

const {
  loadSensorStatus,
} = actions;

export default function PumpControlSensorList({ pumpType, pumpIdentifier }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refreshId = useContext(ControlSummaryRefreshContext);

  const flowMeter = useSelector(
    (state) => selectPumpFlowMeter(state, pumpType, pumpIdentifier),
  );
  const {
    identifier: flowMeterIdentifier,
    type: flowMeterType,
  } = flowMeter || {};

  useEffect(() => {
    if (refreshId && flowMeterIdentifier && flowMeterType) {
      const sensorParams = { type: flowMeterType, identifier: flowMeterIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, refreshId, flowMeterType, flowMeterIdentifier]);

  function renderSensorName(sensor) {
    if (!sensor.name) {
      return <span>{t('No Name')}</span>;
    }
    return (
      <Tooltip title={sensor.name}>
        <span>{sensor.name}</span>
      </Tooltip>
    );
  }

  function renderFlow(sensor) {
    const { status } = sensor;
    if (!status || status.flowRate === undefined || status.flowRate.value === undefined) {
      return null;
    }
    return (
      <div>{`${status.flowRate.value.toFixed(1)} ${status.flowRate.units}`}</div>
    );
  }

  function renderSensorConnectivity(sensor) {
    if (!sensor.status) return null;
    return (
      <BooleanTag value={sensor.status.online} labels={[t('online'), t('offline')]} />
    );
  }

  function renderSensorAlarms(sensor) {
    return (
      <SensorAlarmButton
        type={sensor.type}
        identifier={sensor.identifier}
        alarmCount={sensor.status && sensor.status.alarmCount}
      />
    );
  }

  function renderLatestDate(sensor) {
    if (!sensor.status || !sensor.status.latestDate) return null;
    const { latestDate: date } = sensor.status;
    return (
      <Tooltip
        title={<Moment format="YYYY-MM-DD h:mm:ss A" local>{date}</Moment>}
        placement="bottom"
      >
        <span>
          <Moment fromNow>{date}</Moment>
        </span>
      </Tooltip>
    );
  }

  function widthCalc(columns) {
    const maxWidth = 100;
    const result = columns().map((column) => {
      if (column.key !== 'alarms') {
        return {
          ...column,
          width: `${(maxWidth - 10) / (columns().length - 1)}%`,
        };
      }
      return column;
    });
    return result;
  }

  const flowMeterColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles['schedule-item-header'],
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      width: 100,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('Flow'),
      key: 'flow',
      width: 100,
      render: (sensor) => renderFlow(sensor),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles['last-data'],
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles['alarms-header'],
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  return (
    <div>
      <span className={styles['sub-header']}>{t('Flow Meter')}</span>
      {flowMeter && (
        <Table
          dataSource={[flowMeter]}
          columns={widthCalc(flowMeterColumns)}
          rowKey={(record) => record.identifier}
          size="small"
          className={styles['inner-table']}
          pagination={false}
        />
      )}
      {!flowMeter && (
        <div className={styles['no-data-message']}>
          {t('No Flow Meter installed')}
        </div>
      )}
    </div>
  );
}

PumpControlSensorList.propTypes = {
  pumpType: PropTypes.string.isRequired,
  pumpIdentifier: PropTypes.string.isRequired,
};
