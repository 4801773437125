import { combineReducers } from 'redux';
import renderedEvents from './slices/renderedEvents';
import scheduledEvents from './slices/scheduledEvents';

const scheduleReducer = combineReducers({
  renderedEvents,
  scheduledEvents,
});

export default scheduleReducer;
