import { createSelector } from '@reduxjs/toolkit';

const selectUserSettingsState = (state) => state.settingsData.userSettings;

export const selectUserSettings = createSelector(selectUserSettingsState, (state) => state.data);
export const selectLoadingUserSettings = createSelector(selectUserSettingsState, (state) => ({
  loading: state.loading,
  lastUpdated: state.lastUpdated,
}));

export const selectUserTimeFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_time : '12_hour';
};

export const selectUserTimeMomentFormat = createSelector(selectUserTimeFormat,
  (userTime) => (userTime === '12_hour' ? 'h:mm A' : 'HH:mm'));

export const selectUserLengthFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_length : 'feet';
};

export const selectUserDepthFormat = (state) => {
  const userSettings = selectUserSettings(state);
  if (!userSettings) {
    return 'inches';
  }
  return userSettings.units_length === 'feet' ? 'inches' : 'centimeters';
};

export const selectUserVolumeFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_volume : 'gallons';
};

export const selectUserAreaFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_area : 'acres';
};

export const selectUserFlowRateFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_flow_rate : 'gallons_per_minute';
};

export const selectUserPowerFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_power : 'horsepower';
};

export const selectUserTemperatureFormat = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.units_temperature : 'degrees_fahrenheit';
};

export const selectUserLanguage = (state) => {
  const userSettings = selectUserSettings(state);
  return userSettings ? userSettings.language : 'en-us';
};
